import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(list: any, filterText: string): any {
    if (!list || !filterText) {
      return list;
    }

    console.log('search pipe');

    return list.filter(v => {
      if(!v.userAccount){
        v.userAccount = {firstName: '(Appium)', lastName: '', isFake: true};
      }

      if(v.appiumServerStarted.toString().toLowerCase().includes(filterText.toLowerCase())){
        return v.appiumServerStarted.toString().toLowerCase().includes(filterText.toLowerCase());
      }

      if(v.device.alias.toLowerCase().includes(filterText.toLowerCase())){
        return v.device.alias.toLowerCase().includes(filterText.toLowerCase());
      }
      
      if(v.userAccount.firstName.toLowerCase().includes(filterText.toLowerCase())){
        return v.userAccount.firstName.toLowerCase().includes(filterText.toLowerCase());
      }
      
      if(v.userAccount.lastName.toLowerCase().includes(filterText.toLowerCase())){
        return v.userAccount.lastName.toLowerCase().includes(filterText.toLowerCase());
      }

      if(v.startTime.toString().includes(filterText.toLocaleLowerCase()) || filterText.includes('/') ){
        let nDate = new Date(v.startTime).toLocaleDateString();
        return nDate.includes(filterText.toLocaleLowerCase());
      }

      if(v.startTime.toString().includes(filterText.toLocaleLowerCase()) || filterText.includes(':') ){
        let nTime = new Date(v.startTime).toLocaleTimeString();
        return nTime.includes(filterText.toLocaleLowerCase());
      }
    });
  }
}

interface Log {
  appiumServerStarted: Boolean;
  commandCount: Number;
  device: {alias: String};
  startTime: Date;
  endTime: Date;
  userAccount: {firstName: String, lastName: String, isFake: Boolean};
}
