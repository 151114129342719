import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { LoginService } from '../../../login/login.service';

@Component({
  selector: 'app-lcai',
  templateUrl: './lcai.component.html',
  styleUrls: ['./lcai.component.scss']
})
export class LcaiComponent implements OnInit {

  constructor(private http: Http) { }

	status: String = 'Waiting...';

	
  ngOnInit() {
		
		this.http.get('api/integration/lcai/list').subscribe((res: any) => {
			this.status = 'online';
		}, e => {
			console.log(e);
			this.status = 'offline';
		});
  }

}
