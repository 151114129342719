import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TestsService } from './../../tests/tests.service';
import { DevicesService } from './../../devices/devices.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'live-report',
  templateUrl: './liveReport.component.html',
  styleUrls: ['./liveReport.component.scss']
})
export class LiveReportsComponent implements OnInit, AfterViewInit {

    liveReport: any;
	screenWidth: any
    device: any;
    stepDetail: any;
	downloading = false;
	@ViewChild('canvas') canvas: ElementRef;
	@ViewChild('animecanvas') animecanvas: ElementRef;
	@ViewChild('pdfLink') pdfLink: ElementRef;


	constructor(
		private router: Router,
		private route: ActivatedRoute,
        private testService: TestsService,
		private toastr: ToastrService,
        private devicesService: DevicesService,
        private cd: ChangeDetectorRef
	) {
	}

	ngOnInit() {
		const reportId = this.route.snapshot.paramMap.get('id');

        this.testService.findLiveReportById(reportId)
		.subscribe(async resp => {
			let data = await resp.json();
			console.log(data)
			if(data == null){
				this.router.navigate(['/reports']);
			}
            this.liveReport = data;

            this.device = this.devicesService.devices.find((device) => device._id === data.device);

			this.loadAnimation();
		})
	}

	async sleep(seconds){
		return new Promise((resolve) => {
			setTimeout(resolve, (seconds*1000));
		});
	}
	
	async loadAnimation(){
		
		var g = this.animecanvas.nativeElement.getContext('2d');
		const ref = this;
		var i = 0;
		
		while(true){
			let img = new Image();
			img.src = `data:image/jpeg;base64,${ref.liveReport.steps[i].screen}`;
			
			img.onload = () => {
				ref.animecanvas.nativeElement.setAttribute('width', img.width);
				ref.animecanvas.nativeElement.setAttribute('height', img.height);
				//ref.screenWidth = (ref.animecanvas.nativeElement.offsetWidth);

				g.drawImage(img, 0, 0);
				img.onload = null;
				img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
				img = null;

				i = i == (this.liveReport.steps.length -1) ? 0 : i+1;

				if(ref.liveReport.steps[i].value.method == 'relative'){
					if(ref.liveReport.steps[i].value.reference.duration > 220){
						g.beginPath();
						g.moveTo(ref.liveReport.steps[i].value.reference.origin.x, ref.liveReport.steps[i].value.reference.origin.y);
						g.lineTo(ref.liveReport.steps[i].value.reference.dest.x, ref.liveReport.steps[i].value.reference.dest.y);
						g.lineWidth = '10';
						g.strokeStyle = '#6ce890'
						g.stroke();
					}

					g.beginPath();
					g.arc(ref.liveReport.steps[i].value.reference.dest.x, ref.liveReport.steps[i].value.reference.dest.y, 50, 0, 2 * Math.PI);
					g.fillStyle = '#6ce890';
					g.fill();
					g.stroke();
				}
			};

			await this.sleep(1.4);
		}		
	}

	calcDuration(seconds){
        if(seconds == 0) {return 0}
        let getPlural = (number, word)  => {
            return number === 1 && word.one || word.other;
        }
    
        let words = {
                minute: { one: 'minuto', other: 'minutos' },
                second: { one: 'segundo', other: 'segundos' }
            },
            units = ['minute', 'second'],
            values = [60, 1],
            result = [];
    
        units.reduce(function (s, u, i) {
            var temp = Math.floor(s / values[i]);
            if (temp) {
                result.push(temp + ' ' + getPlural(temp, words[u]));
            }
            return s % values[i];
        }, seconds);
        return result.join(' ');
    }

	downloadCode(){
		const reportId = this.route.snapshot.paramMap.get('id');
		this.testService.exportLiveReport(reportId).subscribe(async resp => {
			let data = await resp.json();
			this.toastr.success('Script gerado!');
			
			setTimeout(() => {
				window.open(data.url, "_blank");
			}, 2000)
		})
	}
	
	downloadPdf(){
		//Solicitar pdf pro backend e pegar url pro download (usar flag)
		this.downloading = true;
		
		this.testService
		.downloadPDF(this.liveReport._id)
		.subscribe(result => {
			const url = window.URL.createObjectURL(result);
			const a = this.pdfLink.nativeElement;

			a.download = `${this.liveReport._id}.pdf`;
			a.href = url;
			a.click();

			this.downloading = false;
		});
	}

	getScreen(step){
		setTimeout(() => {
			let img = new Image();
			img.src = `data:image/jpeg;base64,${step.screen}`;

			var element = document.createElement('a');
			element.setAttribute('href', img.src);
			element.setAttribute('download', `screenshot_${new Date().getTime()}.jpg`);

			element.style.display = 'none';
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
		}, 800);
		
	}

	printReference(step){
		let ref = step.value.reference;

		if(step.value.method == 'relative' && ref.duration && ref.duration > 220){
			return `Drag ${Math.round(parseInt(ref.origin.x))}x${Math.round(parseInt(ref.origin.y))} to ${Math.round(parseInt(ref.dest.x))}x${Math.round(parseInt(ref.dest.y))}`;
		}
		if(step.value.method == 'relative' && ref.duration && ref.duration < 220){
			return `position X: ${Math.round(parseInt(ref.origin.x))} Y: ${Math.round(parseInt(ref.origin.y))}`;
		}

		if(step.value.action == 'presskey'){
			switch (ref) {
				case 3:
					return 'Home'	
				break;
				
				case 4:
					return 'Back'
				break;
				case 187:
					return 'Options'
				break
			}
		}

		return ref;
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	editName(){
		const name = prompt('Digite o nome do relatório:');

		if (name) {
			this.testService.updateReportName({_id: this.liveReport._id, name})
			.subscribe((resp) => {
				this.liveReport.name = name;
				console.log(resp)
			})
		}
	}

	getThumbUrl(device){		
		return device.model.urlThumb ? device.model.urlThumb : (device.model.thumb ? 'assets/images/thumbs/'+device.model.thumb : 'assets/images/iphone.svg');
	}	

	copyInput(step){
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = `${step.value.action} - ${step.value.method} - ${this.printReference(step)}`;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
  
		this.toastr.success('Copiado!');
	  }
}
