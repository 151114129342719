import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { DevicesService, DeviceFilter } from '../../devices/devices.service';
import { URL_SERVER } from './../../providers';

@Injectable()
export class ReportsService {

	pressets: any;

  constructor (
 	 private http: Http,
 	 private devicesService: DevicesService
  ) {
 	 this.pressets = { ... devicesService.pressets };
 	 delete this.pressets.status;
 	 this.pressets['result'] = ['Success', 'Fail'];

  }

	listWithPagination(params) {
		 return this.http.get(`${URL_SERVER}/services/report/list/`, {params: params} );
	 }

  list(id) {
	 const params = {executionId: id};
 	 return this.http.get(`${URL_SERVER}/services/report/list/`, {params: params} );
  }

	listAll(id, did, from, to, skip) {
		const params = {
 		 	executionId: id,
			deviceId: did,
			skip: skip,
			from,
			to
		};
		if(from && to){
			params.from = from.getTime();
			params.to = to.getTime();
		}  
	 	return this.http.get(`${URL_SERVER}/services/report/listAll/`, {params: params} );
	}

	getLastReport(){
		return this.http.get(`${URL_SERVER}/services/report/lastReport`);
	}

	getTotalTimeByTeam(){
		return this.http.get(`${URL_SERVER}/services/report/sumAllUsedTime`);
	}

  count(id) {
		const params = {executionId: id};
		return this.http.get(`${URL_SERVER}/services/report/countAll/`, {params: params} );
  }

  view(id: string) {
	const params = {reportId: id};

 	 return this.http.get(`${URL_SERVER}/services/report/view`, {params: params});
  }

	detail(report: any) {
		report.resource.url.replace('http://', 'https://');
		return this.http.get(report.resource.url);
	}

	delete(id : string){
		return this.http.delete(`${URL_SERVER}/services/report/${id}/delete`);
	}

	editErrorCause(error, id){
		return this.http.post(`${URL_SERVER}/services/report/updateErrorCause`, {id: id, errorCause: error});
	}
}
