import { ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { TicketListDialogService } from './ticket-list-dialog.service';
import { DevicesService } from './../../devices/devices.service';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'ticket-list-dialog',
  templateUrl: './ticket-list-dialog.component.html',
  styleUrls: ['./ticket-list-dialog.component.scss']
})
export class TicketListComponent implements OnInit, OnDestroy {    
    @Input() elementDialog: any;
    
    tktList = [];

    constructor(
	    private dialog: MatDialog,
        private router: Router,
        private loginService: LoginService,
        private dialogRef: MatDialogRef<TicketListComponent>,
        private tktService: TicketListDialogService,
        private zone: NgZone,
        private deviceService: DevicesService,
	) { }

    ngOnInit() {
        this.getTicketList();   
    }

    getTicketList(){
        this.tktService.listTickets().subscribe((tktList: any) => {
			this.zone.run(() => {
                this.tktList = tktList.json().filter(tkt => this.canIseeTicket(tkt));
                this.populateDevice(this.deviceService.devices);
			});
        },
        err => {
            this.tktList = [];
            this.dialogRef.close();
        });
	}

    canIseeTicket(tkt){
        if(this.isRoot()){
            return true;
        }

        if(tkt.team && (tkt.team._id == this.loginService.user.team._id)){
            return true;
        }

        return false;
    }

    isRoot(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ROOT') > -1);
    }
    deleteTkt(ticket){
        this.tktService.dealloc(ticket).subscribe((tktList: any) => {
			this.zone.run(() => {
                this.getTicketList();
			});
        },
        (err) => {
            this.dialogRef.close();
        });
    }

    getUser(tkt){
        return tkt.user ? tkt.user.username : 'APPIUM';
    }

    getDevice(tkt){ 
        if(tkt.deviceId.alias){return tkt.deviceId.alias}
        if(tkt.deviceId.model){return tkt.deviceId.model.name}
        return tkt.deviceId;
    }
    populateDevice(devices) {
        this.tktList.forEach(tkt => {
            let id = tkt.deviceId;
            let	device = devices.find(dev => dev._id == id);
            if(device) tkt.deviceId = device;
        });

        console.log(this.tktList);
    }

    ngOnDestroy(): void {}
}
