import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

@Injectable()
export class BugReportDialogService {
  private static readonly BASE_URL = 'services/zendesk';
  constructor(private http: Http) {}

  createTicket(subject: string, comment: string) {
    return this.http.post(`${BugReportDialogService.BASE_URL}/ticket`, {subject, comment});
  }
}
