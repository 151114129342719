import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { wsocketToken, URL_SERVER } from '../providers';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DevicesService {

	manufacturers: any[] = [];
	oss: any[] = [];
	osVersions: any[] = [];
	types: any[] = [];
	models: any[] = [];
	resoutions: any[] = [];
	devices: any[] = [];
	deviceUpdateEvent: EventEmitter<any> = new EventEmitter();

	pressets = {
		types : [] as any[],
		models : [] as any[],
		manufacturers : [] as any[],
		oss : [] as any[],
		osVersions : [] as any[],
		resolutions : [] as any[],
    status : ['ONLINE', 'BUSY', 'OFFLINE', 'UNAUTHORIZED', 'USB_ERROR']
	};

	constructor (
		private http: Http,
		private loginService: LoginService,
		private toastr: ToastrService,
		@Inject(wsocketToken) private wsocket: any
	) {
		this.wsocket.on('device-status', (args: any) => {
			this.devices.forEach( (device: any) => {
				if (device._id === args.deviceId && device.status !== args.status) {
					device.status = args.status;
					device.owner = args.owner;
					this.deviceUpdateEvent.emit(device);
					if(this.loginService.isAdmin() && (args.status.toLowerCase() !== 'online' && args.status.toLowerCase() !== 'busy')){
						this.toastr.info(`${device.alias} ficou [${args.status.toLowerCase()}]`);
					}
				}
			});
		});

		this.wsocket.on('device-logtemp', (args: any) => {
			console.log(args)
			this.devices.forEach( (device: any) => {
				if (device._id === args.deviceId) {
					device.temp = args.temp;
					this.deviceUpdateEvent.emit(device);
				}
			});
		});
	}

	setAvaiablePressets() {
		const ossMap = {};

		for (const device of this.devices){
			if (!ossMap[device.OS.platform]) {
				ossMap[device.OS.platform] = device.OS.platform;
			}
		}
		this.pressets.oss = Object.keys(ossMap).map( (k) => ossMap[k]);
	}


	getPressets() {
		return this.http.get(`${URL_SERVER}/services/device/pressets`);
	}

	getDevices() {
		return this.http.get(`${URL_SERVER}/services/device/list`);
	}

	editDevice(device){
		return this.http.post(`${URL_SERVER}/services/device/edit/deviceInfo`, {device});
	}

	listByCompany(){
		return this.http.get(`${URL_SERVER}/services/device/listByCompany`);
	}

	saveDeviceByAdmin(device){
		return this.http.post(`${URL_SERVER}/services/device/saveDeviceByAdmin`, {device})
	}

	getDeviceHubs(){
		return this.http.get(`${URL_SERVER}/services/device/listHubs`);
	}

	checkCompanyMinutes(compId, deviceId){
		return this.http.post(`${URL_SERVER}/services/device/checkminute`, {compId, deviceId});
	}

	getDeviceFarms(){
		return this.http.get(`${URL_SERVER}/services/device/listFarms`);
	}

	createLiveReport(name, deviceId){
		return this.http.post(`${URL_SERVER}/services/livereport/create`, {name, deviceId});
	}

	finishLiveReport(reportId){
		return this.http.post(`${URL_SERVER}/services/livereport/finish`, {reportId});
	}
}

export class DeviceFilter {
	private static instance: any;

	constructor() {
		if(DeviceFilter.instance){
				throw new Error('Error - use getInstance()');
		}
	}

	static getInstance(clean: Boolean=false): DeviceFilter {
		if(DeviceFilter.instance){
			if(clean){
				DeviceFilter.setClean();
			}
			return DeviceFilter.instance;
		}else{
			DeviceFilter.instance = new DeviceFilter();
			return DeviceFilter.instance;
		}
	}

	static setClean(){
		DeviceFilter.getInstance().types.filter = [];
		DeviceFilter.getInstance().models.filter = [];
		DeviceFilter.getInstance().manufacturers.filter = [];
		DeviceFilter.getInstance().oss.filter = [];
		DeviceFilter.getInstance().osVersions.filter = [];
		DeviceFilter.getInstance().resolutions.filter = [];
		DeviceFilter.getInstance().status.filter = [];
		DeviceFilter.getInstance().result.filter = [];
	}
	types =  {
		seeAll: false,
		filter:  [] as string[]
	};

	models = {
		seeAll : false,
		filter:  [] as string[]
	};

	manufacturers = {
		seeAll: false,
		filter:  [] as string[]
	};

	oss = {
		seeAll: false,
		filter:  [] as string[]
	};

	osVersions = {
		seeAll: false,
		filter:  [] as string[]
	};

	resolutions = {
		seeAll: false,
		filter:  [] as string[]
	};

	status = {
		filter: ['ONLINE', 'BUSY'] as string[]
	};

	result = {
		filter: [] as string[],
		seeAll: false,
	};
}
