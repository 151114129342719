import { OnInit, OnDestroy, Component } from "@angular/core";
import { LoginService } from "../../login/login.service";
import { PendingDevicesService } from './pending-devices.service';
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { EditDeviceDialogComponent } from './edit-device-dialog/edit-device-dialog.component';

@Component({
    selector: 'app-pending-devices',
    templateUrl: './pending-devices.component.html',
    styleUrls: ['./pending-devices.component.scss']
})


export class PendingDevicesComponent implements OnInit, OnDestroy {

    pendingDevices: any[];
    pendingSub: any;

    constructor(
		private router: Router,
    private loginService: LoginService,
		private dialog: MatDialog,
    private pendingDeviceService: PendingDevicesService,
	) {
	}

    ngOnInit(): void {
			if (this.isRoot()) {
				this.pendingSub = this.pendingDeviceService.getPendingDevices().subscribe(async resp => {
						this.pendingDevices = await resp.json();
				});
			} else {
				this.router.navigate(['/']);
			}
    }

    isRoot(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ROOT') > -1);
    }

    edit(device: any) {
        this.dialog.open(EditDeviceDialogComponent, {
            width: '800px',
            data: {
                device
            }
        });
    }

    ngOnDestroy(): void {
        if (this.pendingSub) {
					this.pendingSub.unsubscribe();
				}
    }
}
