import { Component, OnInit, Input } from '@angular/core';
import { ReportDetailComponent } from '../report-detail.component';
import { ReportUtilService } from '../report-util.service';

@Component({
  selector: 'app-report-entry',
  templateUrl: './report-entry.component.html',
  styleUrls: ['./report-entry.component.scss']
})
export class ReportEntryComponent implements OnInit {

	@Input() reportEntry: any;
	@Input() reportDetail: ReportDetailComponent;

  constructor(public reportUtilservice: ReportUtilService) { }

  ngOnInit() {
  }

	setStep() {
		this.reportDetail.setActiveStep(this.reportEntry);
	}

	showDetails() {
		this.reportDetail.showStepResult(this.reportEntry);
	}
}
