import { Injectable, Inject, EventEmitter, NgZone } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVER } from '../providers';

@Injectable()
export class DashboardService {
    constructor (private http: Http) {}
    
    updateWidgets(widget) {
        return this.http.post(`${URL_SERVER}/services/user/update/widgets`, {widget});
    }
    updateWidgetsList(list) {
        return this.http.post(`${URL_SERVER}/services/user/update/widgetsList`, {list});
    }
}