import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss']
})
export class FolderComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<FolderComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

 	name: String;

   ngOnInit() {
 		if (this.data.step !== undefined) {
 			this.name = this.data.step.defaultValue;
 		}
   }

	add() {
 		this.dialogRef.close(this.name);
  }

}
