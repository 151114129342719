import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys'
})

export class KeysPipe implements PipeTransform {
    transform(list: any, filterText?: any): any {
        if(!list || !filterText){
            return list;
        }
        
        return list.filter( i => {
            //Company
            if(i.name && i.name.toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.name.toString().toLowerCase().includes(filterText.toLowerCase());
            };

            //Devices
            if(i.alias && i.alias.toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.alias.toString().toLowerCase().includes(filterText.toLowerCase());
            };
            if(i.serial && i.serial.toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.serial.toString().toLowerCase().includes(filterText.toLowerCase());
            };
            if(i.deviceHub && i.deviceHub.name && i.deviceHub.name.toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.deviceHub.name.toString().toLowerCase().includes(filterText.toLowerCase());
            };
            if(i.model && i.model.name.toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.model.name.toString().toLowerCase().includes(filterText.toLowerCase());
            };

            //Model
            if(i.manufacturer && i.manufacturer.name.toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.manufacturer.name.toString().toLowerCase().includes(filterText.toLowerCase());
            };

            //Accounts
            if(i.username && i.username.toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.username.toString().toLowerCase().includes(filterText.toLowerCase());
            };
            if(i.firstName && i.firstName.toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.firstName.toString().toLowerCase().includes(filterText.toLowerCase());
            };
            if(i.lastName && i.lastName.toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.lastName.toString().toLowerCase().includes(filterText.toLowerCase());
            };
            if(i.contact && i.contact.map(e => e.email).toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.contact.map(e => e.email).toString().toLowerCase().includes(filterText.toLowerCase());
            };

            //User live report
            if(i.user && i.user.username.toLowerCase().includes(filterText.toLowerCase())){
                return i.user.username.toLowerCase().includes(filterText.toLowerCase());
            }
            
            //Log de Acesso
            if(!i.userAccount && !i.user){
                i.userAccount = {firstName: '(Appium)', lastName: '', isFake: true};
            }

            //Devices
            if(i.device && i.device.alias.toString().toLowerCase().includes(filterText.toLowerCase())){
                return i.device.alias.toString().toLowerCase().includes(filterText.toLowerCase());
            };

            if(i.userAccount && i.userAccount.firstName.toLowerCase().includes(filterText.toLowerCase())){
                return i.userAccount.firstName.toLowerCase().includes(filterText.toLowerCase());
            }
            
            if(i.userAccount && i.userAccount.lastName.toLowerCase().includes(filterText.toLowerCase())){
            return i.userAccount.lastName.toLowerCase().includes(filterText.toLowerCase());
            }
    
            if(i.startTime && i.startTime.toString().includes(filterText.toLocaleLowerCase()) || filterText.includes('/') ){
            let nDate = new Date(i.startTime).toLocaleDateString();
            return nDate.includes(filterText.toLocaleLowerCase());
            }
    
            if(i.startTime && i.startTime.toString().includes(filterText.toLocaleLowerCase()) || filterText.includes(':') ){
            let nTime = new Date(i.startTime).toLocaleTimeString();
            return nTime.includes(filterText.toLocaleLowerCase());
            }
        });
    }
}