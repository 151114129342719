import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestOptions, HttpModule, JsonpModule, ResponseOptions } from '@angular/http';
import { TreeModule } from 'angular-tree-component';

import { ConnectModule } from './connect/connect.module';
import { UtilModule } from './util/util.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AppsComponent } from './apps/apps.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardService } from './dashboard/dashboard.service';
import { DevicesComponent } from './devices/devices.component';
import { ShowcaseComponent } from './admin/showcase/showcase.component';
import { PendingDevicesComponent } from './admin/pending-devices/pending-devices.component';
import { AccountsComponent } from './admin/accounts/accounts.component';
import { SuperAdminComponent } from './superadmin/superadmin.component';
import { ResumeCompanyComponent } from './admin/company/resume.component';
import { InternalComponent } from './internal/internal.component';
import { WidgetsComponent } from './dashboard/widgets/widgets.component';

import { ToastrModule } from 'ngx-toastr';

import { D3Service } from 'd3-ng2-service';
import { AppService } from './app.service';
import { LoginService, AuthRequestOptions, AuthResponseOptions } from './login/login.service';
import { DevicesService } from './devices/devices.service';
import { PendingDevicesService } from './admin/pending-devices/pending-devices.service';
import { ShowcaseService } from './admin/showcase/showcase.service'
import { SuperAdminService } from './superadmin/superadmin.service'
import { ResumeCompanyService } from './admin/company/resume.service'
import { AccountsService } from './admin/accounts/accounts.service';
import { ConnectService } from './connect/connect.service';
import { SessionService } from './session/session.service';
import { ReportsService } from './report-tree/reports/reports.service';
//import { ExecutionGroupService } from './execution-group/execution-group.service';
//import { ExecutionGroupComponent } from './execution-group/execution-group.component';
//import { CountSuccessPipe } from './execution-group/count-success.pipe';
//import { CountFailuresPipe } from './execution-group/count-failures.pipe';
//import { CountAllPipe } from './execution-group/count-all.pipe';
import { ReportUtilService } from './report-tree/reports/report-detail/report-util.service';
import { AppsService } from './apps/apps.service';
import { ConfigurationService } from './configuration/configuration.service';
import { WidgetsService } from './dashboard/widgets/widgets.service';

import { routing } from './app.routing';
import { setup } from './bootstrap.factory';
import { $Token, $, windowToken, wsocketToken, localStorageToken, wsocket, localStorage, sessionStorageToken } from './providers';
import { KeysPipe } from './keys.pipe';
import { LogsPipe } from './logs.pipe';

import { ConfigurationComponent } from './configuration/configuration.component';
import { ScriptsComponent } from './scripts/scripts.component';
import { ReportDetailComponent } from './report-tree/reports/report-detail/report-detail.component';
import { ReportEntryComponent } from './report-tree/reports/report-detail/report-entry/report-entry.component';
import { ReportsComponent } from './report-tree/reports/reports.component';

import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { ChangePasswordService } from './login/change-password/change-password.service';
import { SearchPipe } from './search.pipe';
import { SharedModule } from './shared.module';

import { StorageConsuptionComponent } from './dashboard/widgets/storage-consuption/storage-consuption.component';
import { ApiKeyComponent } from './dashboard/widgets/api-key/api-key.component';
import { LastReportComponent } from './dashboard/widgets/last-report/last-report.component';
import { NoReportComponent } from './dashboard/widgets/no-report/no-report.component';
import { CustomWidgetComponent } from './dashboard/widgets/custom/custom-widget.component';
import { TotalTimeUsageComponent } from './dashboard/widgets/total-time-usage/total-time.component';
import { LcaiComponent } from './dashboard/widgets/lcai/lcai.component';
import { WatcherComponent } from './watcher/watcher.component';
import { InternalService } from './internal/internal.service';

import { LiveReportsComponent } from './report-tree/liveReport/liveReport.component';
import { LogsDialogComponent} from './report-tree/reports/report-detail/logs-dialog/logs-dialog.component';
import { MFADialogComponent } from './configuration/MFADialog/mfa-dialog.component';
import { ReleaseNotesDialogComponent } from './internal/release-notes-dialog/release-notes-dialog.component';
import { ErrorDialogComponent } from './report-tree/reports/report-detail/error-dialog/error-dialog.component';
import { TestSuitesComponent } from './test-suites/test-suites.component';
import { RunDialogComponent } from './test-suites/run-dialog/run-dialog.component';
import { EditDeviceDialogComponent } from './admin/pending-devices/edit-device-dialog/edit-device-dialog.component';
import { EditCompanyDialogComponent } from './superadmin/edit-company-dialog/edit-company-dialog.component';
import { SelectListDialogComponent } from './admin/pending-devices/edit-device-dialog/select-list-dialog/select-list.component';
import { CustomDialogComponent } from './superadmin/custom-dialog/custom-dialog.component'
import { EditAccountDialogComponent } from './admin/accounts/edit-account-dialog/edit-account-dialog.component';
import { EditDeviceInfoDialogComponent } from './devices/edit-device-dialog/edit-device-dialog.component';
import { EditTeamDialogComponent } from './admin/accounts/edit-team-dialog/edit-team-dialog.component';
import { ActionDialogComponent } from './admin/accounts/action-dialog/action-dialog.component';
import { SaveDeviceDialogComponent } from './admin/accounts/save-device-dialog/save-device-dialog.component';
import { LogoutService } from './logout/logout.service';
import { BugReportDialogComponent } from './internal/bug-report-dialog/bug-report-dialog.component';
import { BugReportDialogService } from './internal/bug-report-dialog/bug-report-dialog.service';
import { FullScreenDialogComponent } from './connect/fullscreen-dialog/fullscreen-dialog.component';
import { TicketListComponent } from './internal/ticket-list-dialog/ticket-list-dialog.component';
import { TicketListDialogService } from './internal/ticket-list-dialog/ticket-list-dialog.service';
import { ExecutionComponent } from './report-tree/execution/execution.component';
import { PreviewComponent } from './report-tree/execution/preview/preview.component';
import { ReportTreeComponent } from './report-tree/report-tree.component';
import { ReportBySessionComponent } from './report-tree/reportBySession/reportBySession.component';


import { MatProgressSpinnerModule, MatProgressBarModule, MatDatepickerModule, MatNativeDateModule, MatSlideToggleModule, MAT_DATE_LOCALE, MatSliderModule, MatSelectModule, MatOptionModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DeleteDialogComponent } from './test-suites/delete-dialog/delete-dialog.component';
import { TestcaseDialogComponent } from './test-suites/testcase-dialog/testcase-dialog.component';

import { DeviceAccessLogComponent } from './deviceAccessLog/device-access-log.component';
import { DeviceAccessLogService } from './deviceAccessLog/device-access-log.service';
import { GridsterModule } from 'angular-gridster2';
import { DevicesWidgetComponent } from './dashboard/widgets/devices/devicesWidget.component';
import { ActionHistoryComponent } from './admin/action-history/action-history.component';
import { EditWidgetDialogComponent } from './dashboard/editWidgetDialog/edit-widget-dialog.component';
import { DeviceStatusLogService } from './deviceStatusLog/device-status-log.service';
import { DeviceStatusLogComponent } from './deviceStatusLog/device-status-log.component';
import { CustomLoginComponent } from './login/customLogin/customLogin.component';

@NgModule({
  declarations: [
    AppComponent,
		LoginComponent,
		CustomLoginComponent,
		ChangePasswordComponent,
    AppsComponent,
    DashboardComponent,
    ReportsComponent,
		DevicesComponent,
		ShowcaseComponent,
		SuperAdminComponent,
		ResumeCompanyComponent,
		PendingDevicesComponent,
		AccountsComponent,
    InternalComponent,
		KeysPipe,
		SearchPipe,
		LogsPipe,
    ReportDetailComponent,
    ReportEntryComponent,
		AppsComponent,
		ConfigurationComponent,
		ScriptsComponent,
		WidgetsComponent,
		StorageConsuptionComponent,
		ApiKeyComponent,
		DevicesWidgetComponent,
		LastReportComponent,
		NoReportComponent,
		CustomWidgetComponent,
		TotalTimeUsageComponent,
		LcaiComponent,
		WatcherComponent,
		LogsDialogComponent,
    TestSuitesComponent,
		ActionHistoryComponent,
		RunDialogComponent,
		EditDeviceDialogComponent,
		EditAccountDialogComponent,
		EditDeviceInfoDialogComponent,
		EditTeamDialogComponent,
		ActionDialogComponent,
		SaveDeviceDialogComponent,
		SelectListDialogComponent,
		CustomDialogComponent,
		// FullScreenDialogComponent,
		BugReportDialogComponent,
		MFADialogComponent,
		EditCompanyDialogComponent,
		ReportBySessionComponent,
		TicketListComponent,
		ExecutionComponent,
		PreviewComponent,
		ReleaseNotesDialogComponent,
		ErrorDialogComponent,
		DeleteDialogComponent,
		DeviceAccessLogComponent,
		DeviceStatusLogComponent,
		TestcaseDialogComponent,
		EditWidgetDialogComponent,
		ReportTreeComponent,
		LiveReportsComponent,
  ],
  imports: [
		SharedModule,
    	BrowserModule,
		BrowserAnimationsModule,
    	FormsModule,
		MatSelectModule,
		MatOptionModule,
		ReactiveFormsModule,
		HttpModule,
		JsonpModule,
    	routing,
		MatSlideToggleModule,
		ConnectModule,
		TreeModule,
		UtilModule,
		MatDatepickerModule,
		MatSlideToggleModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatNativeDateModule,
		MatSliderModule,
		DragDropModule,
		GridsterModule,
		ToastrModule.forRoot({
			preventDuplicates: true
		})
  ],
  providers: [
    		AppService,
    		LoginService,
    		LogoutService,
		DevicesService,
		PendingDevicesService,
		ShowcaseService,
		AccountsService,
		ConnectService,
		SessionService,
		DashboardService,
		ReportsService,
		SuperAdminService,
		ResumeCompanyService,
		AppsService,
		ReportUtilService,
		D3Service,
		ConfigurationService,
		WidgetsService,
		ChangePasswordService,
    InternalService,
		BugReportDialogService,
		TicketListDialogService,
		DeviceAccessLogService,
		DeviceStatusLogService,
    { provide: RequestOptions, useClass: AuthRequestOptions},
    { provide: ResponseOptions, useClass: AuthResponseOptions },
		{ provide: wsocketToken, useValue:  wsocket },
		{ provide: $Token, useValue:  $ },
		{ provide: windowToken, useValue:  window },
		{ provide: localStorageToken, useValue:  localStorage },
		{ provide: sessionStorageToken, useValue:  sessionStorage },
		{
			provide: APP_INITIALIZER,
			useFactory: setup,
			deps: [LoginService, DevicesService, DashboardService],
			multi: true,
		},
		{provide: MAT_DATE_LOCALE, useValue: 'pt-BR'}
	],
  bootstrap: [AppComponent],
  entryComponents: [
		LogsDialogComponent,
		RunDialogComponent,
		BugReportDialogComponent,
		// FullScreenDialogComponent,
		TicketListComponent,
		SelectListDialogComponent,
		CustomDialogComponent,
		EditDeviceDialogComponent,
		EditAccountDialogComponent,
		EditDeviceInfoDialogComponent,
		EditTeamDialogComponent,
		ActionDialogComponent,
		SaveDeviceDialogComponent,
		PreviewComponent,
		EditCompanyDialogComponent,
		MFADialogComponent,
		ReleaseNotesDialogComponent,
		ErrorDialogComponent,
		DeleteDialogComponent,
		TestcaseDialogComponent,
		EditWidgetDialogComponent
	]
})
export class AppModule { }
