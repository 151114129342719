import {  Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReportsService } from '../../reports.service';

@Component({
  selector: 'error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
	
	ngOnInit(): void {
		
	}

	constructor(
		public dialogRef: MatDialogRef<ErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
		private reportsService: ReportsService
	) {
	}

	save(){
		this.reportsService.editErrorCause(this.data.report.reason, this.data.report._id).subscribe(async rp => {
			let resp = rp.json();
			console.log(resp);

			this.dialogRef.close();
		});
	}
}
