import { Component, OnInit, AfterViewInit, AfterViewChecked, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ReportsService } from './reports.service';
import { TestsService } from '../../tests/tests.service';
import { Router, ActivatedRoute, Params, NavigationExtras, ParamMap } from '@angular/router';
import { DevicesService, DeviceFilter } from '../../devices/devices.service';
import { Observable } from 'rxjs';//possivel troca pra 'rxjs'
import { PAGINATION_LIMIT } from '../../providers';
import { FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { D3Service, D3, Selection } from 'd3-ng2-service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit {

	@ViewChild('deleteReportModal') deleteReportModal: ElementRef;

	private d3: D3;
	rawReportList: any[] = [];
	reportList: any[];
	executionList: any;
	reportFilter: DeviceFilter = DeviceFilter.getInstance();
  executionGroupName: string;
	totalNumberOfReports: any = 0;
	executionId: any = this.route.snapshot.params.executionId;
	deviceId: any = this.route.snapshot.params.deviceId;
	device: any;
	limitPerPage: any  = PAGINATION_LIMIT;
	totalPages: any = 0;
	reportId: any = null;
	translate = {
		'Success': 'sucesso',
		'Fail': 'falha',
	};
	skip:any = 0;

	@ViewChild('reportMetrics') reportMetricsContainer: ElementRef;
	@ViewChild('pieMetrics') pieMetricsContainer: ElementRef;
	metricsSVG: any;
	metricsG: any;
	pieSVG: any;
	pieG: any;

	filterFrom: Date;
	filterTo: Date;

	minDate: Date = new Date(2000, 0, 1);
  maxDate: Date = new Date(2020, 0, 1);
	durationTotal: any = 0;
	durationAvarage: any = 0;
	durationSuccess: any = 0;
	durationFail: any = 0;
	successCount = 0;
	failCount = 0;

	constructor(
    public reportsService: ReportsService,
		private devicesService: DevicesService,
		private router: Router,
		private route: ActivatedRoute,
    	private testsService: TestsService,
		private d3Service: D3Service,
	) {
		this.d3 = d3Service.getD3();

		const currentDate: Date = new Date();
		this.minDate = new Date(currentDate.getTime() - (180 * 24 * 60 * 60 * 1000));
		this.maxDate = new Date(currentDate.setHours(23,59,59));
		
		this.filterTo = new Date(currentDate);
		this.filterTo.toLocaleDateString();
		this.filterFrom = new Date(currentDate.getTime() - (60 * 24 * 60 * 60 * 1000));
		this.filterFrom.toLocaleDateString();

		this.testsService.getExecutionsByDate(this.executionId as string, this.filterFrom, this.filterTo).subscribe(async (response) => {
		const data = await response.json();

		if (!data) {
			return;
		}

		this.executionGroupName = data.name;
				this.device = this.devicesService.devices.find((device) => device._id === this.deviceId);
		});

	}

	renderExecutions() {
		const executions = {};
		this.reportList.forEach((r) => {
			const executionNames = Object.keys(executions);
			if (r.executionName && executionNames.indexOf(r.executionName) === -1 ) {
				executions[r.executionName] = [r];
			} else if (executionNames.indexOf(r.executionName) > -1) {
				executions[r.executionName].push(r);
			} else {
			if (!executions['Uncategorized']) {
				executions['Uncategorized'] = [];
			}
				executions['Uncategorized'].push(r);
			}
		});

	}

	ngOnInit() {
		this.route.queryParams.subscribe((params: Params) => {
			this.listReports();
		});
	}

	ngAfterViewInit() {
		// setTimeout(() => this.buildMetrics(), 1000);
	}

	applyFilter() {
		this.listReportsAndYourQuantity(true);
	}

	buildMetrics() {

		const dateMap = {};
		const successMetric = [];
		const failMetric = [];
		const totalMetric = [];

		this.successCount = 0;
		this.failCount = 0;
		this.durationTotal = 0;
		this.durationAvarage = 0;
		this.durationSuccess = 0;
		this.durationFail = 0;

		this.reportList.forEach((el) => {
			const d = new Date(el.creationDate);
			const key = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
			if (dateMap[key] === undefined) {
				dateMap[key] = [el];
			} else {
				dateMap[key].push(el);
			}

			this.durationTotal += el.duration;
			if (el.succeed) {
				this.successCount++;
				this.durationSuccess += el.duration;
			} else {
				this.failCount++;
				this.durationFail += el.duration;
			}
		});

		this.durationTotal = (this.durationTotal / 60).toFixed(2);
		this.durationFail = ((this.durationFail / 60) / this.failCount).toFixed(2);
		this.durationSuccess = ((this.durationSuccess / 60) / this.successCount).toFixed(2);
		this.durationAvarage = (this.durationTotal / this.reportList.length).toFixed(2);

		if (isNaN(this.durationTotal)) {
			this.durationTotal = 0;
		}

		if (isNaN(this.durationFail)) {
			this.durationFail = 0;
		}

		if (isNaN(this.durationAvarage)) {
			this.durationAvarage = 0;
		}

		if (isNaN(this.durationSuccess)) {
			this.durationSuccess = 0;
		}

		/*const sortedReporList = this.reportList.sort(function(a, b) {
				return new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime();
		});*/

		// const day0 =  new Date(sortedReporList[0].creationDate);
		// const dayN = new Date(sortedReporList[sortedReporList.length - 1].creationDate);
		const day0 = this.filterFrom;
		const dayN = this.filterTo;
		const daysBetween = Math.round((dayN.getTime() - day0.getTime()) / (1000 * 60 * 60 * 24));

		for (let i = 0; i < daysBetween; i++) {
			const d = new Date(day0.getTime());
			d.setDate(day0.getDate() + i);

			const key = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
			if (dateMap[key] === undefined) {
				dateMap[key] = [];
			}
		}


		Object.keys(dateMap).forEach((k) => {
			const date = this.d3.timeParse('%e-%m-%Y')(k);
			const success = {date: date, value: 0};
			const fail = {date: date, value: 0};
			const total = {date: date, label: `${date.getMonth() + 1}/${date.getDate()}`, value: 0, success: 0, fail: 0};
			dateMap[k].forEach((e) => {
				if (e.succeed) {
					success.value++;
					total.success++;
				} else {
					fail.value++;
					total.fail++;
				}
				total.value++;
			});

			totalMetric.push(total);
			successMetric.push(success);
			failMetric.push(fail);
		});

		const metricData = this.reportList.filter((r) => r.succeed);
		const svgWidth = this.reportMetricsContainer.nativeElement.offsetWidth;
		const svgHeight = 175;
		const ref = this;
		const offset = 30;

		const margin = {top: 20, right: 20, bottom: 30, left: 40};
	 	const width = svgWidth - margin.left - margin.right;
	 	const height = svgHeight - margin.top - margin.bottom;

		if (this.metricsSVG) {
			this.metricsSVG.remove();
		}

		this.metricsSVG = this.d3.select(this.reportMetricsContainer.nativeElement)
		.append('svg').attr('width', svgWidth).attr('height', svgHeight);

		this.metricsG = this.metricsSVG.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);

		const max = this.d3.max(totalMetric, function(d: any) { return d.value; });

		this.buildBarGraphic(width, height, totalMetric);
		this.buildPieGraphic(this.successCount, this.failCount);
	}

	buildPieGraphic(success, fail) {
		console.log(success, fail);
		const data = [success, fail];

		const width = 90,
		    height = 90,
		    radius = Math.min(width, height) / 2;

		const color = this.d3.scaleOrdinal().range(['#44a461', '#ee2c43']);

		const arc = this.d3.arc()
		.outerRadius(radius - 10)
		.innerRadius(0);

		const labelArc = this.d3.arc()
		.outerRadius(radius - 40)
		.innerRadius(radius - 40);

		const pie = this.d3.pie()
		.sort(null)
		.value(function(d: any) { return d; });

		if (this.pieSVG) {
			this.pieSVG.remove();
		}

	this.pieSVG = this.d3.select(this.pieMetricsContainer.nativeElement).append('svg')
	    .attr('width', width)
	    .attr('height', height);

			this.pieG = this.pieSVG
	  	.append('g')
	    .attr('transform', `translate(${width / 2},${height / 2})`);

	  const g = this.pieG.selectAll('.arc').data(pie(data)).enter().append('g').attr('class', 'arc');
		g.append('path').attr('d', <any>arc).style('fill', function(d) {
			return color(d.index.toString()) as any;
		});

	  /*g.append("text")
	      .attr("transform", function(d) { return "translate(" + labelArc.centroid(d) + ")"; })
	      .attr("dy", ".35em")
	      .text(function(d) { return d.data; });*/
	}

	buildBarGraphic(width, height, metric) {

		const ref = this;

		const x = ref.d3.scaleBand()
	    .rangeRound([0, width])
	    .paddingInner(0.05)
	    .align(0.1);

		const y = ref.d3.scaleLinear()
	    .rangeRound([height, 0]);

		const z = ref.d3.scaleOrdinal()
    .range(['#44a461', '#ee2c43']);

		metric = metric.sort(function(a, b) {
			return new Date(a.date).getTime() - new Date(b.date).getTime();
		});

		const keys = ['success', 'fail'];

		x.domain(metric.map(function(d) { return d.label; }));
		const yMax = parseInt(ref.d3.max(metric, function(d: any) { return d.value ; }), 10);
  	y.domain([0, yMax]).nice();
  	z.domain(keys);

		const g = this.metricsG;
			g.append('g')
		 .selectAll('g')
		 .data(ref.d3.stack().keys(keys)(metric))
		 .enter().append('g')
		 .attr('fill', function(d) { return z(d.key); })
		 .selectAll('rect')
		 .data(function(d) { return d; })
		 .enter().append('rect')
		 .attr('x', function(d) {
			 return x(d.data.label);
		 })
	 .attr('y', function(d) { return y(d[1]); })
	 .attr('height', function(d) { return y(d[0]) - y(d[1]); })
	 .attr('width', x.bandwidth())
	 .on('mouseover', function() { tooltip.style('display', null); })
	 .on('mouseout', function() { tooltip.style('display', 'none'); })
	 .on('mousemove', function(d) {
		 const xPosition = ref.d3.mouse(this)[0];
		 const yPosition = ref.d3.mouse(this)[1] + 20;
		 tooltip.attr('transform', 'translate(' + xPosition + ',' + yPosition + ')');
		 tooltip.select('text').text(`Date: ${d.data.label}
			 Success: ${d.data.success}
			 Fail: ${d.data.fail}`);
	 });

	if (metric.length < 35) {
		g.append('g')
				.attr('class', 'axis')
				.attr('transform', 'translate(0,' + height + ')')
				.call(ref.d3.axisBottom(x));
	} else {
		g.append('g')
				.attr('class', 'axis')
				.attr('transform', 'translate(0,' + height + ')')
				.call(ref.d3.axisBottom(x).tickFormat(function(d, i){
					return '';
				}));
	}

 g.append('g')
		 .attr('class', 'axis')
		 .call(ref.d3.axisLeft(y).ticks(yMax))
		 .append('text')
		 .attr('x', 2)
		 .attr('y', y(y.ticks().pop()) + 0.5)
		 .attr('dy', '0.32em')
		 .attr('fill', '#000')
		 .attr('font-weight', 'bold')
		 .attr('text-anchor', 'start');

	/*const legend = g.append('g')
		.attr('font-family', 'sans-serif')
		.attr('font-size', 10)
		.attr('text-anchor', 'end')
		.selectAll('g')
		.data(keys.slice().reverse())
		.enter().append('g')
		.attr('transform', function(d, i) { return 'translate(0,' + i * 20 + ')'; });

	legend.append('rect')
		.attr('x', width - 19)
		.attr('width', 19)
		.attr('height', 19)
		.attr('fill', z);

	legend.append('text')
		.attr('x', width - 24)
		.attr('y', 9.5)
		.attr('dy', '0.32em')
		.text(function(d) { return d; });*/

		const tooltip = g.append('g')
		 .attr('class', 'tooltip')
		 .style('display', 'none');

		/*tooltip.append('rect')
			.attr('width', 210)
			.attr('height', 20)
			.attr('fill', 'white')
			.style('opacity', 1);*/

		tooltip.append('text')
			.attr('x', 0)
			.attr('dy', '1.2em')
			.style('text-anchor', 'middle')
			.attr('font-size', '12px')
			.attr('font-weight', 'bold');
	}

	listReportsAndYourQuantity(reset = false) {
		this.reportsService.listAll(this.executionId, this.deviceId, this.filterFrom, this.filterTo, this.skip).subscribe(
			async (result) => {
				if(reset){
					this.reportList = this.rawReportList = [];	
				}
				let data = await result.json();
				this.reportList = this.rawReportList = this.rawReportList.concat(...data);
				this.buildMetrics();

			},
		(error => console.log(error))
		);
	}

	listReports() {
		this.reportsService.listAll(this.executionId, this.deviceId, null, null, this.skip).subscribe(
			async (result) => {
				let data = await result.json();
				this.reportList = this.rawReportList = this.rawReportList.concat(...data);

				this.filterFrom = new Date(this.reportList[this.reportList.length-1].creationDate);
				this.filterTo = new Date(this.reportList[0].creationDate);

				this.minDate = new Date(this.filterFrom.getFullYear(), (this.filterFrom.getMonth()-1), this.filterFrom.getDate());
				this.maxDate = new Date(this.filterTo.getFullYear(), (this.filterTo.getMonth()+1), this.filterTo.getDate(), 23, 59, 59);
				
				this.buildMetrics();
			},
		(error => console.log(error))
		);
	}

	loadMore(){
		this.skip = (this.skip+100);
		this.listReportsAndYourQuantity();
	}

	openDialog(element: any, idReport: any) {
		element.style.display = 'block';
		this.reportId = idReport;
	}

	closeDialog(element: any) {
		element.style.display = 'none';
	}

	deleteReport() {
		this.reportsService.delete(this.reportId)
						   	.subscribe((result) => {
								this.closeDialog(this.deleteReportModal.nativeElement);
								this.reportList = this.reportList.filter(report => report._id !== this.reportId);

								if (this.reportList.length === 0) {
									this.router.navigate(['/reports']);
								}
						   	},
							(error) => {
								this.closeDialog(this.deleteReportModal);
								console.log(error);
							});
	}

	onClickSeeMore(type: string) {
		this.reportFilter[type].seeAll = true;
	}

	onChangeFilter(event: any, type: string, value: any) {

		let ref;
		value && typeof(value) === 'string' ? ref = value : ref = value._id;

		if (event.checked) {
			if (this.reportFilter[type].filter.indexOf(ref) === -1) {
				this.reportFilter[type].filter.push(ref);
			}
		} else {
			if (this.reportFilter[type].filter.indexOf(ref) !== -1) {
				this.reportFilter[type].filter.splice(this.reportFilter[type].filter.indexOf(ref), 1);
			}
		}
		//this.filterReports();
	}

	getList(type: string) {
		if (this.reportFilter[type].seeAll) {
			return this.reportsService.pressets[type];
		}
			return this.reportsService.pressets[type].slice(0, 5);
 	}

	openReport(reportId) {
		this.router.navigate(['/report-suites', this.executionId, this.deviceId, reportId]);
	}

	getThumbUrl(device){		
		return device.model.urlThumb ? device.model.urlThumb : (device.model.thumb ? 'assets/images/thumbs/'+device.model.thumb : 'assets/images/iphone.svg');
	}
	
	clearExecution() {
		this.router.navigate(['/report-suites', this.executionId]);
	}
}
