import {  ChangeDetectorRef, Input, Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DevicesService } from './../devices.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { SelectListDialogComponent } from '../../admin/pending-devices/edit-device-dialog/select-list-dialog/select-list.component';

@Component({
  selector: 'edit-device-dialog',
  templateUrl: './edit-device-dialog.component.html',
  styleUrls: ['./edit-device-dialog.component.scss']
})
export class EditDeviceInfoDialogComponent implements OnInit, OnDestroy {

	@Input() elementDialog: any;
	deviceForm: FormGroup;
	respError = '';
	deviceSub:any;
	isLoading: boolean = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
		private deviceService: DevicesService,
        private dialog: MatDialog, 
		public dialogRef: MatDialogRef<EditDeviceInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any        
	) {
	}

	ngOnInit() {
        if(!this.data.device.simCards.lenght){
					this.data.device.simCards = [{company:'', number:''}];
				}
        this.deviceForm = new FormGroup({
            name: new FormControl({value: this.data.device.alias}, Validators.required),
            order: new FormControl({value: this.data.device.ordnance}, [Validators.required, Validators.pattern('[0-9]*')]),
            chip: new FormControl({value: this.data.device.simCards[0].company}), 
			phonenumber: new FormControl({value: this.data.device.simCards[0].number}),
			platform: new FormControl({value: this.data.device.OS.platform}, Validators.required),
			version: new FormControl({value: this.data.device.OS.version}, [Validators.required, Validators.pattern('[0-9.]*')]),
        });
    }

    saveDevice(){
		if(this.deviceForm.valid){
			this.isLoading = true;
            console.log(this.data.device)
			this.deviceSub = this.deviceService.editDevice(this.data.device).subscribe(resp => {
                this.sortDevices();
				this.isLoading = false;
                this.dialogRef.close();
			}, err => {
				this.isLoading = false;
				console.log(err)
			});
		}
	}
	
	list(){
        this.dialog.open(SelectListDialogComponent, {
			width: '400px',
			height: '400px',
            data: {
				list: [{name: 'Android'},{name: 'iOS'}],
				type: 'platform',
				device: this.data.device
            }
        })
	}
	
    sortDevices(){
		this.data.list.sort((a:any, b:any) => {
			if(parseInt(a.ordnance) === parseInt(b.ordnance)){
				return a.alias < b.alias ? -1 : (a.alias > b.alias ? 1 : 0);
			}
			return parseInt(a.ordnance) < parseInt(b.ordnance) ? -1 : (parseInt(a.ordnance) > parseInt(b.ordnance) ? 1 : 0);
		});
    }
    
	ngOnDestroy() {
		if(this.deviceSub) this.deviceSub.unsubscribe();
		this.changeDetector.detach();
	}
}
