import { Injectable, Inject } from '@angular/core';
import {
	Http,
	Headers,
	BaseRequestOptions,
	RequestOptionsArgs,
	RequestOptions,
	BaseResponseOptions,
	ResponseOptionsArgs,
	ResponseOptions
} from '@angular/http';

import { localStorageToken, sessionStorageToken, wsocketToken, URL_SERVER, ISDEBUG } from '../providers';
import { LogoutService } from '../logout/logout.service';

@Injectable()
export class LoginService {
	
	private static _authToken: String = undefined;
	
	isLoggedIn: Boolean = false;
	user: any;
	
	static authToken() {
		if (!LoginService._authToken) {
			return '';
		}
		return LoginService._authToken;
	}
	
	constructor (
		private http: Http,
		@Inject(localStorageToken) private localStorage: any,
		@Inject(sessionStorageToken) private sessionStorage: any,
		@Inject(wsocketToken) private wsocket: any,
		) {
			LoginService._authToken = localStorage.getItem('Auth-Token');
			
			if (!LoginService._authToken) {
				LoginService._authToken = sessionStorage.getItem('Auth-Token');
			}
			
			console.log('Login Service')
			console.log(this.user)
		}
		
		doLogin(username: string, password: string, remember: Boolean, token: string = '') {
			console.log('Try login')
			if(ISDEBUG){
				this.isLoggedIn = true;
			}
			console.log(`apontando para ${URL_SERVER}`);
			return this.http.post(`${URL_SERVER}/services/user/signin`, {username, password, remember, token});
		}
		
		customLogin(username: string, password: string, customAPI: string) {
			console.log('Try custom login')
			
			console.log(`apontando para ${URL_SERVER}/${customAPI}`);
			return this.http.post(`${URL_SERVER}/${customAPI}`, {username, password});
		}

		doLogout() {
			localStorage.removeItem('Auth-Token');
			sessionStorage.removeItem('Auth-Token');
			this.isLoggedIn = false;
			location.reload();
		}
		
		checkAuth() {
			return this.http.get(`${URL_SERVER}/services/user/check`);
		}
		
		auth(succeded: Boolean) {
			this.isLoggedIn = succeded;
			this.wsocket.emit('auth', LoginService.authToken());
		}
		
		createMFAToken(){
			return this.http.get(`${URL_SERVER}/services/user/mfa/create`);
		}
		
		checkValidToken(mfa) {
			return this.http.post(`${URL_SERVER}/services/user/mfa/verify`, mfa);
		}
		
		isAdmin(){
			return this.user.roles && (this.user.roles.indexOf('ADMIN') > -1 || this.user.roles.indexOf('ROOT') > -1)
		}
		
		isRoot(){
			return this.user.roles && (this.user.roles.indexOf('ROOT') > -1);
		}
		
		forgotPassword(emailToSend, username) {
			const params = {email: emailToSend, username: username};
			return this.http.get(`${URL_SERVER}/services/user/forgot-password`, {params: params});
		}
		
		updateUser(user){
			return this.http.post(`${URL_SERVER}/admin/account/save`, {account: user});
		}
		
		checkCustomPath(currentPath){
			return this.http.post(`${URL_SERVER}/services/custom/check`, {currentPath});
		}
		
		changeFlagReport(teamId, noReport){
			return this.http.post(`${URL_SERVER}/admin/account/changeFlagReport`, {teamId, noReport});
		}
	}
	
	@Injectable()
	export class AuthRequestOptions extends BaseRequestOptions {
		constructor() {
			super();
		}
		
		merge(options?: RequestOptionsArgs): RequestOptions {
			if (!options.headers) {
				options.headers = new Headers();
			}
			
			options.headers.append('Auth-Token', LoginService.authToken().toString());
			return super.merge(options);
		}
	}
	
	@Injectable()
	export class AuthResponseOptions extends BaseResponseOptions {
		constructor(private logoutService: LogoutService) {
			super();
		}
		
		merge(options?: ResponseOptionsArgs): ResponseOptions {
			if (options.status === 401) {
				this.logoutService.doLogout();
			}
			return super.merge(options);
		}
	}
