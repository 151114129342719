import {  ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs'; //possivel troca pra 'rxjs'
import { DevicesService, DeviceFilter } from '../../devices/devices.service';
import { ConnectService } from '../connect.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-connect-dialog',
  templateUrl: './connect-dialog.component.html',
  styleUrls: ['./connect-dialog.component.scss']
})
export class ConnectDialogComponent implements OnInit, OnDestroy {

	@Input() elementDialog: any;
	@Input() deviceList = [] as any[];
	deviceUpdateSubscription: Subject<any>;
	deviceFilter: DeviceFilter = DeviceFilter.getInstance();

	constructor(
		public snackBar: MatSnackBar,
		private router: Router,
		public devicesService: DevicesService,
		private connect: ConnectService,
		private loginService: LoginService,		
		private toastr: ToastrService,
		private changeDetector: ChangeDetectorRef,
		private zone: NgZone,
		//public dialogRef: MatDialogRef<ConnectDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	ngOnInit() {

		this.filterDevices();
		this.sortDevices();
		this.deviceUpdateSubscription = this.devicesService.deviceUpdateEvent.subscribe((device: any) => {
			this.zone.run(() => {
				this.deviceList.forEach((fullDevice: any) => {
					if (fullDevice._id === device._id) {
						fullDevice.status = device.status;
					}
				});
			});
		});
	}

	ngOnDestroy() {
		this.changeDetector.detach();
		this.deviceUpdateSubscription.unsubscribe();
	}

	isChecked(type, value){
		let ref;
		if(value){
			typeof(value) === 'string' ? ref=value : ref=value._id;
		}
		return (this.deviceFilter[type].filter.indexOf(ref)) > -1;
	}
	
	filterDevices() {
		const allowedDevices = this.devicesService.devices;

		this.deviceList = allowedDevices.filter((device) => {
			let valid = true;
			for (const presset of Object.keys(this.deviceFilter)) {
				if (this.deviceFilter[presset].filter.length > 0) {
					switch (presset) {
						case 'oss': {
							if (this.deviceFilter[presset].filter.indexOf(device.OS.platform.toLowerCase()) === -1) {
								valid = false;
							}
						}
						break;
						case 'status': {
							if (this.deviceFilter[presset].filter.indexOf(device.status) === -1) {
								valid = false;
							}
						}
						break;
					}
				}
			}
			return valid;
		});
		this.sortDevices();
	}

	onClickSeeMore(type: string) {
		this.deviceFilter[type].seeAll = true;
	}

	onClickConnect(device: any) {
		this.devicesService.checkCompanyMinutes(this.loginService.user.team.company._id, device._id).subscribe(async data => {
			let resp = await data.json();
			if(resp.status){
				if (device.status === 'ONLINE') {
					this.connect.requestConnection(device);
					//this.router.navigate(['/connect']);
					//this.dialogRef.close()
				}
			}else{
				this.toastr.warning(resp.message);
			}
		});
	}

	onClickReconnect(device: any) {
		this.router.navigate(['/devices/connect']);
	}

	onClickDisconnect(device: any) {
		this.connect.releaseConnection(device);
	}

	isConnected(device: any): Boolean {
		const index = this.connect.activeDevices.findIndex((itDevice: any) => itDevice.device._id === device._id);
		return index !== -1;
	}

	onChangeFilter(event: any, type: string, value: any) {

		let ref;
		if (value && typeof(value) === 'string') {
			ref = value;
		} else {
			ref = value._id;
		}

		if (event.checked) {
			if (this.deviceFilter[type].filter.indexOf(ref) === -1) {
				this.deviceFilter[type].filter.push(ref);
			}
		} else {
			if (this.deviceFilter[type].filter.indexOf(ref) !== -1) {
				this.deviceFilter[type].filter.splice(this.deviceFilter[type].filter.indexOf(ref), 1);
			}
		}
		this.filterDevices();
	}

	getList(type: string) {
		if (this.deviceFilter[type].seeAll) {
			return this.devicesService.pressets[type];
		} else {
			return this.devicesService.pressets[type].slice(0, 5);
		}
 	}

 	getThumbUrl(device){		
		return device.model.urlThumb ? device.model.urlThumb : (device.model.thumb ? 'assets/images/thumbs/'+device.model.thumb : 'assets/images/iphone.svg');
	}
	
	sortDevices(){
		this.deviceList.sort((a:any, b:any) => {
			return a.alias < b.alias ? -1 : (a.alias > b.alias ? 1 : 0) ;
		});
	}

	showInfo(data: string) {
		const message = `Device ID: ${data}`;
		this.snackBar.open(message, null, {
		duration: 5000,
		});
	}

}
