import {  ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';//possivel troca pra 'rxjs'
import { DevicesService, DeviceFilter } from '../../devices/devices.service';
import { ConnectService } from '../../connect/connect.service';
import { TestsService } from '../../tests/tests.service';
import { MatSnackBar } from '@angular/material';
import { URL_SERVER } from '../../providers';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-run-dialog',
  templateUrl: './run-dialog.component.html',
  styleUrls: ['./run-dialog.component.scss']
})
export class RunDialogComponent implements OnInit, OnDestroy {

	@Input() elementDialog: any;
	@Input() deviceList = [] as any[];
	deviceUpdateSubscription: Subject<any>;
	deviceFilter: DeviceFilter = DeviceFilter.getInstance();

	limitControl = new FormControl('', [Validators.max(15), Validators.min(0)]);

	scheduleControl;

	devFilter:any;
	step = 0;
	repetitions = 1;
	schedule: Date;
	notifySlack: boolean = false;
	openWatcher: boolean = false;
	slackOpt = {token: '', channelId: ''};

	choosenDevices = [] as any[];

	constructor(
		public snackBar: MatSnackBar,
		private router: Router,
		public devicesService: DevicesService,
		private connect: ConnectService,
		private testsService: TestsService,
		private toastr: ToastrService,
		private changeDetector: ChangeDetectorRef,
		private loginService: LoginService,
		private zone: NgZone,
		public dialogRef: MatDialogRef<RunDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	sleep = async (duration) => {
		return new Promise((resolve) => {
			setTimeout(resolve, duration);
		});
	}

	scheduleValidator(control) {

		const great7Days = new Date(control.value).getTime() > new Date().getTime() + 1000 * 60 * 60 * 24 * 7;
		// const greatToday = new Date(control.value).getTime() < new Date().getTime();

		if (great7Days) {
				return  {'schedule': {value: control.value}};
		}
		return  null;
	}

	ngOnInit() {

		this.scheduleControl = new FormControl('', [this.scheduleValidator]);

		this.filterDevices();
		this.sortDevices();
		this.deviceUpdateSubscription = this.devicesService.deviceUpdateEvent.subscribe((device: any) => {
			this.zone.run(() => {
				this.deviceList.forEach((fullDevice: any) => {
					if (fullDevice._id === device._id) {
						fullDevice.status = device.status;
					}
				});
			});
		});
	}

	ngOnDestroy() {
		this.changeDetector.detach();
		this.deviceUpdateSubscription.unsubscribe();
	}

	filterDevices() {
		const rawDevices = this.devicesService.devices;
		const allowedDevices = rawDevices.filter((device) => {
			if (!this.loginService.user.team.allowedDevices || this.loginService.user.team.allowedDevices.length === 0) {
				return true;
			} else {
				return this.loginService.user.team.allowedDevices.indexOf(device._id) > -1;
			}
		});

		this.deviceList = allowedDevices.filter((device) => {
			let valid = true;

			valid = device.status !== 'OFFLINE';

			return valid;
		});
		this.sortDevices();
	}

	onClickSeeMore(type: string) {
		this.deviceFilter[type].seeAll = true;
	}

	onClickConnect(device: any) {
		
		this.devicesService.checkCompanyMinutes(this.loginService.user.team.company._id, device._id).subscribe(async data => {
			let resp = await data.json();
			if(resp.status){
				if (device.status === 'ONLINE') {
					this.connect.requestConnection(device);
					this.router.navigate(['/connect']);
				}
			}else{
				this.toastr.warning(resp.message);
			}
		});
	}

	onClickReconnect(device: any) {
		this.router.navigate(['/devices/connect']);
	}

	onClickDisconnect(device: any) {
		this.connect.releaseConnection(device);
	}

	isConnected(device: any): Boolean {
		const index = this.connect.activeDevices.findIndex((itDevice: any) => itDevice.device._id === device._id);
		return index !== -1;
	}

	onChangeFilter(event: any, type: string, value: any) {

		let ref;
		if (value && typeof(value) === 'string') {
			ref = value;
		} else {
			ref = value._id;
		}

		if (event.checked) {
			if (this.deviceFilter[type].filter.indexOf(ref) === -1) {
				this.deviceFilter[type].filter.push(ref);
			}
		} else {
			if (this.deviceFilter[type].filter.indexOf(ref) !== -1) {
				this.deviceFilter[type].filter.splice(this.deviceFilter[type].filter.indexOf(ref), 1);
			}
		}
		this.filterDevices();
	}

	onSelectDevice(event: any, device: any) {
		if (event.checked) {
			this.choosenDevices.push(device);
		} else {
			const index = this.choosenDevices.findIndex((d) => d._id === device._id);
			this.choosenDevices.splice(index, 1);
		}
	}

	getList(type: string) {
		if (this.deviceFilter[type].seeAll) {
			return this.devicesService.pressets[type];
		} else {
			return this.devicesService.pressets[type].slice(0, 5);
		}
	}

	next() {
		this.step++;
	}

	back() {
		this.step--;
	}

	sendToRun() {

		if (!this.limitControl.valid || !this.scheduleControl.valid) {
			return;
		}

		if(this.data.testSuite){
			this.testsService.runTestSuite(this.data.testSuite, this.choosenDevices, this.repetitions, this.schedule, false, false, this.notifySlack, false, this.data.path)
			.subscribe((result) => {
				if(this.openWatcher){
					this.choosenDevices.forEach(async dev => {
						await this.sleep(1000)
						this.openWatcherPopup(dev);
					});
				}
				this.dialogRef.close();
			}, (error) => {
				console.error(error);
			});
		}else{
			this.data.testSuites.forEach((suite, i) => {
				let suitePath = this.data.paths.find(p => p._id == suite._id);
				console.log(suitePath)
				this.testsService.runTestSuite(suite, this.choosenDevices, this.repetitions, this.schedule, false, false, this.notifySlack, false, suitePath.path)
				.subscribe((result) => {
					
				}, (error) => {
					console.error(error);
				});


				if(i == (this.data.testSuites.length-1)){
					if(this.openWatcher){
						this.choosenDevices.forEach(async dev => {
							await this.sleep(1000)
							this.openWatcherPopup(dev);
						});
					}
					this.dialogRef.close();
				}
			});

		}
	}

	openWatcherPopup(device: any){
		let deviceRatio = (device.model.resolution.width / 300);
		let newwindow = window.open(`${URL_SERVER}/#/watcher?device=${device._id}`,'',
									`directories=0, titlebar=0, location=0, status=0, menubar=0,history=0,resizable=0,scrollbars=0,toolbar=0, height=${((device.model.resolution.height/deviceRatio)+100)},width=320`);
		newwindow.resizeTo(320, ((device.model.resolution.height/deviceRatio)+100));
	}

 	getThumbUrl(device) {
		return device.model.urlThumb ? device.model.urlThumb : (device.model.thumb ? 'assets/images/thumbs/'+device.model.thumb : 'assets/images/iphone.svg');
	}
	showInfo(data: string) {
			const message = `Device ID: ${data}`;
		this.snackBar.open(message, null, {
		duration: 5000,
		});
	}

	sortDevices(){
		this.deviceList.sort((a:any, b:any) => {
			return a.alias < b.alias ? -1 : (a.alias > b.alias ? 1 : 0) ;
		});
	}

	checkSlack() {
		this.notifySlack = !this.notifySlack;
	}
	checkWatcher(){
		this.openWatcher = !this.openWatcher;
	}
}
