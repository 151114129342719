import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BugReportDialogService } from './bug-report-dialog.service';
import { MatDialogRef, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-bug-report-dialog',
  templateUrl: './bug-report-dialog.component.html',
  styleUrls: ['./bug-report-dialog.component.scss']
})
export class BugReportDialogComponent {
  ticketForm = this.fb.group({
    subject: ['', Validators.required],
    comment: ['', Validators.required]
  });

  public sending = false;

  constructor(
    private dialogRef: MatDialogRef<BugReportDialogComponent>,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private bugReportDialogService: BugReportDialogService
  ) {}

  send() {
    this.dialogRef.disableClose = true;
    this.sending = true;
    const subject = this.ticketForm.get('subject').value as string;
    const comment = this.ticketForm.get('comment').value as string;
    const sub = this.bugReportDialogService.createTicket(subject, comment)
      .subscribe((res) => {
        if (res.status === 200) {
          this.dialogRef.close();
          this.snackBar.open('Ticket enviado com sucesso!', 'Fechar', { duration: 2000 });
        } else {
          this.snackBar.open('Falha ao enviar ticket.', 'Fechar', { duration: 2000 });
        }
        this.dialogRef.disableClose = false;
        this.sending = false;
      });
  }
}
