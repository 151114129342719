import { Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  constructor(
		private router: Router,
		public dialogRef: MatDialogRef<DeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
	) { }

  ngOnInit() {
  }

	editTestSuite(testSuite: any) {
		this.router.navigate([`/testsuite`], {queryParams: {id: testSuite._id}});
		this.dialogRef.close();
	}

	close() {
		this.dialogRef.close();
	}

}
