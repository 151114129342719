import { Injectable, Inject } from '@angular/core';
import { localStorageToken, sessionStorageToken } from './providers';

@Injectable()
export class AppService {

	storage: any = null;
	constructor (
		@Inject(localStorageToken) private localStorage: any,
		@Inject(sessionStorageToken) private sessionStorage: any
	) {
	}

	uuid() {
		let d = new Date().getTime();
		const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			const r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r&0x3|0x8)).toString(16);
		});
		return uuid;
	}

}
