import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { TestsService, TestStep } from '../../../../tests/tests.service';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements OnInit {

	constructor(
		private testsService: TestsService,
		public dialogRef: MatDialogRef<KeyboardComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {}

	text: String;
	variable: String;
	type: String = 'static';

  ngOnInit() {
		if (this.data.step !== undefined) {
			this.type = this.data.step.subType;
			if (this.type === 'variable') {
				this.variable = this.data.step.defaultValue;
			} else {
				this.text = this.data.step.defaultValue;
			}
		}
  }

  add() {

		let result;

		if (this.type === 'variable') {
			result = {
				value: this.variable,
				type: this.type
			};
		} else {
			result = {
				value: this.text,
				type: this.type
			};
		}

		this.dialogRef.close(result);
  }

}
