import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { wsocketToken } from '../../providers';
import {URL_SERVER, ISDEBUG} from './../../providers'

@Injectable()
export class AccountsService {

	constructor (
		private http: Http,
	) {
		
	}

	listAccounts(isRoot){
		return this.http.post(`${URL_SERVER}/admin/account/list`, {isRoot});
	}

	saveAccount(account){
		return this.http.post(`${URL_SERVER}/admin/account/save`, {account});
	}

	saveTeam(team){
		return this.http.post(`${URL_SERVER}/admin/account/saveTeam`, {team});
	}

	saveCustomStyle(style){
		return this.http.post(`${URL_SERVER}/admin/account/saveCustomStyle`, {style});
	}

	deactiveTeam(teamId){
		return this.http.post(`${URL_SERVER}/admin/account/deactiveTeam`,{teamId})
	}
	
	removeMFA(acc){
		return this.http.post(`${URL_SERVER}/admin/account/removeMFA`, {accId: acc._id});
	}

	listActions(){
		return this.http.post(`${URL_SERVER}/admin/actions/list`,{})
	}

	listHistory(){
		return this.http.post(`${URL_SERVER}/admin/actions/history`,{})
	}

	createAction(action){
		return this.http.post(`${URL_SERVER}/admin/actions/create`, {command: action.command, deviceId: action.device._id});
	}
	
	deleteAction(actionId){
		return this.http.delete(`${URL_SERVER}/admin/actions/${actionId}`);
	}

	deleteAccount(accountId){
		return this.http.post(`${URL_SERVER}/admin/account/delete`, {accountId});
	}

	reactiveAccount(accountId){
		return this.http.post(`${URL_SERVER}/admin/account/reactiveAcc`, {accountId});
	}
}
