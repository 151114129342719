import { InjectionToken } from '@angular/core';

let config = {
    system: {pageSkip: 20},
    runSafe: false,
    http: {external: 'localhost', externalPort: 3000}
}
export let ISDEBUG = false;
try{
    config = require('../../../../backend/config/default.json');
}catch(e){
    ISDEBUG = true;
    console.log('Running without backend!');
}

export const wsocketToken: any = new InjectionToken('wsocketToken');
declare var io: any;
declare var jQuery: any;
declare var require: any;

export let wsocket: any;
if(config.runSafe){
    console.log('WSS')
    wsocket = io(`wss://${config.http.external}`);
}else{
    console.log('WS')
    wsocket = io(`ws://${config.http.external}`);
}

export const $Token: any = new InjectionToken('$Token');
export const $: any = jQuery;

export const windowToken: any = new InjectionToken('windowToken');

export const localStorageToken: any = new InjectionToken('localStorageToken');
export const localStorage = window.localStorage;

export const sessionStorageToken: any = new InjectionToken('sessionStorageToken');
export const sessionStorage = window.sessionStorage;

export const VERSION = require('../../package.json').version;
export const PAGINATION_LIMIT = (config.system.pageSkip || 20);
export const URL_SERVER = ISDEBUG ? `${config.http.external}:${config.http.externalPort}` : '';
