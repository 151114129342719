import { OnInit, OnDestroy, Component, ViewChild } from "@angular/core";
import { LoginService } from "../../login/login.service";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AccountsService } from "./accounts.service";
import { DevicesService } from './../../devices/devices.service';
import { EditAccountDialogComponent } from './edit-account-dialog/edit-account-dialog.component';
import { SaveDeviceDialogComponent } from './save-device-dialog/save-device-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { EditTeamDialogComponent } from './edit-team-dialog/edit-team-dialog.component';
import { UploadComponent } from './../../util/upload/upload.component';
import { ActionDialogComponent } from "./action-dialog/action-dialog.component";

@Component({
    selector: 'app-account',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})

export class AccountsComponent implements OnInit, OnDestroy {

	@ViewChild('uplogo') uploadComp: UploadComponent;
    uploadSubscr: any;
    pendingDevices: any[];
    accountSub: any;
    actSub: any;
    devicesSub: any;
    devices: any;
    accFilter:any;
    teamFilter:any;
    delSub: any;
    loggedUser: any;
    accounts: any[];
    actions: any[];
    teams: any[];
    style: any;

    constructor(
		private router: Router,
        private loginService: LoginService,
        private devicesService: DevicesService,
        private toastr: ToastrService,
        private accountService: AccountsService,
		private dialog: MatDialog,
	) {
	}

    ngOnInit(): void {
        this.style = {logo:'', background:''};
        if (this.isAdmin()) {

            this.findUsersNTeams();
            this.findDevices();
            this.findActions();

            setTimeout(() => {
                this.uploadSubscr = this.uploadComp.uploadEvent.subscribe((val: any) => {
                    this.style.logo = val.url;
                });
            }, 1000);

        } else {
            this.toastr.error('Você não é um admin!');
            this.router.navigate(['/']);
        }
    }

    findUsersNTeams(){
        this.accountSub = this.accountService.listAccounts(this.isRoot()).subscribe(async resp => {
            let data = await resp.json();
            this.accounts = data.accounts;
            this.teams = data.teams;
        });
    }

    findDevices(){
        this.devicesSub = this.devicesService.listByCompany().subscribe(async resp => {
            let data = await resp.json();
            this.devices = data;
        });
    }

    findActions(){
        this.actSub = this.accountService.listActions().subscribe(async resp => {
            let data = await resp.json();
            this.actions = data;
        });
    }

    isAdmin(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ADMIN') > -1 || this.loginService.user.roles.indexOf('ROOT') > -1)
    }

    isRoot(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ROOT') > -1);
    }

    removeMFA(acc){
        const confirmed = confirm(`Certeza que deseja remover o autenticador do usuário ${acc.username}?`);

        if(confirmed){
            acc.mfa.active = false;
            this.accountService.removeMFA(acc).subscribe(resp => {
                let data = resp.json();
                this.toastr.success('Alteração salva.');
            })
        }
    }

    saveStyle(){
        if(!this.style.background &&  !this.style.logo){
            this.toastr.info('Selecione uma cor de fundo ou uma imagem de logo!');
            return;
        }
        //salvar no team
        this.accountService.saveCustomStyle(this.style).subscribe(async resp => {
            //this.loginService.user.team.company.style = this.style;
            this.loginService.user.team.style = this.style;

            this.toastr.success('Feito! reloge no sistema.');
        }, err => {
            console.log(err.json());
        });
    }

    edit(account: any) {
        let list = this.accounts;
        let teams = this.teams;
        this.dialog.open(EditAccountDialogComponent, {
            width: '800px',
            data: {
                account,
                list,
                teams
            }
        }).afterClosed().subscribe((action) => {
            this.findUsersNTeams();
        });
    }

    editTeam(team: any) {
        this.dialog.open(EditTeamDialogComponent, {
            width: '800px',
            data: {
                team,
                accounts: this.accounts,
                devices: this.devices 
            }
        }).afterClosed().subscribe((action) => {
            this.findUsersNTeams();
        });
    }

    create() {
        let list = this.accounts;
        let teams = this.teams;
        this.dialog.open(EditAccountDialogComponent, {
            width: '800px',
            data: {
                list,
                teams
            }
        }).afterClosed().subscribe((action) => {
            this.findUsersNTeams();
        });
    }

    createTeam() {
        this.dialog.open(EditTeamDialogComponent, {
            width: '800px',
            data: {
                accounts: this.accounts,
                devices: this.devices
            }
        }).afterClosed().subscribe((action) => {
            this.findUsersNTeams();
        });
    }

    remove(account: any){
        let result = confirm(`Deseja bloquear a conta ${account.username}`);

        if(result){
            this.delSub = this.accountService.deleteAccount(account._id).subscribe(async resp => {
                //this.accounts.splice(this.accounts.indexOf(account), 1);
                account.active = false;
                this.toastr.warning('Conta bloqueada!', '', {closeButton: true});
            }, err => {
                console.log(err.json());
            });
        }
    }

    unblock(account){
        let result = confirm(`Reativar a conta '${account.username}'?`);

        if(result){
            this.delSub = this.accountService.reactiveAccount(account._id).subscribe(async resp => {
                //this.accounts.splice(this.accounts.indexOf(account), 1);
                account.active = true;
                this.toastr.success('Conta desbloqueada!');
            }, err => {
                console.log(err.json());
            });
        }
    }

    removeTeam(team: any){
        console.log(team)
        let result = confirm(`Deseja desativar o time ${team.name}?`);

        if(result){
            this.accountService.deactiveTeam(team._id).subscribe(async resp => {
                this.teams.splice(this.teams.indexOf(team), 1);
                this.toastr.warning('Time desativado!', '', {closeButton: true});
            }, err => {
                console.log(err.json());
            });
        }
    }

    createDevice(){
        this.dialog.open(SaveDeviceDialogComponent, {
            width: '800px',
            data: {}
        }).afterClosed().subscribe((action) => {
            this.findDevices();
        });
    }
    
    editDevice(device){
        this.dialog.open(SaveDeviceDialogComponent, {
            width: '800px',
            data: {
                device
            }
        }).afterClosed().subscribe((action) => {
            this.findDevices();
        });
    }

    createAction(){
        let dRef = this.dialog.open(ActionDialogComponent, {
            width: '800px',
            data: {
                devices: this.devices
            }
        });

        dRef.afterClosed().subscribe((action) => {
            this.findActions();
        });
    }

    removeAction(action){
        let result = confirm(`Remover ação ${action.command} do device ${action.device.alias}?`);

        if(result){
            this.actSub = this.accountService.deleteAction(action._id).subscribe(async resp => {
                this.toastr.warning('Ação removida!', '', {closeButton: true});
                this.findActions();
            }, err => {
                console.log(err.json());
            });
        }
    }

    ngOnDestroy(): void {
        if(this.accountSub) this.accountSub.unsubscribe();
        if(this.delSub) this.delSub.unsubscribe();
        if(this.actSub) this.actSub.unsubscribe();

    }
}
