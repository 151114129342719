import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { TestsService, TestStep } from '../../../../tests/tests.service';

@Component({
  selector: 'app-touch',
  templateUrl: './touch.component.html',
  styleUrls: ['./touch.component.scss']
})
export class TouchComponent implements OnInit {

	constructor(
		private testsService: TestsService,
		public dialogRef: MatDialogRef<TouchComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {}

		text: String;
		value: String;
		type: String = 'static';

	  ngOnInit() {
			if (this.data.step !== undefined) {
				this.type = this.data.step.defaultValue.method;
				this.value = this.data.step.defaultValue.reference;
			}
	  }

		add() {

			let result;

			result = {
				method: this.type,
				reference: this.value
			};

			this.dialogRef.close(result);
	  }

}
