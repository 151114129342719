import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVER } from './../../providers';

@Injectable()
export class ChangePasswordService {

  constructor(private http: Http) { }

  checkValidToken(token) {
    const params = {isValidToken: token};
    return this.http.get(`${URL_SERVER}/services/user/check-token`, {params: params});
  }
  changePassword(newPassword, token) {
    return this.http.post(`${URL_SERVER}/services/user/change-password`, {newPassword, token});
  }

}