import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { wsocketToken, URL_SERVER } from '../providers';

@Injectable()
export class DeviceStatusLogService {
    
  constructor (
		private http: Http,
		@Inject(wsocketToken) private wsocket: any
	) {
	}
    
	 
	getStatusLogs(skip, limit, params = null){
		return this.http.post(`${URL_SERVER}/services/device/status-log/list`, {skip, limit, params});
	}
}