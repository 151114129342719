import {  ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy, Inject, ViewChild, asNativeElements, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DevicesService } from './../../../devices/devices.service';
import { FormControl, Validators, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { UploadComponent } from '../../../util/upload/upload.component';
import { ToastrService } from 'ngx-toastr';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { SelectListDialogComponent } from '../../pending-devices/edit-device-dialog/select-list-dialog/select-list.component';

@Component({
  selector: 'save-device-dialog',
  templateUrl: './save-device-dialog.component.html',
  styleUrls: ['./save-device-dialog.component.scss']
})
export class SaveDeviceDialogComponent implements OnInit, OnDestroy {

    constructor(
		private changeDetector: ChangeDetectorRef,
		private zone: NgZone,
		private toastr: ToastrService,
		private dialog: MatDialog, 
		public deviceService: DevicesService,
		public dialogRef: MatDialogRef<SaveDeviceDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any        
	) {
	}

	deviceForm: FormGroup;
	deviceHubList = [];
	filteredVersions = [];
	farmList = [];
	editableDevice = {
		_id: null,
		alias: '',
		serial: '',
		model: {_id:null, name:''},
		deviceHub: {_id:null, name:''},
		deviceFarms: [],
		OS: {
			platform: '',
			version: ''
		}
	};

	async ngOnInit() {
		this.deviceForm = new FormGroup({
			alias: new FormControl({value: this.editableDevice.alias || ''}),
			serial: new FormControl({value: this.editableDevice.serial || ''}, Validators.required),
			deviceOS: new FormControl({value: this.editableDevice.OS.platform || ''}, Validators.required),
			OSVersion: new FormControl({value: this.editableDevice.OS.version}, Validators.required),
			model: new FormControl({value: this.editableDevice.model.name || ''}, Validators.required),
			hub: new FormControl({value: this.editableDevice.deviceHub.name || ''}, Validators.required)
		});

		await this.findHubs();
		await this.findFarms();

		if(this.data.device && this.data.device._id){
			let dev = this.deviceService.devices.find(d => d._id == this.data.device._id);
			console.log(dev)

			this.editableDevice._id = dev._id;
			this.editableDevice.alias = dev.alias;
			this.editableDevice.serial = dev.serial;
			this.editableDevice.model = dev.model;
			this.editableDevice.deviceHub = this.deviceHubList.find(d => d._id === dev.deviceHub);
			dev.deviceFarms.forEach(devFarm => {
				let foundedFarm = this.farmList.find(farm => farm._id === devFarm);
				if(foundedFarm){
					this.editableDevice.deviceFarms.push(foundedFarm);
				}
			});
		}
  }

	findHubs(){
		return new Promise(resolve => {
			this.deviceService.getDeviceHubs().subscribe(async resp => {
				this.deviceHubList = await resp.json();
				console.log(`Hubs: ${this.deviceHubList.length}`);
				resolve();
			});
		})
	}

	findFarms(){
		return new Promise(resolve => {
			this.deviceService.getDeviceFarms().subscribe(async resp => {
				this.farmList = await resp.json();
				console.log(`Farms: ${this.farmList.length}`);
				resolve();
			});
		});
	}

	saveDevice(){
		let shortDevice = {_id:null, alias: '', deviceFarms:[], deviceHub:null, model:null,serial:null, OS: {platform:'',version:''}};
		shortDevice._id = this.editableDevice._id;
		shortDevice.deviceFarms = this.editableDevice.deviceFarms.map(df => df._id);
		shortDevice.deviceHub = this.editableDevice.deviceHub._id;
		shortDevice.model = this.editableDevice.model._id;
		shortDevice.serial = this.editableDevice.serial;
		shortDevice.alias = this.editableDevice.alias;
		shortDevice.OS.platform = this.editableDevice.OS.platform;
		shortDevice.OS.version = this.editableDevice.OS.version;

		console.log(shortDevice);

		this.deviceService.saveDeviceByAdmin(shortDevice).subscribe(async resp => {
			let data = await resp.json();

			console.log(data);
			if(data.status == 'success'){
				this.dialogRef.close();
			}
		});
	}

	list(list, type){
		this.dialog.open(SelectListDialogComponent, {
			width: '300px',
			height: '400px',
      data: {
				list,
				type,
				device: this.editableDevice
      }
    })
	}

	showFarm(farm){
		return this.editableDevice.deviceFarms.map(d => d._id).includes(farm._id) ? false : true;		
	}

	addFarm(farm){
		this.editableDevice.deviceFarms.push(farm);
	}

	removeFarm(idx){
		this.editableDevice.deviceFarms.splice(idx,1);
	}

	ngOnDestroy() {
		this.changeDetector.detach();
	}
}
