import { Injectable, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVER } from '../providers';

@Injectable()
export class AppsService {

	constructor (
 	 private http: Http,
  ) {
  }

  listApps() {
 	 return this.http.get(`${URL_SERVER}/services/resource/app/list`);
  }
  
  renameApp(app){
    return this.http.post(`${URL_SERVER}/services/resource/app/rename`, {appId: app._id, name: app.name});
  }

	delete(appId: string) {
 	 return this.http.delete(`${URL_SERVER}/services/resource/app/delete/${appId}`);
  }

}
