import { Component, ChangeDetectorRef, Input, NgZone, OnInit, OnDestroy, Inject } from '@angular/core';
import { AccountsService } from '../accounts/accounts.service';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
    selector: 'app-action-history',
    templateUrl: './action-history.component.html',
    styleUrls: ['./action-history.component.scss']
})

export class ActionHistoryComponent implements OnInit {
    actions: [];
    
    constructor(
        private router: Router,
        private accountService: AccountsService,
        private loginService: LoginService,
        private toastr: ToastrService,
	) {

    }
    
    ngOnInit() {
        if (this.isAdmin()) {
            this.findActions();

        } else {
            this.toastr.error('Você não é um admin!');
            this.router.navigate(['/']);
        }
    }
    
    findActions(){
        this.accountService.listHistory().subscribe(async resp => {
            let data = await resp.json();

            console.log(data)
            this.actions = data;
        });
    }

    isAdmin(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ADMIN') > -1 || this.loginService.user.roles.indexOf('ROOT') > -1)
    }

    showError(actionId){
        
    }
}