import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { localStorageToken, sessionStorageToken } from '../../providers';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-customlogin',
  templateUrl: './customLogin.component.html',
  styleUrls: ['./customLogin.component.scss']
})
export class CustomLoginComponent implements OnInit {

	loginForm: FormGroup;
	forgotPasswordForm: FormGroup;
	errorMessage: String = '';
	responseMessage: String = '';
	responseMessageLogin = '';
	showForgetPasswordForm = false;
	showToken = false;
	customData;

  	constructor(
 		private loginService: LoginService,
 		private router: Router,
	 	private route: ActivatedRoute,
		private toastr: ToastrService,
		private appService: AppService,
 		@Inject(localStorageToken) private localStorage: any,
 		@Inject(sessionStorageToken) private sessionStorage: any,
  	) { }

  	ngOnInit() {
		const customPath = this.route.snapshot.paramMap.get('customPath');

		this.loginService.checkCustomPath(customPath).subscribe(async resp => {
			this.customData = await resp.json();

			console.log(this.customData)
		}, async error => {
			let err = await error.json();
			console.log(err)
			this.toastr.warning(err.message);
			this.router.navigate(['/login']);
		})

		this.loginForm = new FormGroup({
			username : new FormControl('', Validators.required),
			password : new FormControl('', Validators.required),
		});
	}

  	doLogin() {
		this.errorMessage = '';
	 	this.loginService.customLogin(this.loginForm.value.username, this.loginForm.value.password, this.customData.customAPI)
		.subscribe(
 			(result: any) => {
				let resp = result.json();

				this.loginService.isLoggedIn = true;
				this.appService.storage = localStorage;
				
				localStorage.removeItem('Auth-Token');
				sessionStorage.removeItem('Auth-Token');
				this.appService.storage.setItem('Auth-Token', result.json().token);
				
				location.reload();
 			},
 			(error: any) => {
				 this.errorMessage = error.json().error;
				 this.toastr.warning(error.json().error);  
 			},
 		);
  	}

}
