import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../login/login.service';
import { ReportsService } from '../../../report-tree/reports/reports.service';
import { DashboardService } from '../../dashboard.service';
@Component({
    selector: 'app-custom-widget',
    templateUrl: './custom-widget.component.html',
    styleUrls: ['./custom-widget.component.scss']
})
export class CustomWidgetComponent implements OnInit {
    constructor(
        public dashboardService: DashboardService, 
        public loginService: LoginService, 
        public reportService: ReportsService
    ) { }
    customTitle: any;
    customMessage: any;
    customWidget: any;
    ngOnInit() {
        this.customWidget = this.loginService.user.team.widgets.filter(wgt => wgt.title == 'custom')[0];
        if(!this.customWidget.data){
            this.customWidget.data = {msg: '', title: ''}
        }
        
        this.customMessage = this.customWidget.data.msg;
        this.customTitle = this.customWidget.data.title;
    }

    editTitle(){
        let title = prompt('Digite o título do widget:',this.customTitle);

        if(title){
            this.customWidget.data.title = this.customTitle = title;

            console.log(this.customWidget)
            this.dashboardService.updateWidgets(this.customWidget)
            .subscribe(async resp => console.log(await resp.json()));
        }
    }
    
    editMsg(){
        let msg = prompt('Digite a mensagem do widget:',this.customMessage);
        
        if(msg){
            this.customWidget.data.msg = this.customMessage = msg;
            
            console.log(this.customWidget)
            this.dashboardService.updateWidgets(this.customWidget)
            .subscribe(async resp => console.log(await resp.json()));
        }
    }
}
