import { Component, OnInit } from '@angular/core';
import { ResumeCompanyService } from '../../../admin/company/resume.service';
import { LoginService } from '../../../login/login.service';
import { ReportsService } from '../../../report-tree/reports/reports.service';
@Component({
    selector: 'app-total-time-usage',
    templateUrl: './total-time.component.html',
    styleUrls: ['./total-time.component.scss']
})
export class TotalTimeUsageComponent implements OnInit {
    constructor(
        public loginService: LoginService, 
        public reportService: ReportsService,
        private ResumeCompService: ResumeCompanyService,
    ) { }
    company: any;
    totalTime: any;
    ngOnInit() {
        this.ResumeCompService.getCompanyResume(this.loginService.user.team.company._id)
        .subscribe(async (resp: any) => {
            this.company = await resp.json();
            console.log(this.company)

            this.totalTime = this.calcDuration(this.company.plan.usedTime);
        });

        
        // this.reportService.getTotalTimeByTeam().subscribe(async (result) => {
        //     let resp = await result.json();
        //     this.totalTime = resp;
        // });
    }

    calcDuration(seconds){
        if(seconds == 0) {return 0}
        let getPlural = (number, word)  => {
            return number === 1 && word.one || word.other;
        }
    
        let words = {
                minute: { one: 'minuto', other: 'minutos' },
                second: { one: 'segundo', other: 'segundos' }
            },
            units = ['minute', 'second'],
            values = [60, 1],
            result = [];
    
        units.reduce(function (s, u, i) {
            var temp = Math.floor(s / values[i]);
            if (temp) {
                result.push(temp + ' ' + getPlural(temp, words[u]));
            }
            return s % values[i];
        }, seconds);
        return result.join(' ');
    }
}
