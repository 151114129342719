import { Component, OnInit, Input, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { TestCase, TestSuite, TestFolder } from '../../../tests/tests.service';
import { TestComponent } from '../test.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { TestStep, TestsService } from '../../../tests/tests.service';
import { ConnectService } from '../../connect.service';

@Component({
  selector: 'app-testcase-bottom',
  templateUrl: './bottom.component.html',
  styleUrls: ['./bottom.component.scss']
})
export class BottomComponent implements OnInit {

	@Input() testComp: TestComponent;
	@ViewChild('actionDragArea') actionDragArea: ElementRef;
	@ViewChild('actionListContainer') actionListContainer: ElementRef;

	draggable: boolean;
	showArrowLeft: boolean;
	showArrowRight: boolean;
	baseX: number;

  constructor(
		public testsService: TestsService, 
		private cd: ChangeDetectorRef,
		private connectService: ConnectService
	) {}

	removeStep() {

		if (this.testsService.testComponent.activeStepIndex >= 0) {
			if (this.testComp.activeFolder.length > 0) {
				const activeFolder: TestFolder = this.testComp.getActiveFolder();
				activeFolder.subSteps.splice(this.testsService.testComponent.activeStepIndex, 1);
			} else {
				this.testsService.testComponent.activeTestCase.steps.splice(this.testsService.testComponent.activeStepIndex, 1);
			}
		}

		this.testsService.testComponent.activeStepIndex = -1;
		this.testsService.updateSteps();
	}

  prevStep() {
    if (this.testsService.testComponent.activeStepIndex > 0) {
      this.testsService.testComponent.activeStepIndex--;
    }
  }

  nextStep() {
    if (this.testsService.testComponent.activeStepIndex === -1) {
      this.testsService.testComponent.activeStepIndex = 0;
    } else if (this.testsService.testComponent.activeStepIndex < this.testsService.testComponent.activeTestCase.steps.length - 1) {
      this.testsService.testComponent.activeStepIndex++;
    }
	}
	
	onFocus(){
		this.connectService.activeEnhancedKeyboard = false;
	}

  onBlur() {
    this.cd.detectChanges();
		this.testsService.updateSteps();
  }

	onDrag(event: any) {
		this.testsService.testComponent.activeStepIndex = -1;
		this.cd.detectChanges();
		this.testsService.updateSteps();
	}

	isEditable() {
		const step = this.testsService.testComponent.activeTestCase.steps[this.testsService.testComponent.activeStepIndex];

		if (step && step.type === 'pressKey') {
			return false;
		}

		return true;
	}

	editStep() {

		this.testsService.testComponent.isEditing = true;

		const step = this.testsService.testComponent.activeTestCase.steps[this.testsService.testComponent.activeStepIndex];

		switch (step.type) {
			case 'delay':
			this.testsService.testComponent.addDelay();
			break;
			case 'script':
			this.testsService.testComponent.addScript();
			break;
			case 'touchAction':
				if (step.subType === 'element') {
					this.testsService.testComponent.addTouchElement();
				}
			break;
			case 'validate':
				this.testsService.testComponent.addValidation();
			break;
			case 'keyboard':
			//TODO fix this
			this.testsService.testComponent.addKeyboard('', '');
			break;
		}
	}

	mouseUpEvent(e) {
		this.draggable = false;
	}

	mouseDownEvent(e) {
		this.draggable = true;
		this.baseX = e.screenX;
	}

	mouseMoveEvent(e) {
		/*if (this.draggable) {
			this.actionDragArea.nativeElement.scrollLeft += this.baseX - e.screenX;
			this.baseX = e.screenX;
		}*/
	}


	openFolder() {

		const activeFolder: TestStep = this.testComp.getActiveFolder();

		this.testComp.activeFolder.push(this.testsService.testComponent.activeStepIndex);
		this.testsService.testComponent.activeStepIndex = -1;
	}

	closeFolder() {
		this.testComp.activeFolder.splice(-1, 1);
		this.testsService.testComponent.activeStepIndex = -1;
	}

	getName() {

		let name = this.testComp.activeTestCase.name;
		let step;

		for (let i = 0; i < this.testComp.activeFolder.length; i++) {

			if (!step) {
				step = this.testsService.testComponent.activeTestCase.steps[this.testComp.activeFolder[i]];
			} else {
				step = step.subSteps[this.testComp.activeFolder[i]];
			}

			name += ` > ${step.alias}`;
		}

		return name;
	}

  ngOnInit() {

		console.log(this.testComp.activeTestCase.steps);
  }

}
