import { Injectable } from '@angular/core';
import { Http, ResponseContentType } from '@angular/http';
import { URL_SERVER } from './../../../providers'
import { map } from 'rxjs/operators';

@Injectable()
export class ReportUtilService {

  pressets: any;

  constructor (private http: Http) {}

	labelFor(el, withAlias: boolean = false) {

		const sessionId = el.value.sessionId;
		const path: string = el.value.url.substr(el.value.url.indexOf(sessionId) + sessionId.length);

		let desc;

		if (path.endsWith('/elements') || path.endsWith('/element')) {
			desc = 'Find';

			if (el.value.content.using) {
				desc += ` by ${el.value.content.using}`;
			}
		} else if (new RegExp('^/element.*/attribute/value$').test(path)) {
			if (el.value.method === 'GET') {
				desc = `Get value`;
			}
    } else if (new RegExp('^/element.*/text$').test(path)) {
      if (el.value.method === 'GET') {
        desc = `Get text`;
      }
		} else if (new RegExp('^/element.*/value$').test(path)) {
			if (el.value.method === 'POST') {
				desc = `Set text`;
			}
		} else if (new RegExp('^/element.*/click$').test(path)) {
			if (el.value.method === 'POST') {
				desc = `Click`;
			}
		} else if (path.endsWith('/source')) {
			desc = 'Source';
		} else if (path.endsWith('/back')) {
      desc = 'Back';
    }else if (path.endsWith('/timeouts')) {
			desc = 'Timeout';
		}else if (path.endsWith('/displayed')) {
			desc = 'Is Displayed';
		}else if (path.endsWith('/press_keycode')) {
			desc = 'Keycode';
    } else if (path.endsWith('/touch/perform')) {
      desc = 'Touch';
		} else if (path.endsWith('/keys')) {
			desc = 'Keys';
		} else if (path.endsWith('/app/reset')) {
			desc = 'App Reset';
		} else if (path.endsWith('/app/close')) {
			desc = 'App Close';
		} else if (path.endsWith('/app/launch')) {
			desc = 'App Launch';
		}else if (path.endsWith('/toggle_location_services')) {
 			desc = 'Toggle Location Service';
		}else if (path.endsWith('/execute')) {
			desc = 'Mobile Execute';
		}else {
			desc = 'Session';
		}

    if (el.alias) {
      desc = `${el.alias} (${desc})`;
    }

		return desc;
	}

	getLog(report) {
		return this.http.get(report.logResource.url);
  }

  download(id: string) {
    const params = {reportId: id};

    return this.http.get(`${URL_SERVER}/services/report/download`, {params, responseType: ResponseContentType.Blob})
	.pipe(map(res => new Blob([res.blob()], {type: 'application/pdf'})))
  }

}
