import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-mfa-dialog',
  templateUrl: `mfa-dialog.component.html`,
  styleUrls: [ `mfa-dialog.component.scss` ]
})
export class MFADialogComponent {
  
  mfaForm!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<MFADialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private loginService: LoginService,
  ){}
  
  async ngOnInit(){
    this.mfaForm = this.formBuilder.group({
      token: ['',[Validators.required]]
    });
  }

  validarToken(){
    this.loginService.checkValidToken({
      token: this.mfaForm.value.token,
      secret: this.data ? this.data.secret : ''
    })
    .subscribe(async (data:any) => {
      let resp = await data.json();

      this.toastr.success(resp.message);
      this.dialogRef.close(true);
    }, e => {
      let err = e.json();
      this.toastr.error(err.message)
    })
  }
}
