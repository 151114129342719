import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { TestsService, TestStep } from '../../../../tests/tests.service';
import { FormGroup, FormControl, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
export class ValidateComponent implements OnInit {

	constructor(
		private testsService: TestsService,
		public dialogRef: MatDialogRef<ValidateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {}

	validationForm: FormGroup;

	props: any[];

	valitationType: string;

	propsComparators = [
		{
			label: 'Equals',
			value: 'eq',
		},
		{
			label: 'Not Equals',
			value: 'neq',
		},
		{
			label: 'Constains',
			value: 'contains',
		},
		{
			label: 'Greater Than',
			value: 'gt',
		},
		{
			label: 'Greater Than Equals',
			value: 'gte',
		},
		{
			label: 'Less Than',
			value: 'lt',
		},
		{
			label: 'Less Than Equals',
			value: 'lte',
		},
	];

	value: {
		element: {
			method: string, // by
			reference: string, // id or xpath
		},
		validation: {
			type: string, // property or lookup
			property: string,
			comparator: string, // propert: ==, !=, gt, lt, gt3, lte; lookup: exists not-exists
			data: {
				type: string, // static variables
				value: string,
			},
			exceptionMessage: string,
		}
	};

	setValidationType() {

			this.validationForm =  new FormGroup({
				element:  new FormGroup({
					method: new FormControl(this.value.element.method, Validators.required),
					reference: new FormControl(this.value.element.reference, Validators.required),
				}),
				validation: new FormGroup({
					type: new FormControl(this.value.validation.type, Validators.required),
					property: new FormControl(this.value.validation.property, Validators.required), //
					comparator: new FormControl(this.value.validation.comparator, Validators.required),
					data: new FormGroup({
						type: new FormControl(this.value.validation.data.type, Validators.required),
						value: new FormControl(this.value.validation.data.value, Validators.required),
					}),
					exceptionMessage: new FormControl(this.value.validation.exceptionMessage, Validators.required),
				}),
			});

			this.validationForm.get('validation').get('type').valueChanges.subscribe(val => {
		    this.validationForm.get('validation').get('data').get('value').setValue('');
		  });
	}


  ngOnInit() {

		if (this.data.props) {
			this.props = Object.keys(this.data.props);
			this.props = this.props.filter((el) => {
				if (el === 'package' || el === 'activity') {
					return false;
				}
				return true;
			});
		}

		if (this.data.step !== undefined) {
			this.value = this.data.step.defaultValue;
			console.log(this.value);
		} else {
			this.value = {
				element: this.data.element,
				validation: {
					type: 'property',
					property: undefined,
					comparator: 'eq',
					data: {
						type: 'static',
						value: '',
					},
					exceptionMessage: '',
				},
			};
		}

		this.setValidationType();
  }

	add() {

		if (
			this.validationForm.get('element').valid &&
			(this.validationForm.get('validation').get('type').value === 'existence' || this.validationForm.get('validation').valid) &&
			this.validationForm.get('validation').get('data').valid
		) {
				this.dialogRef.close(this.validationForm.getRawValue());
		}
	}

}
