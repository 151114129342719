import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { $Token } from '../../providers';
import { StorageConsuptionComponent } from './storage-consuption/storage-consuption.component';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent implements OnInit {

    constructor(@Inject($Token) private $: any) { }

    ngOnInit() {
		
		}
}
