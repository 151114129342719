import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { wsocketToken, URL_SERVER } from '../providers';

@Injectable()
export class DeviceAccessLogService {
    
  constructor (
		private http: Http,
		@Inject(wsocketToken) private wsocket: any
	) {
	}
    
	 
	getAccessLogs(skip, limit, params = null){
		return this.http.post(`${URL_SERVER}/services/device/access-log/list`, {skip, limit, params});
	}

	getLogError(logId){
		return this.http.post(`${URL_SERVER}/services/device/access-log/getError`, {logId});
	}

	getReport(sessionId){
		return this.http.post(`${URL_SERVER}/services/device/access-log/report`, {sessionId});
	}
}