import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVER } from './../providers';

@Injectable()
export class SuperAdminService {

    constructor(private http: Http) {}

    listCompanies(){
        return this.http.get(`${URL_SERVER}/superadmin/company/list`);
    }

    listDevices(){
        return this.http.get(`${URL_SERVER}/superadmin/devices/list`);
    }

    listServicePackages(){
        return this.http.get(`${URL_SERVER}/superadmin/company/findServicePackages`);
    }

    listDeviceFarms(){
        return this.http.get(`${URL_SERVER}/superadmin/company/findDeviceFarms`);
    }

    listDeviceModels(){
        return this.http.get(`${URL_SERVER}/superadmin/devices/listDeviceModels`)
    }
    
    listDeviceHubs(){
        return this.http.get(`${URL_SERVER}/superadmin/devices/listDeviceHubs`)
    }

    createFarm(farm){
        return this.http.post(`${URL_SERVER}/superadmin/company/createDeviceFarm`, {farm});
    }

    savePackage(servicePackage){
        return this.http.post(`${URL_SERVER}/superadmin/company/savePackage`, {servicePackage});
    }

    saveCompany(company){
        return this.http.post(`${URL_SERVER}/superadmin/company/saveCompany`, {company});
    }

    deactiveCompany(id){
        return this.http.post(`${URL_SERVER}/superadmin/company/deactiveCompany`, {companyId: id});
    }

    saveDevice(device){
        return this.http.post(`${URL_SERVER}/superadmin/devices/saveDevice`, {device});
    }

    saveAccount(acc){
		return this.http.post(`${URL_SERVER}/admin/account/save`, {account: acc});
    }

    saveDeviceModel(model){
        return this.http.post(`${URL_SERVER}/superadmin/devices/saveDeviceModel`, {model});
    }

    listAccounts(){
        return this.http.get(`${URL_SERVER}/superadmin/account/list`);
    }

    listTeams(){
        return this.http.get(`${URL_SERVER}/superadmin/account/teams/list`);
    }

    listManufacturers(){
        return this.http.get(`${URL_SERVER}/superadmin/devices/listManufacturer`)
    }
    
    updateHubCache(hubId){
        return this.http.post(`${URL_SERVER}/superadmin/devices/updateHub`, {hubId});
    }

    resetMinutes(compId){
        return this.http.post(`${URL_SERVER}/superadmin/company/resetMinutes`, {compId});
    }
}
