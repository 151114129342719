//stage
import { Component, Input, Inject, ChangeDetectorRef, ElementRef, ViewChild, OnInit, OnDestroy, HostListener } from '@angular/core';
import { InspectorComponent } from '../tooltab/inspector/inspector.component';
import { AppsComponent } from '../tooltab/apps/apps.component';
import { Subject } from 'rxjs'; //possivel troca pra 'rxjs'
import { ConnectService } from '../connect.service';
import { AppService } from '../../app.service';
import { windowToken } from '../../providers';
import { TestsService, TestStep } from '../../tests/tests.service';
import { AndroidKeyCode } from './../androidKeycode.service';
import { ToastrService } from 'ngx-toastr';
//TODO -> setar tamanho do screen e reajustar quando chegar tela

@Component({
	selector: 'app-stage',
	templateUrl: './stage.component.html',
	styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit, OnDestroy {
	
	@Input() deviceObject: any;
	
	@ViewChild('canvas') canvas: ElementRef;
	@ViewChild('touch1') touch1: ElementRef;
	@ViewChild('touch2') touch2: ElementRef;
	@ViewChild('inspector') inspector: InspectorComponent;
	@ViewChild('apps') apps: AppsComponent;
	
	messages = [
		'Estamos carregando o ambiente.',
		'Só mais alguns instantes.',
		'Caso o APPIUM não carregue tente reconectar no aparelho.',
		'A Subida do APPIUM nos aparelhos iOS pode levar um tempo...',
		'Quase lá..',
	];
	loadMsg: String = this.messages[0];
	width: any = 0;
	height: any = 0;
	stageResizeSubscription: Subject<any>;
	scale: Number = 1;
	keys: any[];
	pressedKeys: any[];
	buffer: string[];
	keysInterval: any;
	
	isPressed: Boolean = false;
	
	mouseOrigin = {
		x: 0,
		y: 0
	};
	
	pinch: Boolean = false;
	
	showInspector: boolean;
	showApps: boolean;
	showLogs: boolean;
	
	started: boolean;
	
	screenSubscr: any;
	screenWidth: any
	logSubscr: any;
	
	clickObj = {
		origin: { x: 0, y: 0 },
		origin2: { x: 0, y: 0 },
		dest: { x: 0, y: 0 },
		dest2: { x: 0, y: 0 },
		path: [],
		duration: 0,
		reverse: false
	};
	
	keyTimer: any;
	
	@HostListener('document:keydown', ['$event'])
	public handleKeyboardDownEvent(event: KeyboardEvent): void {
		
		if (event.key === 'Alt') {
			this.pinch = true;
		}
		
		if (event.key == ' ' || event.key == 'Enter' || event.key == 'Tab') {
			event.preventDefault();
		}
		
		if(event.key == 'Shift' || event.key == 'Alt' || event.key == 'Control'){
			if (this.pressedKeys.indexOf(event.key.toString()) == -1){
				this.pressedKeys.push(event.key.toString())
			}
		}
	}
	
	@HostListener('document:keyup', ['$event'])
	public async handleKeyboardUpEvent(event: KeyboardEvent) {
		let ref = this;
		
		if ((ref.connectService.activeDevice.device._id == ref.deviceObject.device._id) || ref.deviceObject.replicate) {
			
			if(ref.keyTimer){
				clearTimeout(ref.keyTimer);
			}
			
			if(event.key == 'Shift' || event.key == 'Alt' || event.key == 'Control' || event.key == 'Dead' || event.key == 'CapsLock' || event.key == 'Tab'){
				console.log(`Ignorar evento ${event.key}`);
			} else {
				if(event.key.toString().toLowerCase() == 'v' && (ref.pressedKeys.indexOf('Control') > -1)) {
					console.log('Colar clipboard')
					let clipText = prompt('Cole o texto aqui:')
					
					ref.buffer.push(clipText)
				} else if(event.key == 'Backspace') {
                    // ref.buffer.push('\uE003') // esse é do btn delete
                    ref.buffer.push('\u0008') // esse sim eh do backspace
                } else if(event.key == 'Enter') {
                    ref.buffer.push('\n')
                } else {
					ref.buffer.push(event.key)
				}
			} 
			
			if(event.key == 'Shift' || event.key == 'Alt' || event.key == 'Control'){
				ref.pressedKeys.splice(ref.pressedKeys.indexOf(event.key.toString()), 1)
			}
			
			ref.keyTimer = setTimeout(() => {
				ref.enhancedText(ref.buffer);
				ref.buffer = [];
			}, 800)
		}
	}
	
	constructor(
		private connectService: ConnectService,
		private changeDetector: ChangeDetectorRef,
		private appService: AppService,
		private androidService: AndroidKeyCode,
		private testsService: TestsService,
		private toastr: ToastrService,
		@Inject(windowToken) private window: any
	) {}
		
	enhancedText(message) {
		return new Promise(async (resolve, reject) => {
			const actionRequestId = this.appService.uuid();
			const ref = this;
			
			this.connectService.sendAction(this.deviceObject.device._id, actionRequestId, 'live', 
			{
				action: 'enhancedKeyboard',
				reference: message,
			})
			.then((result: any) => {
				console.log(result.data);
			});
		});
	}
	
	getMouseOffset(event: any) {
		let canva = this.canvas.nativeElement;
		const deviceModel = this.deviceObject.device.model;
		const isLandscape = canva.offsetWidth > canva.offsetHeight;
		const offset = { x: 0, y: 0, };
		
		const clickInPercent = {
			x: (event.offsetX / canva.offsetWidth),
			y: (event.offsetY / canva.offsetHeight)
		}
		
		if(this.deviceObject.device.OS.platform.toLowerCase() === 'ios'){
			if(isLandscape){
				offset.x = (deviceModel.logicalResolution.height * clickInPercent.x);
				offset.y = (deviceModel.logicalResolution.width * clickInPercent.y);
			} else {
				offset.x = (deviceModel.logicalResolution.width * clickInPercent.x);
				offset.y = (deviceModel.logicalResolution.height * clickInPercent.y);
			}
		}else{
			if(isLandscape){
				offset.x = (deviceModel.resolution.height * clickInPercent.x);
				offset.y = (deviceModel.resolution.width * clickInPercent.y);
			} else {
				offset.x = (deviceModel.resolution.width * clickInPercent.x);
				offset.y = (deviceModel.resolution.height * clickInPercent.y);
			}
		}
		console.log(`Click na pos [X]:${offset.x} [Y]:${offset.y}`);
		
		return offset;
	}
	
	onCanvasMouseDown(event: any, replicated: Boolean = false) {
		
		event.preventDefault();
		
		this.isPressed = true;
		
		this.clickObj = {
			origin: { x: 0, y: 0 },
			origin2: { x: 0, y: 0 },
			dest: { x: 0, y: 0 },
			dest2: { x: 0, y: 0 },
			path: [],
			duration: 0,
			reverse: false,
		};
		
		this.clickObj.origin = this.getMouseOffset(event);
		this.clickObj.duration = new Date().getTime();
		
		this.mouseOrigin = {
			x: event.offsetX,
			y: event.offsetY
		};
		
		this.touch1.nativeElement.style.display = 'block';
		this.touch1.nativeElement.style.top = `${this.mouseOrigin.y - 15}px`;
		this.touch1.nativeElement.style.left = `${this.mouseOrigin.x}px`;
		
		if (event.altKey /* Valida de o AltKey esta pressionado */) {
			this.touch2.nativeElement.style.display = 'block';
			this.touch2.nativeElement.style.top = this.touch1.nativeElement.style.top;
			this.touch2.nativeElement.style.left = this.touch1.nativeElement.style.left;
			
			this.clickObj.origin2 = this.getMouseOffset(event);
			
			this.clickObj.path.push({
				finger1: this.getMouseOffset(event),
				finger2: this.getMouseOffset(event),
				duration: new Date().getTime()
			});
		} else {
			this.touch2.nativeElement.style.display = 'none';
		}
		
		//this.clickObj.origin = this.getMouseOffset(event);
		
		if (this.showInspector) {
			this.inspector.bestMatch = undefined;
			this.inspector.selectElementByPosition(this.clickObj.origin);
		} else if (this.deviceObject.appRunning) {
			if (!replicated && this.deviceObject.replicate) {
				this.replicate('onCanvasMouseDown', event)
			}
		}
	}
	
	renderScreen(deviceObject: any) {
		if (this.deviceObject.lastScreenTime == undefined) {
			this.deviceObject.lastScreenTime = new Date().getTime();
		} else {
			//Screen second to render (in milliseconds)
			let now = new Date().getTime();
			this.deviceObject.ssr = (now - this.deviceObject.lastScreenTime);
			this.deviceObject.ssrColor = this.deviceObject.ssr < 1000 ? '#44a461' : (this.deviceObject.ssr < 2000 ? '#ff9019' : '#ee2c43');
			// this.deviceObject.lastScreenTime = now;
		}
		
		if(!this.deviceObject.iOSorientation){
			this.deviceObject.iOSorientation = 'portrait';
		}
		
		if (!this.deviceObject.appRunning) {
			
			if (!this.started) {
				this.apps.run({
					noReset: true,
					fullReset: false
				});
				this.started = true;
			}
		}
		
		this.canvas.nativeElement.classList.remove('empty');
		
		const g = this.canvas.nativeElement.getContext('2d');
		let blob = new Blob([deviceObject.screen], { type: 'image/jpeg' });
		const URL = window.URL || window['webkitURL'];
		let img = new Image();
		let u = URL.createObjectURL(blob);
		const ref = this;
		
		let hRatio;
		
		if (deviceObject.screenResolution) {
			hRatio = (window.innerHeight - 100) / deviceObject.screenResolution.height;
		}
		
		img.onload = function () {
			// O Player iOS tem duas formas de enviar a img, se for com minicap ela vem quadrada com barras pretas na lateral
			if(img.width == img.height && ref.deviceObject.device.OS.platform.toLowerCase() == 'ios' && ref.deviceObject.iOSorientation == 'portrait'){
				let rt = ref.deviceObject.device.model.logicalResolution.height / ref.deviceObject.device.model.logicalResolution.width;
				let canvas = ref.canvas.nativeElement;
				canvas.setAttribute('width', Math.round( img.height / rt ));
				canvas.setAttribute('height', img.height);
				ref.screenWidth = (canvas.offsetWidth);
				ref.deviceObject.width = (canvas.offsetWidth);
				
				//agr agt calcula pra n printar a barra preta
				let calcW = ((img.height - Math.round(img.height / rt)) / 2) * -1;
				g.drawImage(img, calcW, 0);
			} else if(img.width == img.height && ref.deviceObject.device.OS.platform.toLowerCase() == 'ios' && ref.deviceObject.iOSorientation == 'landscape') {
				let rt = ref.deviceObject.device.model.logicalResolution.height / ref.deviceObject.device.model.logicalResolution.width;
				let canvas = ref.canvas.nativeElement;
				canvas.setAttribute('width', img.height);
				canvas.setAttribute('height', Math.round( img.height / rt ));
				ref.screenWidth = (canvas.offsetWidth);
				ref.deviceObject.width = (canvas.offsetWidth);
				
				let calcW = ((img.height - Math.round(img.height / rt)) / 2) * -1;
				g.drawImage(img, 0, calcW);
			} else {
				ref.canvas.nativeElement.setAttribute('width', img.width);
				ref.canvas.nativeElement.setAttribute('height', img.height);
				ref.screenWidth = (ref.canvas.nativeElement.offsetWidth);
				ref.deviceObject.width = (ref.canvas.nativeElement.offsetWidth);
				
				g.drawImage(img, 0, 0);
			}
			
			img.onload = null;
			img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
			img = null;
			URL.revokeObjectURL(u);
			u = null;
			blob = null;
			
			let now = new Date().getTime();
			ref.deviceObject.lastScreenTime = now;
			
			setTimeout((d: any, ratio: any) => {
				// console.log(d.screenResolution);
				ref.connectService.requestScreen(d.device._id, { ratio: ratio, quality: 40 });
			}, 15, deviceObject, hRatio);
		};
		img.src = u;
	}
	
	replicate(actionFunction: any, event: any) {
		return new Promise((resolve, reject) => {
			this.connectService.activeDevices.forEach((deviceConnection) => {
				if (deviceConnection.device._id != this.deviceObject.device._id && deviceConnection.replicate) {
					deviceConnection.stage[actionFunction](event, true);
				}
			});
		})
	}
	
	getReverseOffset(event: any) {
		const angle = Math.atan2(event.offsetY - this.mouseOrigin.y, event.offsetX - this.mouseOrigin.x) * 180 / Math.PI;
		const reverseAngle = angle + 180;
		
		const distx = this.mouseOrigin.x - event.offsetX;
		const disty = this.mouseOrigin.y - event.offsetY;
		
		const dist = Math.sqrt(distx * distx + disty * disty);
		
		return {
			offsetY: this.mouseOrigin.y + (Math.sin(reverseAngle * Math.PI / 180) * dist),
			offsetX: this.mouseOrigin.x + (Math.cos(reverseAngle * Math.PI / 180) * dist)
		};
	}
	
	onCanvasMouseMove(event: any, replicated: Boolean = false) {
		//console.log(event.offsetX);
		
		if (!this.isPressed) {
			return;
		}
		
		this.touch1.nativeElement.style.top = `${event.offsetY - 15}px`;
		this.touch1.nativeElement.style.left = `${event.offsetX}px`;
		
		if (event.altKey /* Valida de o AltKey esta pressionado */) {
			
			// if (this.clickObj.path[this.clickObj.path.length - 1].duration + 2 < new Date().getTime() ) {
			const resverseOffset = this.getReverseOffset(event);
			this.touch2.nativeElement.style.top = `${resverseOffset.offsetY}px`;
			this.touch2.nativeElement.style.left = `${resverseOffset.offsetX}px`;
			
			this.clickObj.path.push({
				finger1: this.getMouseOffset(event),
				finger2: this.getMouseOffset(this.getReverseOffset(event)),
				duration: new Date().getTime()
			});
			// }
		}
		
	}
	
	onCanvasMouseUp(event: any, replicated: Boolean = false) {
		//activeEnhancedKeyboard on click!
		this.connectService.activeEnhancedKeyboard = true;
		
		event.preventDefault();
		
		this.isPressed = false;
		
		this.clickObj.dest = this.getMouseOffset(event);
		
		if (event.altKey /* Valida de o AltKey esta pressionado */) {
			this.clickObj.dest2 = this.getMouseOffset(this.getReverseOffset(event));
			
			this.clickObj.path.push({
				finger1: this.getMouseOffset(event),
				finger2: this.getMouseOffset(this.getReverseOffset(event)),
				duration: new Date().getTime()
			});
			
			this.clickObj.path.map((el, index) => {
				if (index === 0) {
					el.duration = 0;
				} else {
					el.duration = el.duration - this.clickObj.duration;
				}
				return el;
			});
			
			if (event.which === 3) {
				this.clickObj.path = this.clickObj.path.reverse();
				this.clickObj.reverse = true;
			} else {
				this.clickObj.reverse = false;
			}
			
			this.actionPinch(this.clickObj);
			return;
		}
		
		this.clickObj.duration = new Date().getTime() - this.clickObj.duration;
		const actionRequestId = this.appService.uuid();
		
		if (this.showInspector) {
			this.inspector.bestMatch = undefined;
			this.inspector.selectElementByPosition(this.clickObj.origin);
		} else if (this.deviceObject.appRunning) {
			
			if (!replicated && this.deviceObject.replicate) {
				this.replicate('onCanvasMouseUp', event);
			}
			
			if (!replicated) {
				this.testsService.testComponent.addTouchAction(this.clickObj, 'relative', this.deviceObject);
			}
			
			this.actionTouchXY(this.clickObj);
		}
	}
	
	toggleInspector() {
		this.showApps = false;
		this.showLogs = false;
		this.showInspector = !this.showInspector;
		if (this.showInspector) {
			this.inspector.load();
		} else {
			this.callScreen();
		}
	}
	
	toggleApps() {
		this.showInspector = false;
		this.showLogs = false;
		this.showApps = !this.showApps;
		this.callScreen();
	}
	
	toggleLogs() {
		this.showInspector = false;
		this.showApps = false;
		this.showLogs = !this.showLogs;
		this.callScreen();
	}
	
	actionPinch(val: any) {
		const actionRequestId = this.appService.uuid();
		const ref = this;
		
		this.connectService.sendAction(
			this.deviceObject.device._id,
			actionRequestId, 'live', {
				action: 'pinch',
				method: 'relative',
				reference: val,
			})
		.then((result: any) => {
			if (result.data.status === 'ERROR') {
				const retry = confirm(`OPS! Ocorreu um erro ao executar o comando: \r\n ${result.data.error.data} \r\n \r\n Deseja manter a conexão?`);
				if (!retry) {
					ref.connectService.releaseConnection(ref.deviceObject.device);
				}
			}
		});
	}
		
	actionTouchXY(val: any) {
		const actionRequestId = this.appService.uuid();
		const ref = this;
		
		this.connectService.sendAction(
			this.deviceObject.device._id,
			actionRequestId, 'live', {
				action: 'touch',
				method: 'relative',
				reference: val,
			})
		.then((result: any) => {
			if (result.data.status === 'ERROR') {
				const retry = confirm(`OPS! Ocorreu um erro ao executar o comando: \r\n ${result.data.error.data} \r\n \r\n Deseja manter a conexão?`);
				if (!retry) {
					ref.connectService.releaseConnection(ref.deviceObject.device);
				}
			}
		});
	}
			
	actionPressKey(key: any, replicated: Boolean) {
		if (!replicated) {
			this.testsService.testComponent.addPressKeyAction(key, this.deviceObject);
			
			if (this.deviceObject.replicate) {
				this.replicate('actionPressKey', key);
			}
		}
		
		const actionRequestId = this.appService.uuid();
		const ref = this;
		
		this.connectService.sendAction(
			this.deviceObject.device._id,
			actionRequestId, 'live', {
				action: 'presskey',
				reference: key,
			})
		.then((result: any) => {
			// console.log(result.data);
			if (result.data.status === 'ERROR') {
				const retry = confirm(`OPS! Ocorreu um erro ao executar o comando: \r\n ${result.data.error.data} \r\n \r\n Deseja manter a conexão?`);
				if (!retry) {
					ref.connectService.releaseConnection(ref.deviceObject.device);
				}
			}
		});
	}
					
	requestStats(ref) {
		const actionRequestId = ref.appService.uuid();
		const pStats = ref.connectService.requestStats(ref.deviceObject.device._id, actionRequestId);
		
		if (pStats) {
			pStats.then((stats) => {
				if (ref.deviceObject.stats.length < 10) {
					if (stats.data) {
						ref.deviceObject.stats.push(stats.data);
					}
				} else {
					ref.deviceObject.stats.shift();
					ref.deviceObject.stats.push(stats.data);
				}
				
				setTimeout(ref.requestStats, 2000, ref);
			});
		} else {
			setTimeout(ref.requestStats, 2000, ref);
		}
	}
	
	requestLog(ref) {
		//
		const actionRequestId = ref.appService.uuid();
		const pLog = ref.connectService.requestLog(ref.deviceObject.device._id, actionRequestId);
		if (pLog) {
			pLog.then((log) => {
				if (log.data.device) {
					ref.deviceObject.log.device.push(...log.data.device);
				}
				
				if (log.data.appium) {
					ref.deviceObject.log.appium.push(...log.data.appium);
				}
				
				setTimeout(ref.requestLog, 2000, ref);
			});
		} else {
			setTimeout(ref.requestLog, 2000, ref);
		}
	}
	
	callScreen() {
		this.connectService.requestScreen(this.deviceObject.device._id);
	}
	
	randomMsg() {
		setInterval(() => {
			this.loadMsg = this.messages[Math.floor((Math.random() * this.messages.length))];
		}, (10 * 1000));
	}
	
	ngOnInit() {
		this.keys = [];
		this.deviceObject.stage = this;
		this.pressedKeys = [];
		this.buffer = [];
		this.keyTimer = setTimeout(() => {console.log('init')}, 0)

		
		this.height = this.connectService.stageHeight;
		//this.width = this.connectService.stageWidth;
		this.width = this.deviceObject.device.model.resolution.width / (this.deviceObject.device.model.resolution.height / this.height);
		
		
		this.stageResizeSubscription = this.connectService.stageResizeEvent.subscribe((dimension: any) => {
			this.height = this.connectService.stageHeight;
			//this.width = this.connectService.stageWidth;
			this.width = this.deviceObject.device.model.resolution.width / (this.deviceObject.device.model.resolution.height / this.height);
			
			//this.touch1.nativeElement.style.display = 'none';
			
			this.touch1.nativeElement.style.top = '0px';
			this.touch1.nativeElement.style.left = '0px';
		});
		
		this.screenSubscr = this.connectService.screenReceiveEvent.subscribe((deviceObject: any) => {
			if (this.showInspector || deviceObject.fullScreen) {
				return;
			}
			if (deviceObject.device._id === this.deviceObject.device._id) {
				this.renderScreen(deviceObject);
			}
		});
		
		// this.logSubscr = this.connectService.logReceiveEvent.subscribe((deviceObject: any) => {
		// 	if (deviceObject.device._id === this.deviceObject.device._id) {
		// 		this.deviceObject.log = deviceObject.log;
		// 	}
		// });
		
		this.callScreen();
		this.requestStats(this);
		this.randomMsg();
	}
	
	ngOnDestroy() {
		this.stageResizeSubscription.unsubscribe();
		this.screenSubscr.unsubscribe();
	}
	
}
