import { Component, OnInit, Inject } from '@angular/core';
import { TestsService, TestCase, TestSuite, TestStep, TestFolder } from '../../../../tests/tests.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnInit {

	testCases: TestCase[];
	rootCases: any[];
	activeDirectory: any;
	casesFilter: String = '';
	path = [];

  constructor(private testsService: TestsService,
		public dialogRef: MatDialogRef<ImportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
		this.getCases();
  }

	sortDir(a, b) {
		if (a.type === 'folder' && b.type === 'test-case') {
			return -1;
		}

		if (a.type === 'test-case' && b.type === 'folder') {
			return 1;
		}

		if (a.type === 'folder' && b.type === 'folder') {
			if (a.value > b.value) {
				return 1;
			}

			if (a.value < b.value) {
				return -1;
			}

			return 0;
		}

		if (a.type === 'test-case' && b.type === 'test-case') {
			if (a.testCase.name > b.testCase.name) {
				return 1;
			}

			if (a.testCase.name < b.testCase.name) {
				return -1;
			}

			return 0;
		}

	  return 0;
	}

	back(count) {
		if (count !== undefined) {
			this.path = this.path.slice(0, count);
		} else {
			this.path.splice(-1);
		}

		this.activeDirectory = this.resolvePath();
	}

	getRawDirectory(): any {
		let directory: any = {
			name: '/',
			tree: this.rootCases,
		};

		if (this.path.length > 0) {
			this.path.forEach((f) => {
				directory = directory.tree[f.rawIndex];
			});
		}

		return directory;
	}

	resolvePath() {
		let directory: any = {
			name: '/',
			tree: JSON.parse(JSON.stringify(this.rootCases)),
		};

		if (this.path.length > 0) {
			this.path.forEach((f) => {
				directory = directory.tree[f.rawIndex];
			});
		}

		let index = 0;

		directory.tree.forEach((f) => {
			f.testCase = this.testCases.find((ts) => ts._id === f.value);
			f.rawIndex = index++;
		});

		directory.tree = directory.tree.filter((f) => {
			if (f.type === 'folder' && f.value) {
				return f.value.toLowerCase().includes(this.casesFilter.toLowerCase());
			} else {
				return f.testCase.name.toLowerCase().includes(this.casesFilter.toLowerCase());
			}
		});

		directory.tree.sort(this.sortDir);

		return directory;
	}

	openFolder(folder: any) {
		this.path.push({rawIndex: folder.rawIndex, name: folder.value});
		this.activeDirectory = this.resolvePath();
	}

	importFile(file: any) {
		this.dialogRef.close(file.testCase);
	}

	getCases() {
		this.testsService.getCaseTree().subscribe((treeResult) => {
			this.rootCases = treeResult.json().root;
			this.testsService.listTestCases().subscribe((testCasesResult) => {
				this.testCases = testCasesResult.json();
				this.activeDirectory = this.resolvePath();
				console.log(this.activeDirectory);
			}, (error) => {
				console.error(error);
			});
		}, (error) => {
			console.error(error);
		});
	}

}
