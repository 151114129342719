import { QueryList, Component, Input, Inject, ChangeDetectorRef, ElementRef, ViewChild, OnInit, OnDestroy, NgZone, HostListener, AfterViewInit, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras, ParamMap } from '@angular/router';
import { DevicesService } from '../devices/devices.service';
import { Title } from '@angular/platform-browser';

declare var io: any;

@Component({
  selector: 'app-watcher',
  templateUrl: './watcher.component.html',
  styleUrls: ['./watcher.component.scss']
})
export class WatcherComponent implements OnInit {

	@ViewChild('canvas') canvas: ElementRef;
	
	constructor(
		private router: Router,
		private deviceService: DevicesService,
		private route: ActivatedRoute,
		private zone: NgZone,
		private titleService: Title,
	) {
	}

	deviceObject: any;
	heightSize: number = 100;
	loading: Boolean = true;
	lastUpdate: any;
	watcherStatus: String = 'Standby';
	timer: any;
	appiumFilter: string;	
	logStatus: any = {show: true};
	backgroundOpt: any = {
		statusColor: 'green',
		overlay: 'rgba(50,50,50,1)'
	};
	dwSocket: any;

	backgroundByStatus(){
		this.zone.run(() => {
			setInterval(() => {
				if(this.deviceObject.device.status == 'ONLINE'){
					this.backgroundOpt.overlay = 'rgba(50,50,50,0.7)';
					this.backgroundOpt.statusColor = 'green';
					this.watcherStatus = 'Standby';
				}
				if(this.deviceObject.device.status == 'BUSY' && !this.loading){
					this.backgroundOpt.overlay = 'rgba(50,50,50,0)';
					this.backgroundOpt.statusColor = 'orange';
					this.watcherStatus = 'Em uso';					
				}
				if(this.deviceObject.device.status == 'OFFLINE'){
					this.backgroundOpt.overlay = 'rgba(50,50,50,1)';
					this.backgroundOpt.statusColor = 'red';
					this.watcherStatus = 'Offline';					
				}
			}, 1000);
		});
	}

	renderScreen() {
		this.lastUpdate = new Date().getTime();
		this.loading = false;
		
		const g = this.canvas.nativeElement.getContext('2d');
		let blob = new Blob([this.deviceObject.screen], {type: 'image/jpeg'});
		const URL = window.URL || window['webkitURL'];
		let img = new Image();
		let u = URL.createObjectURL(blob);
		const ref = this;

		img.onload = function() {
			let ratio = 0;
			ratio = (img.width/300);
			ref.canvas.nativeElement.setAttribute('width', 300);
			ref.canvas.nativeElement.setAttribute('height', (img.height/ratio));

			g.drawImage(img, 0, 0, 300, (img.height/ratio));
			img.onload = null;
			img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
			img = null;
			u = null;
			blob = null;
		};
		img.src = u;
	}

	getDevice(id: any){
		return this.deviceService.devices.find((d) => d._id === id);
	}

	subStatus(){
		this.dwSocket.on('watcher-status', (message: any) => {
			this.zone.run(() => {
				this.deviceObject.device.status = message.status;
			});
		});
	}

	subScreen(){
		this.lastUpdate = new Date().getTime();

		this.dwSocket.on('device-screen', (message: any) => {
			this.zone.run(() => {
				this.heightSize = 0;
				this.deviceObject.screen = message;
				this.renderScreen();

				clearTimeout(this.timer);
				this.timer = setTimeout(()=>{
					let now = new Date().getTime();
					if((now - this.lastUpdate) > 15000){
						this.heightSize = 100;
					}
				},15000);
			});
		});
	}

	ngOnInit() {
		this.deviceService.getDevices().subscribe(async resp => {
			this.deviceService.devices = await resp.json();
			
			this.route
			 .queryParamMap
			 .subscribe((map: ParamMap) => {
				this.deviceObject = {
					device: {
						_id: map.get('device')
					},
					log:{
						appium: [],
						device: []
					}
				};
				const device = this.getDevice(this.deviceObject.device._id);
				
				this.deviceObject.device = device;
				this.dwSocket = io(`/${this.deviceObject.device._id}`);
			
				this.titleService.setTitle(`${this.deviceObject.device.alias}`);
	
				this.subScreen();
				this.subStatus();
			},
			(error => console.log(error)));
	
			this.backgroundByStatus();
		})
	}
}
