import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http } from '@angular/http';
import { $Token } from '../providers';
import { LoginService } from '../login/login.service';
import { GridsterConfig, GridsterItem }  from 'angular-gridster2';
import { DashboardService } from './dashboard.service';
import { MatDialog } from '@angular/material';
import { EditWidgetDialogComponent } from './editWidgetDialog/edit-widget-dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	options: GridsterConfig;
	dashboard: Array<GridsterItem>;

	constructor
	(	
		private http: Http, 
		@Inject($Token) private $: any, 
		public loginService : LoginService,
		private dashboardService: DashboardService,
		private dialog: MatDialog,
	) {
		this.options = {
			itemChangeCallback: (item) => {
				if(this.dashboardService){
					this.dashboardService.updateWidgets(item)
					.subscribe(async resp => console.log(await resp.json()));
				}
			},
			displayGrid: 'none',
			minCols: 2,
			minRows: 4,
			//maxCols: 9,
			outerMargin: false,
			gridType: 'fixed',
			fixedColWidth: 200,
			fixedRowHeight: 130,
			setGridSize: true,
			mobileBreakpoint: 640,
			draggable: {
				enabled: true,
				dropOverItems: true
			}
		};
	}

	widgetsDefault = [
		{
			title: "apiKey",
			cols: 2,
			rows: 1,
			x: 0,
			y: 0
		}
	];

	ngOnInit() {
		this.setWidgets();
	}

	openEditDialog(){
        this.dialog.open(EditWidgetDialogComponent, {
            width: '300px'
        })
	}

	setWidgets() {
		if(!this.loginService.user.team.widgets){
			this.loginService.user.team.widgets = this.widgetsDefault;
		}
		
		this.loginService.user.team.widgets.forEach(wgt => {
			if(!wgt.x) wgt.x = 0;
			if(!wgt.y) wgt.y = 0;
			if(!wgt.cols) wgt.cols = 2;			
			if(!wgt.rows) wgt.rows = 1;			
		})
	}
}
