import {  ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { TestsService, TestCase, TestSuite, TestStep, TestFolder } from './../../tests/tests.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-suite-dialog',
  templateUrl: './suite-dialog.component.html',
  styleUrls: ['./suite-dialog.component.scss']
})
export class SuiteDialogComponent implements OnInit, OnDestroy {
    
    @Input() elementDialog: any;
    testSuites: TestSuite[];
    suitesFilter: String;

    constructor(
		private testsService: TestsService,
        private dialog: MatDialog,
        private router: Router,
        private dialogRef: MatDialogRef<SuiteDialogComponent>
	) { }

    ngOnInit() {
        this.getSuites();   
    }

    getSuites(){
		this.testsService.listTestSuites().subscribe((result) => {
			this.testSuites = result.json();
		}, (error) => {
			console.error(error);
		});
	}

    getCasesName(testCases){
		return testCases.reduce((prev, curr) => prev+curr.name+', ' ,'').slice(0,-2);
	}

    select(testSuite){
        this.dialogRef.close();
        this.router.navigate([`/testsuite`], {queryParams: {id:testSuite._id}});
    }

    ngOnDestroy(): void {
        //throw new Error("Method not implemented.");
    }
}
