import { NgModule } from '@angular/core';
import { MatInputModule, MatButtonModule, MatRadioModule, MatCheckboxModule, MatSidenavModule, MatMenuModule, MatIconModule, MatSnackBarModule, MatTooltipModule, MatTabsModule, MatTableModule, MatToolbarModule, MatDialogModule, MatCardModule } from '@angular/material';

@NgModule({
    declarations: [
    ],
    imports: [
        MatInputModule,
		MatButtonModule,
		MatCheckboxModule,
		MatRadioModule,
		MatMenuModule,
		MatSidenavModule,
		MatIconModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatTabsModule,
        MatTableModule,
		MatToolbarModule,
		MatDialogModule,
		MatCardModule,
    ],
    exports: [
        MatInputModule,
		MatButtonModule,
		MatCheckboxModule,
		MatRadioModule,
		MatMenuModule,
		MatSidenavModule,
		MatIconModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatTabsModule,
        MatTableModule,
		MatToolbarModule,
		MatDialogModule,
		MatCardModule,
    ]
  })
  export class SharedModule { }
