import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVER } from '../../providers';

@Injectable()
export class ResumeCompanyService {

	constructor (
		private http: Http,
	) {
		
	}

	getCompanyResume(id) {
		return this.http.post(`${URL_SERVER}/admin/company/resume`, {id});
	}
}
