import { Component, ChangeDetectorRef, Input, NgZone, OnInit, OnDestroy, Inject } from '@angular/core';
import { ShowcaseService } from './showcase.service';

@Component({
    selector: 'app-showcase',
    templateUrl: './showcase.component.html',
    styleUrls: ['./showcase.component.scss']
})

export class ShowcaseComponent implements OnInit {
    showcases: [];
    
    constructor(
        public showcaseService: ShowcaseService,
        public zone: NgZone
	) {

    }
    
    ngOnInit() {
        this.zone.run(() => {
            this.showcases = this.showcaseService.showcases;
        })
    }
    
    getColor(sNumber){
        let num = parseFloat(sNumber.replace(',','.'));

        return num > 35 ? 'red' : (num > 20 ? 'orange' : 'green') ;
    }
}