import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../login/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.scss']
})
export class ApiKeyComponent implements OnInit {

    constructor(
      public loginService: LoginService,
      private toastr: ToastrService
    ) { }

    ngOnInit() {
      
    }

    copyInput(inputEl){
      let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = inputEl.value;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      this.toastr.success('Copiado!');
    }

}
