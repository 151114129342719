import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-delay',
  templateUrl: './delay.component.html',
  styleUrls: ['./delay.component.scss']
})
export class DelayComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DelayComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

	secconds: number;

  ngOnInit() {
		if (this.data.step !== undefined) {
			this.secconds = this.data.step.defaultValue;
		}
  }

	add() {
		 this.dialogRef.close(this.secconds);
	}

}
