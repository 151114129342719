import { Component, OnInit, ViewChildren, ViewChild, QueryList, ElementRef, ChangeDetectorRef, NgZone} from '@angular/core';
import { MatDialog } from '@angular/material';
import { TestsService, TestFolder } from '../tests/tests.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DevicesService, DeviceFilter } from '../devices/devices.service';
import { D3Service, D3, Selection } from 'd3-ng2-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-report-tree',
  templateUrl: './report-tree.component.html',
  styleUrls: ['./report-tree.component.scss']
})
export class ReportTreeComponent implements OnInit {

	d3: any;

	root: any;
	activeDirectory: any;
	path = [];

	executionGroupFilter: DeviceFilter = DeviceFilter.getInstance(true);
	executionsNameFilter: String = '';
	listForDelete: any[];
	executionList: any[];
	rawExecutionList: any[];
	showDragUp: Boolean;
	lReportFilter: String = '';
	transactionText: String = '';

	liveReportFilter: String = '';
	liveReportList: any[];

	soundRecordList: any[];
	logcatList: any[];

	@ViewChild('transactionModal') transactionModal: ElementRef;
	@ViewChildren('pieMetrics') pieMetricsContainer: QueryList<ElementRef>;
	pieSVGList: any[] = [];

  constructor(
		private testsService: TestsService,
		public devicesService: DevicesService,
		private dialog: MatDialog,
		private router: Router,
        private toastr: ToastrService,
		private zone: NgZone,
		private d3Service: D3Service,
		private changeDetector: ChangeDetectorRef,
	) {
		this.d3 = d3Service.getD3();
	}

	ngOnInit() {
		this.listForDelete = [];
		this.soundRecordList = [];
		this.logcatList = [];

		this.getReports().then(() => {
			this.testsService.listExecutions().subscribe((executionListResult: any) => {

				// Define a executionList
				this.executionList = this.rawExecutionList = executionListResult.json().map((execution) => {
					execution.deviceReport = execution.deviceReport.map((deviceReport) => {
						deviceReport.device = this.devicesService.devices.find((device) => device._id === deviceReport.device);
						return deviceReport;
					});
					if (!execution.name) {
						execution.name = '';
					}
					return execution;
				});
 
				this.loadRoot();
			});
		});

		this.testsService.getLiveReport().subscribe(async list => {
			let data = await list.json();
			this.liveReportList = data;
			console.log(data)
		})
		
		this.loadSounds();
		this.loadLogs();

	}

	getThumbUrl(device) {
		return device.model.urlThumb ? device.model.urlThumb : (device.model.thumb ? 'assets/images/thumbs/'+device.model.thumb : 'assets/images/iphone.svg');
	}

	loadSounds(){
		this.testsService.listSoundRecord(this.soundRecordList.length, 30).subscribe(async list => {
			let data = await list.json();
			this.soundRecordList = this.soundRecordList.concat(...data);
			console.log(data)
		})
	}
	
	refreshLogs(){
		this.logcatList = [];
		this.loadLogs();
	}

	loadLogs(){
		this.testsService.listDeviceLogs(this.logcatList.length, 30).subscribe(async list => {
			let data = await list.json();
			this.logcatList = this.logcatList.concat(...data);
			console.log(data)
		})
	}

	downloadAudio(url: string){
		var sHttpUrl = url.replace('http:', 'https:');
		var element = document.createElement('a');
		element.setAttribute('href', sHttpUrl);

		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}
	
	downloadLogs(url: string){
		var sHttpUrl = url.replace('http:', 'https:');
		var element = document.createElement('a');
		element.setAttribute('href', sHttpUrl);
		element.setAttribute('target', '_blank');

		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	getText(soundId){
		this.toastr.info('Carregando, isso pode levar alguns minutos!');
		this.testsService.speechToText(soundId).subscribe((resp) => {
			let data = resp.json();
			this.transactionText = data.transcription;
			this.transactionModal.nativeElement.style.display = 'block';
			console.log(resp.json())
		})
	}

	closeDialog(element: any) {
		element.style.display = 'none';
	}

	openLiveReport(report){
		this.router.navigate([`/reports/livereport/${report._id}`]);
	}

	editName(liveReport){
		const name = prompt('Digite o nome do relatório:');

		if (name) {
			this.testsService.updateReportName({_id: liveReport._id, name})
			.subscribe((resp) => {
				liveReport.name = name;
				console.log(resp.json())
			})
		}
	}

	removeReport(liveReport, idx){
		const conf = confirm(`Deletar relatório '${liveReport.name}'`);

		if (conf) {
			this.testsService.deleteLiveReport(liveReport._id)
			.subscribe((resp) => {
				this.liveReportList.splice(idx,1)
				console.log(resp.json())
			})
		}
	}
	
	getReports(){
		return new Promise<void>((resolve, reject) => {
			this.testsService.getReportTree().subscribe((treeResult) => {
				this.root = treeResult.json().root;

				resolve();

			}, (error) => {
				console.error(error);
			});
		});
	}

	loadRoot() {
		this.root = this.root.map((fileEl) => {

			let id;

			if (typeof fileEl.value === 'object') {
				id = fileEl.value._id;
			} else {
				id = fileEl.value;
			}

			if (fileEl.type === 'execution') {
				fileEl.value = this.executionList.find((execEl) => execEl._id === id);
			}
			return fileEl;
		});

		this.activeDirectory = this.resolvePath();

		this.changeDetector.detectChanges();

		let pieIndex = -1;

		this.activeDirectory.tree.forEach((file, fileIndex) => {
			if(file.type === 'execution' && file.value.deviceReport.length){
				pieIndex++;
				this.buildPieGraphic(fileIndex, pieIndex);
			}
		});
	}

	buildPieGraphic(fileIndex, pieIndex) {

		const successCount = this.getSuccess(this.activeDirectory.tree[fileIndex].value);
		const failCount = this.getFail(this.activeDirectory.tree[fileIndex].value);
		const emptyCount = successCount === 0 && failCount === 0 ? 1 : 0;

		const width = 90,
		    height = 90,
		    radius = Math.min(width, height) / 2;

		let pieSVG, pieG;

		const data = [
			{
				type: 'success',
				value : successCount,
			},
			{
				type: 'fail',
				value : failCount,
			},
			{
				type: 'none',
				value: emptyCount,
			}
		];

		const color = this.d3.scaleOrdinal().range(['#44a461', '#ee2c43', '#837e7f']);

		if (this.pieSVGList.length > pieIndex && this.pieSVGList[pieIndex]) {
			pieSVG = this.pieSVGList[pieIndex];
			pieSVG.remove();
		}

		pieSVG = this.d3.select(this.pieMetricsContainer.toArray()[pieIndex].nativeElement).append('svg')
			.attr('width', width)
		  .attr('height', height);

		this.pieSVGList[pieIndex] = pieSVG;

		const arc = this.d3.arc()
		.outerRadius(radius - 10)
		.innerRadius(0);

		const labelArc = this.d3.arc()
		.outerRadius(radius - 40)
		.innerRadius(radius - 40);

		const pie = this.d3.pie()
		.sort(null)
		.value(function(d: any) { return d.value; });

		pieG = pieSVG
	  	.append('g')
	    .attr('transform', `translate(${width / 2},${height / 2})`);

	  const g = pieG.selectAll('.arc').data(pie(data)).enter().append('g').attr('class', 'arc');
		g.append('path').attr('d', <any>arc).style('fill', function(d) {
			return color(d.index.toString()) as any;
		});
		
	}


	getTotal(execution: any) {
		let result = 0;
		for ( let i = 0; i < execution.deviceReport.length; i++) {
			result = result + execution.deviceReport[i].succeed + execution.deviceReport[i].fail;
		}
		return result;
	}

	getFail(execution: any) {
		let result = 0;
		for ( let i = 0; i < execution.deviceReport.length; i++) {
			result = result + execution.deviceReport[i].fail;
		}
		return result;
	}

	getSuccess(execution: any) {
		let result = 0;
		for ( let i = 0; i < execution.deviceReport.length; i++) {
			result = result + execution.deviceReport[i].succeed;
		}
		return result;
	}

	openFolder(folder: any) {
		this.path.push({rawIndex: folder.rawIndex, name: folder.value});
		this.activeDirectory = this.resolvePath();

		this.loadRoot();

		this.changeDetector.detectChanges();
	}

	getRawParentDirectory() {
		const path = [];

		let directory: any = {
			name: '/',
			tree: this.root,
		};

		if (this.path.length > 0) {

			for (let i = 0; i < this.path.length - 1; i++) {
				path.push(this.path[i]);
			}

			path.forEach((f) => {
				directory = directory.tree[f.rawIndex];
			});
		}

		return directory;
	}

	getRawDirectory(): any {
		let directory: any = {
			name: '/',
			tree: this.root,
		};

		if (this.path.length > 0) {
			this.path.forEach((f) => {
				directory = directory.tree[f.rawIndex];
			});
		}

		return directory;
	}

	back(count) {
		if (count !== undefined) {
			this.path = this.path.slice(0, count);
			console.log(this.path);
		} else {
			this.path.splice(-1);
		}

		this.activeDirectory = this.resolvePath();
		this.loadRoot();
	}

	resolvePath() {
		let directory: any = {
			name: '/',
			tree: JSON.parse(JSON.stringify(this.root)),
		};

		if (this.path.length > 0) {
			this.path.forEach((f) => {
				directory = directory.tree[f.rawIndex];
			});
		}

		let index = 0;

		directory.tree.forEach((f) => {
			f.rawIndex = index++;
		});

		directory.tree = directory.tree.map((fileEl) => {

			let id;

			if (typeof fileEl.value === 'object') {
				id = fileEl.value._id;
			} else {
				id = fileEl.value;
			}

			if (fileEl.type === 'execution') {
				fileEl.value = this.executionList.find((execEl) => execEl._id === id);
			}
			return fileEl;
		});

		directory.tree = directory.tree.filter((f) => {
			if (f.type === 'folder' && f.value) {
				return f.value.toLowerCase().includes(this.executionsNameFilter.toLowerCase());
			} else {
				if(!f.value.name){
					f.value.name = '';
				}
				return f.value.name.toLowerCase().includes(this.executionsNameFilter.toLowerCase());
			}
		});

		directory.tree.sort(this.sortDir);

		console.log(directory);

		return directory;
	}

	sortDir(a, b) {
		if (a.type === 'folder' && b.type === 'execution') {
			return -1;
		}

		if (a.type === 'execution' && b.type === 'folder') {
			return 1;
		}

		if (a.type === 'folder' && b.type === 'folder') {
			if (a.value > b.value) {
				return 1;
			}

			if (a.value < b.value) {
				return -1;
			}

			return 0;
		}

		if (a.type === 'execution' && b.type === 'execution') {
			if (a.value.name > b.value.name) {
				return 1;
			}

			if (a.value.name < b.value.name) {
				return -1;
			}

			return 0;
		}

	  return 0;
	}

	getList(type: string) {

		if (this.executionGroupFilter[type].seeAll) {
			return this.devicesService.pressets[type];
    }
			return this.devicesService.pressets[type].slice(0, 5);
   }

	 onChangeFilter(event: any, type: string, value: any) {

		console.log(type, value);

		let ref;
		value && typeof(value) === 'string' ? ref = value : ref = value._id;

		if (event.checked) {
			if (this.executionGroupFilter[type].filter.indexOf(ref) === -1) {
				this.executionGroupFilter[type].filter.push(ref);
			}
		} else {
			if (this.executionGroupFilter[type].filter.indexOf(ref) !== -1) {
				this.executionGroupFilter[type].filter.splice(this.executionGroupFilter[type].filter.indexOf(ref), 1);
			}
		}
		this.filterExecutionGroup();
  }

	openExecution(execution: any) {
		this.router.navigate(['/report-suites', execution._id]);
	}

	filterExecutionGroup() {

		const raw = JSON.parse(JSON.stringify(this.rawExecutionList));

		this.executionList = raw.filter((execution) => {
			execution.deviceReport = execution.deviceReport.filter((deviceReport) => {
				let valid = true;
				for (const presset of Object.keys(this.executionGroupFilter)) {
					if (this.executionGroupFilter[presset].filter.length > 0) {
						// switch
						switch (presset) {
							case 'types': {
								let hasType = false;
								for (const type of deviceReport.device.model.type) {
									if (this.executionGroupFilter[presset].filter.indexOf(type._id) !== -1) {
										hasType = true;
									}
								}
								valid = hasType;
							}
							break;
							case 'models': {
								if (this.executionGroupFilter[presset].filter.indexOf(deviceReport.device.model._id) === -1) {
									valid = false;
								}
							}
							break;
							case 'manufacturers': {
								if (this.executionGroupFilter[presset].filter.indexOf(deviceReport.device.model.manufacturer._id) === -1) {
									valid = false;
								}
							}
							break;
							case 'oss': {
								if (this.executionGroupFilter[presset].filter.indexOf(deviceReport.device.OS.platform) === -1) {
									valid = false;
								}
							}
							break;
							case 'resolutions': {
								const resolution = deviceReport.device;
								if (this.executionGroupFilter[presset].filter.indexOf(`${resolution.width}x${resolution.height}`) === -1) {
									valid = false;
								}
							}
							break;
						}
						//
					}
				}
				return valid;
				//
			});
			return true;
		});
		this.loadRoot();
	}

	filterLive(){

	}

	inDeleteList(id){
		if(this.listForDelete)
			return this.listForDelete.indexOf(id) > -1;
		return false;
	}

	isChecked(type, value){
		let ref;
		if(value){
			typeof(value) === 'string' ? ref=value : ref=value._id;
		}
		return (this.executionGroupFilter[type].filter.indexOf(ref)) > -1;
	}

	delete(execution){
		let result = confirm(`Deseja deletar TODOS os relatórios de '${execution.value.name}'?`);
		let index = this.activeDirectory.tree.indexOf(execution);
		
		if(result){
			this.testsService.deleteReportByExecution(execution, this.path).subscribe((resp) => {
				this.zone.run(async () => {
					this.activeDirectory.tree.splice(index,1);
					await this.getReports()
					
					if(this.activeDirectory.tree.length < 1){
						this.back(0);
						this.loadRoot();
						this.activeDirectory = this.resolvePath();
					}
				});
			});
		}
	}

	selectExecution(executionId){
		let idx = this.listForDelete.indexOf(executionId);

		if(idx > -1){
			this.listForDelete.splice(idx,1);
		}else{
			this.listForDelete.push(executionId);
		}
	}

	deleteFolder(folder){
		let result = confirm(`Deseja deletar TODOS os relatórios de '${folder.value}'?`);
		let index = this.activeDirectory.tree.indexOf(folder);
		
		if(result){
			if(this.activeDirectory.tree[index].tree.length > 0){
                this.toastr.warning('Delete os itens internos primeiro!', '', {closeButton: true});
			}else{

				// this.testsService.deleteFolder(folder, this.path).subscribe((resp) => {
				// 	this.zone.run(async () => {
				// 		this.activeDirectory.tree.splice(index,1);
				// 		await this.getReports()
						
				// 		if(this.activeDirectory.tree.length < 1){
				// 			this.back(0);
				// 			this.loadRoot();
				// 			this.activeDirectory = this.resolvePath();
				// 		}
				// 	});
				// });
			}
		}
	}
}
