import { Component, Inject, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DevicesService, DeviceFilter } from '../devices/devices.service';
import { AppsService } from '../apps/apps.service';
import { ConnectService } from '../connect/connect.service';
import { windowToken } from '../providers';
import { UploadComponent } from '../util/upload/upload.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})

export class AppsComponent implements OnInit {

  deviceObject: any;
  apps: any[] = [];
  app: any;

	@ViewChild('confirmDelModal') confirmDelModal: ElementRef;

	osFilter: any[] = [];

	@ViewChild('upload') uploadComp: UploadComponent;
	uploadSubscr: any;
	uploadPlatform: any;
	upload: {
		platform: any,
	} = {
		platform: null,
	};

	deviceOSList = [{platform: 'android'}, {platform: 'ios'}];

  constructor(
		private zone: NgZone,
    private router: Router,
    private toastr: ToastrService,
		private appsService: AppsService,
    private devicesService: DevicesService,
    private connectService: ConnectService,
    @Inject( windowToken) private wsocket: any
  ) {}

  ngOnInit() {

    this.appsService.listApps().subscribe(
				(result: any) => {
					this.apps = result.json();

					// FIX View Render stuck
					setTimeout(() => {
						try{
							this.uploadSubscr = this.uploadComp.uploadEvent.subscribe((val: any) => {
								this.apps.push(val);
							});
						}catch(err){
							console.log('Deu Ruin...',err)
						}
					}, 1000);
				},
				(error: any) => {

				},
			);
  }

	filteredApps(): any[] {
		if (this.osFilter.length === 0) {
			return this.apps;
		} else if (this.osFilter.length === this.deviceOSList.length) {
			return this.apps;
		} else {
			const filtered: any[] = [];
			this.apps.forEach((app) => {
				if (this.osFilter.indexOf(app.OS.platform) > -1) {
					filtered.push(app);
				}
			});
			return filtered;
		}
	}

	uploadParams() {
		if (this.uploadPlatform) {
			return {type: this.uploadPlatform, deviceOS: this.uploadPlatform};
		} else {
			return null;
		}
	}

	copyURL(file: any){
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = file.resource.url;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);

		this.toastr.success('Copiado!');
	}

	onChangeFilter(event: any, type: string, value: any) {
		const index = this.osFilter.indexOf(value);
		if (event.checked) {
			if (index === -1) {
				this.osFilter.push(value);
			}
		} else {
			if (index  > -1) {
				this.osFilter.splice(index, 1);
			}
		}
	}

	onClickDelete(app: any) {
		this.app = app;
		this.openModal(this.confirmDelModal.nativeElement);
	}

	delete() {
		this.appsService.delete(this.app._id).subscribe(
  			 (result: any) => {
  				 this.closeModal(this.confirmDelModal.nativeElement);
					 this.apps = [];
					 this.ngOnInit();
  			 },
  			 (error: any) => {
				console.log(error)
  				 // this.errorMessage = error.json().message;
  			 }
			 );
	}

	editName(app){
		const newName = prompt('Insia o nome do APP');

		if(newName.length){
			console.log(newName);
			app.name = newName;
			
			this.appsService.renameApp(app).subscribe(resp => {
				let data = resp.json();

				console.log(data)
				this.toastr.success('Alterações salvas.');
			})

		}
	}

	openModal(modal: any) {
		modal.style.display = 'block';
	}

	closeModal(modal: any) {
		modal.style.display = 'none';
	}

}
