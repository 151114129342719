import { Injectable } from '@angular/core';
import { TestComponent } from '../connect/test/test.component';
import { Http, ResponseContentType } from '@angular/http';
import { URL_SERVER } from './../providers';
import { map } from 'rxjs/operators';

@Injectable()
export class TestsService {

  constructor(private http: Http) { }

	testComponent: TestComponent;

	listExecutions() {
 	 return this.http.get(`${URL_SERVER}/services/execution/list`);
  }

	getExecution(id: string) {
 	 return this.http.get(`${URL_SERVER}/services/execution/${id}`);
  }

	getExecutionsByDate(id: string, from: Date, to: Date) {
 	 return this.http.get(`${URL_SERVER}/services/execution/${id}`);
  }

	populateFolder(folder){
		return this.http.post(`${URL_SERVER}/services/test-suite/populateFolder`, {folderId: folder._id});
	}

	getSuiteTree() {
		return this.http.get(`${URL_SERVER}/services/test-suite/tree`);
	}

	getCaseTree() {
		return this.http.get(`${URL_SERVER}/services/test-case/tree`);
	}

	getReportTree() {
		return this.http.get(`${URL_SERVER}/services/report/tree`);
	}

	updateSuiteTree(root) {
		return this.http.post(`${URL_SERVER}/services/test-suite/tree`, {root});
	}

	updateCaseTree(root) {
		return this.http.post(`${URL_SERVER}/services/test-case/tree`, {root});
	}

	createTestSuite(name: String) {
		//
		return this.http.post(`${URL_SERVER}/services/test-suite`, {name});
	}

	createTestCase(name: String) {
		return this.http.post(`${URL_SERVER}/services/test-case`, {name});
	}

	viewTestSuite(id: String) {
		return this.http.get(`${URL_SERVER}/services/test-suite/${id}`);
	}

	getShortInfo(list){
		return this.http.post(`${URL_SERVER}/services/test-suite/shortInfo`, {list});
	}

	viewTestCase(id: String) {
		return this.http.get(`${URL_SERVER}/services/test-case/${id}`);
	}

	renameTestSuite(testSuite: TestSuite, name: String) {
		return this.http.post(`${URL_SERVER}/services/test-suite/${testSuite._id}/rename`, {name});
	}

	addTestCase(name: String) {
		return this.http.post(`${URL_SERVER}/services/test-suite/${this.testComponent.testSuite._id}/test-case/add`, {name});
	}

	importTestCase(testCaseId: String) {
		return this.http.post(`${URL_SERVER}/services/test-suite/${this.testComponent.testSuite._id}/test-case/import`, {testCaseId});
	}

	turnGenericTestCase(testSuite: TestSuite, index: number) {
		return this.http.post(`${URL_SERVER}/services/test-suite/${testSuite._id}/test-case/genericfy/${index}`, {});
	}

	reorderTestCases() {
		const testCases = this.testComponent.testSuite.testCases.map((tc) => tc._id);
		this.http.post(`${URL_SERVER}/services/test-suite/${this.testComponent.testSuite._id}/test-case/reorder`, {testCases}).subscribe(
			 (result: any) => {
				this.testComponent.testSuite = result.json();
				this.testComponent.activeTestCase = this.testComponent.testSuite.testCases[this.testComponent.testSuite.testCases.length - 1];
			 },
			 (error: any) => {
					console.log(error);
			 },
		 );
	}

	renameTestCase(testSuite: TestSuite, index: number, name: String) {
		return this.http.post(`${URL_SERVER}/services/test-suite/${testSuite._id}/test-case/rename/${index}`, {name});
	}

	removeTestCase(testSuite: TestSuite, index: number) {
		return this.http.post(`${URL_SERVER}/services/test-suite/${testSuite._id}/test-case/remove/${index}`, {});
	}

	removeCapability(testSuite: TestSuite, index: number) {
		return this.http.post(`${URL_SERVER}/services/test-suite/${testSuite._id}/capabilities/remove/${index}`, {});
	}

	editTestSuiteVar(testSuite: TestSuite) {
		return this.http.post(`${URL_SERVER}/services/test-suite/${testSuite._id}/variables/`, {
			variables: testSuite.variables
		});
	}

	editTestSuiteCapability(testSuite: TestSuite) {
		return this.http.post(`${URL_SERVER}/services/test-suite/${testSuite._id}/capabilities/`, {
			capabilities: testSuite.capabilities
		});
	}

	listTestSuites() {
		return this.http.get(`${URL_SERVER}/services/test-suite/list`);
	}
	
	listSoundRecord(skip, limit){
		return this.http.post(`${URL_SERVER}/services/sound/list`, {skip, limit});
	}

	listDeviceLogs(skip, limit){
		return this.http.post(`${URL_SERVER}/api/external/logs/list`, {skip, limit});
	}
	
	speechToText(soundId){
		return this.http.post(`${URL_SERVER}/services/sound/s2t`, {soundId});
	}

	listTestCases() {
		return this.http.get(`${URL_SERVER}/services/test-case/list`);
	}

	deleteSuite(suiteId) {
		return this.http.delete(`${URL_SERVER}/services/test-suite/${suiteId}`);
	}

	deleteCase(caseId) {
		return this.http.delete(`${URL_SERVER}/services/test-case/${caseId}`);
	}

	deleteReportByExecution(execution, path){
		return this.http.post(`${URL_SERVER}/services/report/deleteReportByExecution/`, {execution: execution.value._id, path});
	}

	deleteFolder(folder, path){
		return this.http.post(`${URL_SERVER}/services/report/deleteFolderReport/`, {folder, path});
	}
	
	exportCode(suiteId){
		return this.http.get(`${URL_SERVER}/services/test-suite/exportCode/${suiteId}`);
	}

	duplicateSuite(suite) {
		//
		return this.http.post(`${URL_SERVER}/services/test-suite/`, suite);
	}
	
	duplicateCase(testCase) {
		return this.http.post(`${URL_SERVER}/services/test-case/`, testCase);
	}
	
	exportLiveReport(reportId){
		return this.http.get(`${URL_SERVER}/services/livereport/export/${reportId}`);
	}

	getLiveReport(){
		return this.http.get(`${URL_SERVER}/services/livereport/list`);
	}

	findLiveReportById(id){
		return this.http.post(`${URL_SERVER}/services/livereport/find`, {id});
	}

	runTestSuite(testSuite: TestSuite, devices: any[], times: number, schedule: Date,
		sendMail: boolean, sendSMS: boolean, sendSlack: boolean, abortAfter3Fails: boolean, path: string) {

		console.log(testSuite);

		devices = devices.map((d) => d._id);
		return this.http.post(`${URL_SERVER}/services/test-suite/${testSuite._id}/run`,
			{variables: testSuite.variables, devices, times, schedule, sendMail, sendSMS, sendSlack, abortAfter3Fails, path}
		);
	}

	updateReportName(report){
		return this.http.post(`${URL_SERVER}/services/livereport/updateName`,{report});
	}
	
	deleteLiveReport(id){
		return this.http.post(`${URL_SERVER}/services/livereport/delete`, {id});
	}

	downloadPDF(liveReportId){
		const params = {liveReportId};

		return this.http.get(`${URL_SERVER}/services/livereport/download`, {params, responseType: ResponseContentType.Blob})
		.pipe(map(res => new Blob([res.blob()], {type: 'application/pdf'})))
	}

	updateSteps() {

		if (!this.testComponent.testSuite || !this.testComponent.activeTestCase) {
			return;
		}

		this.http.post(`${URL_SERVER}/services/test-suite/${this.testComponent.testSuite._id}/test-case/steps/${this.testComponent.activeTestCase._id}`,
			{
				steps: this.testComponent.activeTestCase.steps
			}
		).subscribe(
			 (result: any) => {
				const activeTestCaseIndex = this.testComponent.testSuite.testCases.findIndex((tc) => tc._id === this.testComponent.activeTestCase._id);
				this.testComponent.testSuite = result.json();
				this.testComponent.activeTestCase = this.testComponent.testSuite.testCases[activeTestCaseIndex];
			 },
			 (error: any) => {
				 	console.log(error);
			 },
		 );
	}

}

export class TestCase {
	_id: String;
  name: String;
  steps: TestStep[] = [];
}

export class TestVariable {
	_id: String;
  name: String;
  value: String;

	constructor(name: String) {
		this.name = name;
	}
}

export class TestCapability {
	_id: String;
  	name: String;
	value: String;

	constructor(name: String, value: String) {
		this.name = name;
		this.value = value;
	}
}

export class TestStep {
  alias: String;
  type: String;
	subType: String;
  defaultValue: any;

	setTouchAction(clickObj, deviceObject) {
		let resolution;

		if (deviceObject.device.model.logicalResolution) {
			resolution = deviceObject.device.model.logicalResolution;
		} else {
			resolution = deviceObject.device.model.resolution;
		}

		this.defaultValue = {
			origin: {
				x: clickObj.origin.x / resolution.width ,
				y: clickObj.origin.y / resolution.height,
			},
			dest: {
				x: clickObj.dest.x / resolution.width,
				y: clickObj.dest.y / resolution.height,
			},
			duration: clickObj.duration,
		};
	}
}

export class TestFolder extends TestStep {
	subSteps: TestStep[] = [];

	constructor() {
		super();
		this.type = 'folder';
		this.subSteps = [];
	}
}

export class TestSuite {
	_id: String;
	name: String;
	creationDate: Date;
	testCases: TestCase[] = [];
	variables: TestVariable[] = [];
	capabilities: TestCapability[] = [];

  constructor() {
    // this.testCases = [];
	}
}
