import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { AppService } from '../app.service';
import { localStorageToken, sessionStorageToken } from '../providers';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  errorMessage: String = '';
  responseMessage: String = '';
  responseMessageLogin = '';
  showForgetPasswordForm = false;
  showToken = false;

  constructor(
 	 private loginService: LoginService,
 	 private appService: AppService,
	  private toastr: ToastrService,
 	 @Inject(localStorageToken) private localStorage: any,
 	 @Inject(sessionStorageToken) private sessionStorage: any,
  ) { }

  ngOnInit() {
 	 this.loginForm = new FormGroup({
 		 username : new FormControl('', Validators.required),
 		 password : new FormControl('', Validators.required),
 		 token : new FormControl(''),
 		 rememberMe : new FormControl('', Validators.required),
	  });
	  
	  this.forgotPasswordForm = new FormGroup({
		email : new FormControl('', Validators.email),
		username: new FormControl('')
	  });
  }

  	doLogin() {
		this.errorMessage = '';
	 	this.loginService.doLogin(this.loginForm.value.username, this.loginForm.value.password, this.loginForm.value.rememberMe, this.loginForm.value.token).subscribe(
 			(result: any) => {
				let resp = result.json();

				if(resp.mfa){
					this.showToken = true;
					this.toastr.warning(resp.message);  
				}else{
					this.loginService.isLoggedIn = true;
					this.appService.storage = localStorage;
					
					// localStorage.removeItem('Auth-Token');
					// sessionStorage.removeItem('Auth-Token');
					this.appService.storage.setItem('Auth-Token', result.json().token);
					
					location.reload();
				}
 			},
 			(error: any) => {
				 this.errorMessage = error.json().error;
				 this.toastr.warning(error.json().error);  
 			},
 		);
  	}

  sendEmail() {
	if (this.forgotPasswordForm.status === 'VALID') {
		this.responseMessage = 'O email está sendo enviado. Aguarde...';
	  	this.loginService.forgotPassword(this.forgotPasswordForm.get('email').value, this.forgotPasswordForm.get('username').value)
	  				   .subscribe(
						(result: any) => {
							this.responseMessage = '';
							this.responseMessageLogin = 'Email enviado com sucesso!';
							setTimeout(() => this.responseMessageLogin = '', 3000);
							this.forgotPasswordForm.reset();
							this.showForgetPasswordForm = false;
						},
						(error: any) => {
							this.responseMessage = '';
							this.errorMessage = error.json().message;
						}
						);
	} else {
		this.errorMessage = 'Invalid format email';
		setTimeout(() => this.errorMessage = '', 2000);
	}
  }

  voltar() {
	  this.showForgetPasswordForm = false;
  }
}
