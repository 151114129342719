import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangePasswordService } from './change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  errorMessage: String = '';
  showErrorPage = true;
  successChangePassword = false;
  token = '';
  constructor(private changePasswordService: ChangePasswordService,
              private router: ActivatedRoute,
              private route: Router) { }

  ngOnInit() {
    this.router.queryParams.subscribe((params) => {

      if (params['email']) {
        this.token = params['email'];

        this.changePasswordService
            .checkValidToken(this.token)
            .subscribe(
              (result) => {
                this.showErrorPage = false;
                console.log(result);
              },
              (error) => {
                console.log(error);
              }
        );

      }
    });

    this.changePasswordForm = new FormGroup({
      newPassword : new FormControl('', Validators.required),
      confirmNewPassword: new FormControl('', Validators.required)
    });
  }

  sendNewPassword() {
    if (this.changePasswordForm.get('newPassword').value.length >= 5 && (this.changePasswordForm.get('newPassword').value === this.changePasswordForm.get('confirmNewPassword').value)) {

      this.changePasswordService
          .changePassword(this.changePasswordForm.get('confirmNewPassword').value, this.token)
          .subscribe(
            (result) => {
              this.showErrorPage = false;
              this.successChangePassword = true;
              setTimeout(() => this.route.navigate(['/login']), 3000 );
            },
            (error) => {
              this.showErrorPage = false;
            });
    } else {
      this.errorMessage = 'Senha inválida!';
    }
  }

}