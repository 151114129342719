import { Component, Input, Inject, ChangeDetectorRef, NgZone, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { ConnectService } from '../../connect.service';
import { windowToken } from '../../../providers';
import { StageComponent } from '../../stage/stage.component';
import { Subject } from 'rxjs';//possivel troca pra 'rxjs'
import { TreeComponent } from 'angular-tree-component';
import { TestsService, TestStep } from '../../../tests/tests.service';
import * as _ from 'lodash';

@Component({
	selector: 'app-tooltab-inspector',
	templateUrl: './inspector.component.html',
	styleUrls: ['./inspector.component.scss']
})
export class InspectorComponent implements OnInit, OnDestroy {

	@Input() deviceObject: any;

	@ViewChild(TreeComponent) tree: TreeComponent;

	width: Number = 100;
	height: Number = 100;
	stageResizeSubscription: Subject<any>;
	screenSubscr: any;
	selectedElement: any;
	bestMatch: any;
	working: Boolean = true;
	isHTML: Boolean = false;
	debounced: any = _.debounce(this.refresh.bind(this), 2000, { 'leading': true, 'trailing': false })
	nodes: any = [
	];

	parseInspector(data: any, index: Number) {

		const node: any = {};

		Object.keys(data).forEach((k) => {
			if (typeof data[k] === 'object' && k !== 'props') {
				data[k].forEach((el: any, i: Number) => {
					if (!node.children) {
						node.children = [];
					}
					node.children.push(this.parseInspector(el, i));
				});
			}
		});

		if (!data.props) {
			node.name = Object.keys(data)[0];
			node.props = {
				index: index,
				class: node.name
			};
		} else {
			node.name = data.props.class;
			node.props = data.props;
			node.props.index = index;
		}
		return node;
	}

	parseHTMLInspector(data: any, index: Number, name?: string) {

		const node: any = {};

		Object.keys(data).forEach((k) => {
			if (typeof data[k] === 'object' && k !== 'props') {

				if (data[k].length) {
					data[k].forEach((el: any, i: Number) => {
						if (!node.children) {
							node.children = [];
						}
						node.children.push(this.parseHTMLInspector(el, i, k));
					});
				} else {
					if (!node.children) {
						node.children = [];
					}
					node.children.push(this.parseHTMLInspector(data[k], 0, k));
				}
			}
		});

		node.name = name;

		if (!data.props) {
			//node.name = Object.keys(data)[0];
			node.props = {
				index: index,
				class: node.name
			};
		} else {
			//node.name = data.props.class;
			node.props = data.props;
			node.props.index = index;
		}
		return node;
	}

	refresh() {
		this.screenSubscr = this.connectService.screenReceiveEvent.subscribe((deviceObject: any) => {
			if (deviceObject.device._id === this.deviceObject.device._id) {
				this.deviceObject.stage.renderScreen(deviceObject);
				this.screenSubscr.unsubscribe();
				this.load();
			}
		});
		this.connectService.requestScreen(this.deviceObject.device._id);
	}

	load() {
		const actionRequestId = this.appService.uuid();
		this.working = true;
		this.connectService.sendAction(
			this.deviceObject.device._id,
			actionRequestId, 'source', {}).then((result: any) => {
				this.zone.run(() => {
					// console.log(JSON.stringify(result.data));
					// console.log(result.data);
					this.working = false;

					if (result.data.html) {
						this.isHTML = true;
						this.nodes = [result.data.html].map((el: any) => this.parseHTMLInspector(el, 0, 'html'));
					} else {
						this.isHTML = false;
						const key = Object.keys(result.data['android.widget.FrameLayout'][0]).find((k) => k !== 'props');
						//const root = result.data['android.widget.FrameLayout'][0][key];
						const root = [result.data['android.widget.FrameLayout'][0]];
						this.nodes = root.map((el: any) => this.parseInspector(el, 0));

						this.getPackage();
					}
				});
			});
	}

	getPackage() {
		const actionRequestId = this.appService.uuid();

		if (this.selectedElement) {
			this.connectService.sendAction(
				this.deviceObject.device._id,
				actionRequestId, 'apppackage', {}).then((result: any) => {
					this.zone.run(() => {

						this.selectedElement.props['package'] = result.data.appPackage;
						this.selectedElement.props['activity'] = result.data.appActivity;
					});
				});
		}
	}

	buildXpath(node: any, path: any): string {

		if (!node.data.props) {
			return '/' + path;
		}
		path = node.data.props.class + '[' + (parseInt(node.data.props.index, 10) + 1) + ']' + path;
		if (node.parent) {
			return this.buildXpath(node.parent, '/' + path);
		} else {
			return path;
		}
	}

	onActivate(event: any) {
		if (event.node.data && event.node.data.props) {
			this.selectedElement = event.node.data;
			this.selectedElement.props.xpath = this.buildXpath(event.node, '');

			const canvas = this.deviceObject.stage.canvas.nativeElement;

			const bounds = this.selectedElement.props.bounds;

			const g = canvas.getContext('2d');
			let blob = new Blob([this.deviceObject.screen], { type: 'image/jpeg' });
			const URL = window.URL || window['webkitURL'];
			let img = new Image();
			let u = URL.createObjectURL(blob);
			const deviceObject = this.deviceObject;
			let hRatio;

			if (deviceObject.screenResolution) {
				hRatio = (window.innerHeight - 100) / deviceObject.screenResolution.height;
			}

			img.onload = function () {

				canvas.setAttribute('width', img.width);
				canvas.setAttribute('height', img.height);
				
				deviceObject.width = (canvas.offsetWidth);
				g.drawImage(img, 0, 0);
				
				g.beginPath();
				g.rect(bounds.x * hRatio, bounds.y * hRatio, bounds.width * hRatio, bounds.height * hRatio);
				g.lineWidth = 4;
				g.strokeStyle = 'red';
				g.stroke();

				img.onload = null;
				img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
				img = null;
				u = null;
				blob = null;
			};
			img.src = u;
			if (!this.isHTML) {
				this.getPackage();
			}

		}
	}

	deepPositionSearch(tree: any, pos: any, bestMatch?: any): any {

		const element = tree.find((el: any) => {
			if (el.props.bounds) {
				const x0 = Number(el.props.bounds.x);
				const y0 = Number(el.props.bounds.y);
				const x1 = x0 + el.props.bounds.width;
				const y1 = y0 + el.props.bounds.height;

				if ((pos.x >= x0 && pos.x <= x1) && (pos.y >= y0 && pos.y <= y1)) {
					if (!bestMatch) {
						return el;
					} else {
						const elDx = x0 - x1;
						const elDy = y0 - y1;
						const bmDx = Number(bestMatch.props.bounds.x) - (Number(bestMatch.props.bounds.x) + bestMatch.props.bounds.width);
						const bmDy = Number(bestMatch.props.bounds.y) - (Number(bestMatch.props.bounds.y) + bestMatch.props.bounds.height);

						if ((elDx + elDy) < (bmDx + bmDy)) {
							return el;
						} else {
							return bestMatch;
						}
					}
				}
			}
		});

		if (element) {
			if (element.children) {
				return this.deepPositionSearch(element.children, pos, element);
			} else {
				return element;
			}
		}

		return bestMatch;
	}

	selectElementByPosition(pos: any) {
		const element = this.deepPositionSearch(this.nodes, pos);

		if (element) {
			const node = this.tree.treeModel.getNodeById(element.id);
			this.tree.treeModel.fireEvent({ eventName: 'activate', node });
			node.focus();
			node.ensureVisible();
			this.tree.treeModel.setFocusedNode(node);
		}
	}

	actionValidate(subType: string) {
		this.connectService.activeEnhancedKeyboard = false;
		this.testsService.testComponent.addValidation({
			reference: this.selectedElement.props[subType],
			method: subType,
		}, this.selectedElement, this.deviceObject)
	}

	actionTouch(subType: string) {

		const propagation = [this.deviceObject];

		if (this.deviceObject.replicate) {
			this.connectService.activeDevices.forEach((deviceConnection) => {
				if (deviceConnection.device._id !== this.deviceObject.device._id && deviceConnection.replicate) {
					propagation.push(deviceConnection);
				}
			});
		}

		this.testsService.testComponent.addTouchAction({
			reference: this.selectedElement.props[subType],
			method: subType,
		}, 'element', this.deviceObject);

		this.working = true;
		propagation.forEach((deviceConnection) => {
			const actionRequestId = this.appService.uuid();
			this.connectService.sendAction(
				deviceConnection.device._id,
				actionRequestId, 'live', {
				action: 'touch',
				method: subType,
				reference: this.selectedElement.props[subType]
			}).then((result: any) => {
				this.working = false;
				//this.zone.run(() => {
				console.log(result.data);
				if (deviceConnection.device._id == this.deviceObject.device._id) {
					setTimeout(() => { this.refresh() }, 1000);
				}
				//});
			});
		});
	}

	actionType(method) {
		this.connectService.activeEnhancedKeyboard = false;
		this.testsService.testComponent.addKeyboard(method, this.selectedElement.props[method]).then((text: String) => {
			if (!text || text.length === 0) {
				return;
			}

			if (!this.connectService.activeDevice) {
				return;
			}

			const propagation = [this.connectService.activeDevice];

			if (propagation[0].replicate) {
				this.connectService.activeDevices.forEach((deviceConnection) => {
					if (deviceConnection.device._id !== propagation[0].device._id && deviceConnection.replicate) {
						propagation.push(deviceConnection);
					}
				});
			}

			propagation.forEach((deviceConnection) => {
				const actionRequestId = this.appService.uuid();
				this.connectService.sendAction(
					deviceConnection.device._id,
					actionRequestId, 'live', {
					action: 'type',
					method: method,
					reference: this.selectedElement.props[method],
					text: text,
				}).then((result: any) => {
					console.log(result.data);
					if (deviceConnection.device._id === this.deviceObject.device._id) {
						setTimeout(() => { this.refresh() }, 1000);
					}
				});
			});
		});
	}

	constructor(
		protected router: Router,
		protected connectService: ConnectService,
		protected appService: AppService,
		protected zone: NgZone,
		protected changeDetector: ChangeDetectorRef,
		protected testsService: TestsService,
		@Inject(windowToken) private window: any,
	) {
	}

	keys(obj: any) {
		return Object.keys(obj);
	}

	ngOnInit() {
		this.width = this.connectService.stageWidth;
		this.height = this.connectService.stageHeight;

		this.stageResizeSubscription = this.connectService.stageResizeEvent.subscribe((dimension: any) => {
			this.width = this.connectService.stageWidth;
			this.height = this.connectService.stageHeight;
		});
	}

	ngOnDestroy() {
		this.stageResizeSubscription.unsubscribe();
	}

}
