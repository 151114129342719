import {
  AfterViewInit,
  Component,
  Input,
  Inject,
  NgZone,
  ViewChild,
  ViewChildren,
  OnInit,
  OnDestroy,
  ElementRef,
  QueryList
} from '@angular/core';
import { StageComponent } from '../../stage/stage.component';
import { D3Service, D3, Selection } from 'd3-ng2-service';
import { Observable } from 'rxjs';//possivel troca pra 'rxjs'

@Component({
  selector: 'app-tooltab-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements AfterViewInit, OnInit, OnDestroy {

	@Input() deviceObject: any;
	@ViewChild('statsContainer') statsContainer: ElementRef;
	@ViewChild('toolTipStats') toolTipStats: ElementRef;
  @ViewChild('appiumLogsContainer') appiumLogsContainer: ElementRef;
  @ViewChild('deviceLogsContainer') deviceLogsContainer: ElementRef;
  @ViewChildren('appiumLogs') appiumLogs: QueryList<ElementRef>;
	@ViewChildren('deviceLogs') deviceLogs: QueryList<ElementRef>;

	private d3: D3;
	statsSVG: any;

	index: number;
	intervalRef: any;
  locked = true;
  lockIcon = 'lock';
  appiumFilter: string;
  deviceFilter: string;

  constructor(private d3Service: D3Service) {
		this.d3 = d3Service.getD3();
	}

	buildMetrics() {

		if (this.statsSVG) {
			this.statsSVG.remove();
			this.statsSVG = undefined;
		}

		const width = this.statsContainer.nativeElement.offsetWidth;
		const ref = this;
		const height = 20;
		const min = 1;
		const max = 100;

		const data = ref.deviceObject.stats;

		// data.push(data[10 - 1]);

		this.statsSVG = this.d3.select(this.statsContainer.nativeElement)
		.append('svg')
		.attr('width', width)
		.attr('height', height)
		.on('mouseover', function(d) {
			ref.d3.select(ref.toolTipStats.nativeElement)
			.transition()
			.duration(200)
			.style('opacity', 1);
		})
		.on('mouseout', function(d) {
			ref.d3.select(ref.toolTipStats.nativeElement)
			.transition()
			.duration(200)
			.style('opacity', 0);
		})
		.on('mousemove', function(d) {
			/*const x = ref.d3.mouse(this as SVGGElement)[0];
			const els = width / ref.stepList.length;
			let i = 0;
			let sizeRef = 0;
			for (; i < ref.stepList.length; i++, sizeRef += els) {
				if (x < sizeRef) {
					break;
				}
			}
			ref.tooltipMetrics.nativeElement.style.left = `${x + 20}px`;
			ref.metric = data[i];*/
		});

		const gCPU = this.statsSVG.append('g')
		.attr('width', width / 3)
		.attr('height', '20');

		gCPU.append('line')
		.attr('x1', 0)
		.attr('x2', width / 3)
		.attr('y1', 1)
		.attr('y2', 1)
		.attr('shape-rendering', 'optimizeSpeed')
		.attr('stroke-width', 1)
		.attr('stroke', 'red');

		/*gCPU.append('rect')
		 .attr('class', 'overlay')
		 .attr('width', width)
		 .attr('height', 20)
		 .on('mouseover', function(d) { console.log(this);  })
		 .on('mouseout', function(d) { console.log(d);  })
		 .on('mousemove', function(d) { console.log(d); });*/

		const gMEM = this.statsSVG.append('g')
		.attr('height', '20')
		.attr('width', width / 3)
		.attr('transform', `translate(${(width / 3)}, 0)`);

		gMEM.append('line')
		.attr('x1', 0)
		.attr('x2', width / 3)
		.attr('y1', 1)
		.attr('y2', 1)
		.attr('stroke-width', 1)
		.attr('shape-rendering', 'optimizeSpeed')
		.attr('stroke', '#44a461');

		const gSIG = this.statsSVG.append('g')
		.attr('height', '20')
		.attr('width', width / 3)
		.attr('transform', `translate(${(width / 3) * 2}, 0)`);

		gSIG.append('line')
		.attr('x1', 0)
		.attr('x2', width / 3)
		.attr('y1', 1)
		.attr('y2', 1)
		.attr('shape-rendering', 'optimizeSpeed')
		.attr('stroke-width', 1)
		.attr('stroke', '#3ab3db');

	 	// cpu
		const area_cpu = this.d3.area()
		.x(function( d: any, i: any ) {
			return i * ((width / 3) / 9);
		})
		.y0(function( d: any ) {
			return  height - Math.floor((d.cpu / 100) * height);
		})
		.y1(function( d: any ) {
			return height;
		});

		const line_cpu = this.d3.line()
		.x(function( d: any, i: any ) {
			return i * ((width / 3) / 9);
		})
		.y(function( d: any ) {
			return height - Math.floor((d.cpu / 100) * height);
		});

		gCPU.append('path')
		.datum(data)
		.attr('fill', 'none')
		.attr('stroke', 'red')
		.attr('stroke-linejoin', 'round')
		.attr('stroke-linecap', 'round')
		.attr('stroke-width', 1)
		.attr('d', line_cpu);

		gCPU.append('path')
		.datum(data)
		.attr('fill', '#ee2c43')
		.attr('opacity', .5)
		.attr('d', area_cpu);

		// ram
		const area_ram = this.d3.area()
		.x(function( d: any, i: any ) {
			return i * ((width / 3) / 9);
		})
		.y0(function( d: any ) {
			return  height - Math.floor((d.ram / 100) * height);
		})
		.y1(function( d: any ) {
			return height;
		});

		const line_ram = this.d3.line()
		.x(function( d: any, i: any ) {
			return i * ((width / 3) / 9);
		})
		.y(function( d: any ) {
			return height - Math.floor((d.ram / 100) * height);
		});

		gMEM.append('path')
		.datum(data)
		.attr('fill', 'none')
		.attr('stroke', '#44a461')
		.attr('stroke-linejoin', 'round')
		.attr('stroke-linecap', 'round')
		.attr('stroke-width', 1)
		.attr('d', line_ram);

		gMEM.append('path')
		.datum(data)
		.attr('fill', '#44a461')
		.attr('opacity', .5)
		.attr('d', area_ram);

		// signal
		const area_sig = this.d3.area()
		.x(function( d: any, i: any ) {
			return i * ((width / 3) / 9);
		})
		.y0(function( d: any ) {
			return  height - Math.floor((d.signal / 100) * height);
		})
		.y1(function( d: any ) {
			return height;
		});

		const line_sig = this.d3.line()
		.x(function( d: any, i: any ) {
			return i * ((width / 3) / 9);
		})
		.y(function( d: any ) {
			return height - Math.floor((d.signal / 100) * height);
		});

		gSIG.append('path')
		.datum(data)
		.attr('fill', 'none')
		.attr('stroke', '#3ab3db')
		.attr('stroke-linejoin', 'round')
		.attr('stroke-linecap', 'round')
		.attr('stroke-width', 1)
		.attr('d', line_sig);

		gSIG.append('path')
		.datum(data)
		.attr('fill', '#3ab3db')
		.attr('opacity', .5)
		.attr('d', area_sig);

		gCPU.append('text')
    .attr('x', 5)
    .attr('y', 15)
    .text('CPU: ' + data[data.length - 1].cpu  + '%');

		gMEM.append('text')
    .attr('x', 5)
    .attr('y', 15)
    .text('RAM: ' + data[data.length - 1].ram  + '%');

		gSIG.append('text')
    .attr('x', 5)
    .attr('y', 15)
    .text('Signal: ' + data[data.length - 1].signal + '%');

	}

  ngOnInit() {
		//const ref = this;
		//this.index = 0;

		//this.intervalRef = setInterval(() => {
		//	for (let i = ref.index; i < ref.deviceObject.log.device.length; i++, ref.index++) {
		//		const p = document.createElement('P');
		//		const log = document.createTextNode(ref.deviceObject.log.device[i]);
		//		p.appendChild(log);
		//		ref.deviceLog.nativeElement.appendChild(p);

        //if (log) {
          //this.scrollDeviceLogs();
        //}
		//	}
		//	//this.buildMetrics();
		//}, 3000);
  }

  ngOnDestroy() {
    // clearInterval(this.intervalRef);
  }

  public ngAfterViewInit(): void {
    this.appiumLogs.changes.subscribe(() => this.scrollAppiumLogs());
    // this.deviceLogs.changes.subscribe(() => this.scrollDeviceLogs());
  }

  public setLocked(): void {
    this.locked = !this.locked;
    this.lockIcon = this.locked ? 'lock' : 'lock_open';

    this.scrollAppiumLogs();
    this.scrollDeviceLogs();
  }

  public scrollLogs(): void {
    this.scrollAppiumLogs();
    this.scrollDeviceLogs();
  }

  private scrollAppiumLogs(): void {
    if (this.locked) {
      const appiumLogsContainer = this.appiumLogsContainer.nativeElement as HTMLElement;
      appiumLogsContainer.scrollTop = appiumLogsContainer.scrollHeight;
    }
  }

  private scrollDeviceLogs(): void {
    if (this.locked) {
      const deviceLogsContainer = this.deviceLogsContainer.nativeElement as HTMLElement;
      deviceLogsContainer.scrollTop = deviceLogsContainer.scrollHeight;
    }
  }

  public trackByFn(index, log) {
    return index;
  }
}
