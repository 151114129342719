import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Router, ActivatedRoute, Params, NavigationExtras, ParamMap } from '@angular/router';
import { ReportsService } from '../../reports/reports.service';
import { DevicesService, DeviceFilter } from '../../../devices/devices.service';
import { ReportUtilService } from '../../reports/report-detail/report-util.service';
import { $Token } from '../../../providers';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

	report: any;
	reportEntries: any;
	stepList: any[] = [];
	stepIndex = 0;
	paused: Boolean = false;
	movieInterval: any;
	@ViewChild('gallery') gallery: ElementRef;

  constructor(
		public dialogRef: MatDialogRef<PreviewComponent>,
		private reportsService: ReportsService,
		private devicesService: DevicesService,
		public reportUtilservice: ReportUtilService,
		private router: Router,
		@Inject($Token) private $: any,
		@Inject(MAT_DIALOG_DATA) public data: {
		report: any,
		reportName: string,
		executionId: any
	}) { }

  ngOnInit() {
		this.loadReport();
  }

	getVisibility(i) {
		return this.stepIndex === i;
	}

	play() {
		this.movieInterval = setInterval(() => {
			if (this.stepIndex < this.stepList.length - 1) {
				this.stepIndex++;
			} else {
				this.stepIndex = 0;
			}
		}, 300);
		this.paused = false;
	}

	pause() {
		this.paused = true;
		clearInterval(this.movieInterval);
	}

	next() {
		if ((this.stepIndex + 1) > this.stepList.length - 1) {
			this.setStep(0);
		}else {
			this.setStep(this.stepIndex + 1);
		}

	}

	back() {
		if ((this.stepIndex - 1) < 0) {
			this.setStep(this.stepList.length - 1);
		}else {
			this.setStep(this.stepIndex - 1);
		}
	}

	setStep(i) {
		this.pause();
		this.stepIndex = i;
	}

	filterSession(reports) {
		const ref = this;
		reports = reports.filter((r) => {
			if (r.children) {
				r.children = ref.filterSession(r.children);
			}

			if (r.value) {
				return this.reportUtilservice.labelFor(r) !== 'Session';
			} else {
				return true;
			}
		});

		return reports;
	}

	flatSteps(list, name) {
		list.forEach((el) => {
			if (el.children) {
				this.flatSteps(el.children, el.name);
			} else {
				el.name = name;
				this.stepList.push(el);
			}
		});
	}

	stepTitle(step: any) {
		if (!step) {
			return;
		}

		let desc;
		if (step.action === 'APPIUM') {
			desc = this.reportUtilservice.labelFor(step);
		} else {
			desc = (step.description || step.action);
		}
		return desc;
	}

	seeFullReport() {
		this.router.navigate(['/report-suites', this.data.executionId, this.report.device._id, this.report._id]);
		this.dialogRef.close();
	}

	loadReport() {
		this.reportsService.view(this.data.report.report).subscribe((result: any) => {
			this.report = result.json();
			this.report.device = this.devicesService.devices.find((device: any) => device._id === this.report.device);

			this.reportsService.detail(this.report).subscribe((detailsResult: any) => {
				this.reportEntries = detailsResult.json();
				this.reportEntries = this.filterSession(this.reportEntries);
				this.flatSteps(this.reportEntries, null);
				console.log(this.stepList);

				this.play();
				/*this.width = this.reportIndexContainer.nativeElement.offsetWidth;
				this.buildChart();
				this.reportIndexContainer.nativeElement.addEventListener('mousewheel', (e: any) => {
					e.preventDefault();
					if (this.reportSVG && this.width + (e.wheelDelta / 10) >= this.reportIndexContainer.nativeElement.offsetWidth) {
						this.reportSVG.remove();
						this.reportSVG = undefined;

						if ( this.metricsSVG ) {
							this.metricsSVG.remove();
						}
						this.metricsSVG = undefined;

						this.width += (e.wheelDelta / 10);
						this.buildChart();
					}
				}, false);*/
			});
		}, (error: any) => {
			// TODO
			// this.errorMessage = error.json().message;
		});
	}
}
