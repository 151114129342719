import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilModule } from '../util/util.module';
import { TreeModule } from 'angular-tree-component';
import { WordPipe } from './word.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { StageComponent } from './stage/stage.component';
import { ToolboxComponent } from './toolbox/toolbox.component';
import { TooltabComponent } from './tooltab/tooltab.component';
import { ConnectComponent } from './connect.component';
import { ConnectDialogComponent } from './connect-dialog/connect-dialog.component';
import { SuiteDialogComponent } from './suite-dialog/suite-dialog.component';

import { DndModule } from 'ng2-dnd';
import { AceEditorModule } from 'ng2-ace-editor';

import { AppsService } from '../apps/apps.service';
import { TestsService} from '../tests/tests.service';
import { AndroidKeyCode } from './androidKeycode.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatToolbarModule} from '@angular/material/toolbar';
import {
	MatButtonModule,
	MatCheckboxModule,
	MatRadioModule,
	MatInputModule,
	MatMenuModule,
	MatSidenavModule,
	MatIconModule,
	MatSnackBarModule,
	MatTooltipModule,
	MatProgressBarModule,
	MatTabsModule,
	MatTableModule,
	MatSelectModule,
} from '@angular/material';

import { InspectorComponent } from './tooltab/inspector/inspector.component';
import { IOSInspectorComponent } from './tooltab/inspector/ios/ios-inspector.component';
import { AppsComponent } from './tooltab/apps/apps.component';
import { LogsComponent } from './tooltab/logs/logs.component';
import { SideComponent } from './test/side/side.component';
import { BottomComponent } from './test/bottom/bottom.component';
import { TestComponent } from './test/test.component';
import { DelayComponent } from './test/dialogs/delay/delay.component';
import { ScriptComponent } from './test/dialogs/script/script.component';
import { KeyboardComponent } from './test/dialogs/keyboard/keyboard.component';
import { FolderComponent } from './test/dialogs/folder/folder.component';
import { SharedModule } from './../shared.module';
import { TouchComponent } from './test/dialogs/touch/touch.component';
import { ValidateComponent } from './test/dialogs/validate/validate.component';
import { ImportDialogComponent } from './test/side/import-dialog/import-dialog.component';
import { FullScreenDialogComponent } from './fullscreen-dialog/fullscreen-dialog.component';

@NgModule({
  imports: [
	SharedModule,
    CommonModule,
	MatButtonModule,
	MatCheckboxModule,
	MatRadioModule,
	MatInputModule,
	MatMenuModule,
	MatSidenavModule,
	DragDropModule,
	MatIconModule,
	MatSnackBarModule,
	MatTooltipModule,
	MatTabsModule,
	MatToolbarModule,
	MatProgressBarModule,
	MatTableModule,
	MatSelectModule,
	TreeModule.forRoot(),
	UtilModule,
	MatExpansionModule,
	DndModule.forRoot(),
	AceEditorModule,
	FormsModule,
	ReactiveFormsModule,
  ],
  declarations: [
		StageComponent,
		ToolboxComponent,
		TooltabComponent,
		ConnectComponent,
		ConnectDialogComponent,
		FullScreenDialogComponent,
		SuiteDialogComponent,
		InspectorComponent,
		IOSInspectorComponent,
		AppsComponent,
		LogsComponent,
		SideComponent,
		BottomComponent,
		TestComponent,
		DelayComponent,
		ScriptComponent,
		KeyboardComponent,
		FolderComponent,
		WordPipe,
		TouchComponent,
		ValidateComponent,
		ImportDialogComponent,
	],
	entryComponents: [
		DelayComponent,
		ScriptComponent,
		KeyboardComponent,
		ConnectDialogComponent,
		FullScreenDialogComponent,
		FolderComponent,
		SuiteDialogComponent,
		TouchComponent,
		ValidateComponent,
		ImportDialogComponent,
	],
	providers: [
		AppsService,
		TestsService,
		AndroidKeyCode
	],
})
export class ConnectModule { }
