import { Component, OnInit,   ViewChild, ViewChildren,  ElementRef, QueryList } from '@angular/core';
import { TestsService, TestCase, TestSuite, TestStep, TestFolder } from '../tests/tests.service';
import { RunDialogComponent } from './run-dialog/run-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { TestcaseDialogComponent } from './testcase-dialog/testcase-dialog.component';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-test-suites',
  templateUrl: './test-suites.component.html',
  styleUrls: ['./test-suites.component.scss']
})
export class TestSuitesComponent implements OnInit {

	testSuites: TestSuite[];
	testCases: TestCase[];
	rootSuites: any[];
	nRootSuites: any[];
	rootCases: any[];
	suitesFilter: String = '';
	casesFilter: String = '';
	root: any;
	activeDirectory: any;
	path = [];
	shortPath = [];
	show: String = 'suites';
	// @ViewChildren('fileBox') files: QueryList<ElementRef>;
	dndOrigin: any;
	dndDestiny: any;
	showDragUp: Boolean = false;
	showDragUpTimeout: any;
	runAllList: any[];

  	constructor(
		private testsService: TestsService,
		private dialog: MatDialog,
		private toastr: ToastrService,
		private router: Router,
	) { }

	run(file: any) {
		this.dialog.open(RunDialogComponent, {
      width: '800px',
      data: {
				testSuite: file.testSuite,
				path: this.path.map((p) => p.name).join('/')
      }
    });
	}

	runAll(file){
		this.runAllList = [];
		let copyPath = [...this.path];
		copyPath.push({rawIndex: file.rawIndex, name: file.value});
		this.extractSuitesFrom(file, copyPath)
		this.testsService.getShortInfo(this.runAllList.map(d => d._id))
		.subscribe(async (result) => {
			let resp = await result.json();
			
			this.dialog.open(RunDialogComponent, {
				width: '800px',
				data: {
					testSuites: resp,
					paths: this.runAllList
				}
			});

		}, (error) => {
			console.error(error);
		});
	}

	extractSuitesFrom(folder, path){
		//Rever estrutura de pastas
		folder.tree.sort((a, b) => a.type > b.type ? -1 : 1);
		console.log(folder.tree)
		folder.tree.forEach(el => {
			if(el.type == 'test-suite'){
				this.runAllList.push({
					_id: el.value,
					path: path.map((p) => p.name).join('/')
				})
			}else{
				path.push({rawIndex: el.rawIndex, name: el.value});
				this.extractSuitesFrom(el, path)
			}
		})
	}

	trackBreadCumb(index: number, el: any) {
		el.index = index;
		return el;
	}

	trackDirectory(index: number, file: any) {
		file.index = index;
		return file;
	}

	openTestCaseDetails(file: any) {
		console.log(file);
		this.dialog.open(TestcaseDialogComponent, {
			width: '800px',
			data: {
				testCase: file.testCase,
			}
		});
	}

	copyID(file: any){
		let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = file.value;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
			document.body.removeChild(selBox);
			
			this.toastr.success('Copiado!');
	}

	edit(file: any) {

		if (file.type === 'folder') {
			const name = prompt('Digite o novo nome para a pasta', file.value);

			if (name) {
				this.getRawDirectory().tree[file.rawIndex].value = name;
				this.syncTree();
			}

		} else {
			this.router.navigate([`/testsuite`], {queryParams: {id: file.testSuite._id}});
		}
	}

	openFolder(folder: any) {
		this.path.push({rawIndex: folder.rawIndex, name: folder.value});
		this.shortPath = this.path.slice(-4);
		this.activeDirectory = this.resolvePath();
	}
	// openFolder(folder: any) {
	// 	this.testsService.populateFolder(folder).subscribe(async (result) => {
	// 		this.path.push({rawIndex: folder.rawIndex, name: folder.name});
	// 		this.shortPath = this.path.slice(-4);
	// 		folder = await result.json();
			
	// 		if (this.path.length > 0) {
	// 			this.activeDirectory.tree[folder.rawIndex] = folder
	// 		}
			
	// 		this.activeDirectory = this.resolvePath();
	// 	});
	// }

	removeElementAndSave(el) {
		this.getRawDirectory().tree.splice(el.rawIndex, 1);
		this.syncTree();
	}

	getRawParentDirectory() {
		const path = [];

		let directory: any = {
			name: '/',
			tree: this.root,
		};

		if (this.path.length > 0) {

			for (let i = 0; i < this.path.length - 1; i++) {
				path.push(this.path[i]);
			}

			path.forEach((f) => {
				directory = directory.tree[f.rawIndex];
			});
		}

		return directory;
	}

	getRawDirectory(): any {
		let directory: any = {
			name: '/',
			tree: this.root,
		};

		if (this.path.length > 0) {
			this.path.forEach((f) => {
				directory = directory.tree[f.rawIndex];
			});
		}

		return directory;
	}

	syncTree() {
		if (this.show === 'suites') {
			this.testsService.updateSuiteTree(this.rootSuites).subscribe((response) => {
				this.getSuites();
			}, (error) => {
				console.error(error);
			});
		} else {
			this.testsService.updateCaseTree(this.rootCases).subscribe((response) => {
				this.getCases();
			}, (error) => {
				console.error(error);
			});
		}
	}

	addElementAndSave(el) {
		this.getRawDirectory().tree.push(el);
		this.syncTree();
	}

	createFolder() {
		const name = prompt(`Insira o nome da pasta`);

		if (name) {
			this.addElementAndSave({
				type: 'folder',
				value: name,
				tree: [],
			});
		}
	}

	sortDir(a, b) {
		if (a.type === 'folder' && b.type === 'test-suite') {
			return -1;
		}

		if (a.type === 'test-suite' && b.type === 'folder') {
			return 1;
		}

		if (a.type === 'folder' && b.type === 'folder') {
			// if (a.name > b.name) {
			if (a.value > b.value) {
				return 1;
			}

			// if (a.name < b.name) {
			if (a.value < b.value) {
				return -1;
			}

			return 0;
		}

		if (a.type === 'test-suite' && b.type === 'test-suite') {
			// if (a.suite.name > b.suite.name) {
			if (a.testSuite.name > b.testSuite.name) {
				return 1;
			}

			// if (a.suite.name < b.suite.name) {
			if (a.testSuite.name < b.testSuite.name) {
				return -1;
			}

			return 0;
		}

	  return 0;
	}

	back(count) {
		console.log(this.path)
		if (count !== undefined) {
			this.path = this.path.slice(0, count);
		} else {
			this.path.splice(-1);
		}
		
		this.shortPath = this.path.slice(-4);
		this.activeDirectory = this.resolvePath();
	}

	resolvePath() {
		let directory: any = {
			name: '/',
			tree: JSON.parse(JSON.stringify(this.root)),
		};

		if (this.path.length > 0) {
			this.path.forEach((f) => {
				directory = directory.tree[f.rawIndex];
			});
		}

		let index = 0;

		if (this.show === 'suites') {
			directory.tree.forEach((f) => {
				// f.suite = this.testSuites.find((ts) => ts._id === f.suite);
				f.testSuite = this.testSuites.find((ts) => ts._id === f.value);
				f.rawIndex = index++;
			});

			directory.tree = directory.tree.filter((f) => {
				// if (f.type === 'folder' && f.name) {
				// 	return f.name.toLowerCase().includes(this.suitesFilter.toLowerCase());
				// } else {
				// 	return f.suite.name.toLowerCase().includes(this.suitesFilter.toLowerCase());
				// }
				if (f.type === 'folder' && f.value) {
					return f.value.toLowerCase().includes(this.suitesFilter.toLowerCase());
				} else {
					return f.testSuite.name.toLowerCase().includes(this.suitesFilter.toLowerCase());
				}
			});
		} else {
			directory.tree.forEach((f) => {
				f.testCase = this.testCases.find((ts) => ts._id === f.value);
				f.rawIndex = index++;
			});

			directory.tree = directory.tree.filter((f) => {
				if (f.type === 'folder' && f.value) {
					return f.value.toLowerCase().includes(this.casesFilter.toLowerCase());
				} else {
					if(!f.testCase.name){
						f.testCase.name = '';
					}
					return f.testCase.name.toLowerCase().includes(this.casesFilter.toLowerCase());
				}
			});
		}

		directory.tree.sort(this.sortDir);

		return directory;
	}

	create() {
		const name = prompt(`Insira o nome do elemento a ser criado:`);

		if (name) {

			if (this.show === 'suites') {
				this.testsService.duplicateSuite({name: name}).subscribe((result) => {
					const newSuite = result.json();
					this.addElementAndSave({
						type: 'test-suite',
						value: newSuite._id,
					});
				}, (error) => {
					console.error(error);
				});
			} else {
				this.testsService.duplicateCase({name: name}).subscribe((result) => {
					const newCase = result.json();
					this.addElementAndSave({
						type: 'test-case',
						value: newCase._id,
					});
				}, (error) => {
					console.error(error);
				});
			}
		}else{
			alert(`Insira um nome para o ${this.show}.`);
		}
	}

	allowDrop(ev) {
    ev.preventDefault();
	}

	dragend(ev) {
		ev.preventDefault();
		this.showDragUp = false;
		clearTimeout(this.showDragUpTimeout);
	}

	drag(ev, file) {
		if (this.path.length > 0) {
			this.showDragUpTimeout = setTimeout(() => {
				this.showDragUp = true;
			}, 1000);
		}
		this.dndOrigin = file;
    // ev.dataTransfer.setData("text", ev.target.id);
	}

	dropParent(ev) {
		ev.preventDefault();
		this.getRawParentDirectory().tree.push(this.dndOrigin);
		this.getRawDirectory().tree.splice(this.dndOrigin.rawIndex, 1);
		this.syncTree();
	}

	drop(ev, file) {
    ev.preventDefault();
		this.dndDestiny = file;

		if (this.dndDestiny && this.dndDestiny.type === 'folder' && this.dndDestiny.rawIndex !== this.dndOrigin.rawIndex) {

			if (this.dndOrigin.type === 'test-suite') {
					this.getRawDirectory().tree[this.dndDestiny.rawIndex].tree.push({
						type: 'test-suite',
						value: this.dndOrigin.testSuite._id,
					});
			} else if(this.dndOrigin.type === 'test-case'){
					this.getRawDirectory().tree[this.dndDestiny.rawIndex].tree.push({
						type: 'test-case',
						value: this.dndOrigin.testCase._id
					});
			}

			this.getRawDirectory().tree.splice(this.dndOrigin.rawIndex, 1);
			this.syncTree();
		}
    // var data = ev.dataTransfer.getData("text");
    // ev.target.appendChild(document.getElementById(data));
	}

	duplicateFolder(file){
		console.log(file)
	}

	exportCode(file: any){
		this.testsService.exportCode(file.testSuite._id).subscribe(async resp => {
			let data = await resp.json();
			this.toastr.success('Script gerado!');
			
			setTimeout(() => {
				window.open(data.url, "_blank");
			}, 2000)
		})
	}

	duplicate(file: any) {
		let name = '';
		let copy = {_id:'', name: ''};

		if (this.show === 'suites') {
				name = prompt('Digite o nome da suíte de teste:', file.testSuite.name + ' (copy)');
				copy = {...file.testSuite};
				if (name) {
					delete copy._id;
					copy.name = name;

					this.testsService.duplicateSuite(copy).subscribe((result) => {
						const newSuite = result.json();
						this.addElementAndSave({
							type: 'test-suite',
							value: newSuite._id,
							//testSuite: newSuite,
						});
					}, (error) => {
						console.error(error);
					});
				}
		} else {
				name = prompt('Digite o nome da suíte de teste:', file.testCase.name + ' (copy)');
				copy = {...file.testCase};
				if (name) {
					delete copy._id;
					copy.name = name;

					this.testsService.duplicateCase(copy).subscribe((result) => {
						const newCase = result.json();
						this.addElementAndSave({
							type: 'test-case',
							value: newCase._id,
							//testCase: newCase,
						});
					}, (error) => {
						console.error(error);
					});
				}
		}
	}

	delete(file: any) {

		if (file.type === 'folder') {

			if (file.tree.length > 0) {
				alert('A pasta contém elementos internos, mova-os ou remova-os antes de exclui-la.');
				return;
			}

			this.getRawDirectory().tree.splice(file.rawIndex, 1);
			this.syncTree();
			return;
		}

		if (this.show === 'suites') {
			const confirmation = confirm(`Deseja deletar a Suite '${file.testSuite.name}'?`);
			if (confirmation) {
				this.testsService.deleteSuite(file.testSuite._id).subscribe((result) => {
					this.removeElementAndSave(file);
				}, (error) => {
					console.error(error);
				});
			}
		} else {
			const confirmation = confirm(`Deseja deletar o caso de teste '${file.testCase.name}'?`);
			if (confirmation) {
				this.testsService.deleteCase(file.testCase._id).subscribe((result) => {
					this.removeElementAndSave(file);
				}, (error) => {
					if (error.status === 409) {
						const pendingTestSuites = error.json();
						//
						this.dialog.open(DeleteDialogComponent, {
							width: '800px',
							data: {
								testSuites: pendingTestSuites
							}
						});
					} else {
						console.error(error);
					}
				});
			}
		}
	}

	getCasesName(testCases){
		return testCases
		.reduce((prev, curr) => prev+curr.name+', ' ,'')
		.slice(0,-2);
	}

	getVars(variables){
		return variables
		.reduce((prev, curr) => prev+curr.name+':'+curr.value+', ', '')
		.slice(0,-2);
	}

	getSuites() {
		console.log('GetSuites')
		this.testsService.getSuiteTree().subscribe(async (treeResult) => {
			this.rootSuites = await treeResult.json().root;
			//this.nRootSuites = await treeResult.json().nRoot;

			this.testsService.listTestSuites().subscribe(async (testSuitesResult) => {
				this.testSuites = await testSuitesResult.json();
				this.activeDirectory = this.resolvePath();
			}, (error) => {
				console.error(error);
			});

			this.root = this.rootSuites;
		}, (error) => {
			console.error(error);
		});
	}

	getCases() {
		console.log('mudo pra cases')
		this.testsService.getCaseTree().subscribe((treeResult) => {
			this.rootCases = treeResult.json().root;
			this.testsService.listTestCases().subscribe((testCasesResult) => {
				this.testCases = testCasesResult.json();
				this.activeDirectory = this.resolvePath();
			}, (error) => {
				console.error(error);
			});

			this.root = this.rootCases;
		}, (error) => {
			console.error(error);
		});
	}

	changeTab(e){
		this.path = [];
	
		if(e.index == 0){
			this.show = 'suites';
			this.getSuites();
		}else{
			this.show = 'cases';
			this.getCases();
		}
	}

	ngOnInit() {
		this.getSuites();
	}
}
