import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DeviceAccessLogService } from "../../deviceAccessLog/device-access-log.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'app-report-session',
    template: ''
})
export class ReportBySessionComponent implements OnInit {
    
	sessionId: any = this.route.snapshot.params.sessionId;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private logService: DeviceAccessLogService,
		private toastr: ToastrService
    ) {}
    
    ngOnInit(): void {
        this.logService.getReport(this.sessionId).subscribe(async resp => {
            let data = await resp.json();

            if(data){
                this.router.navigate([`/report-suites/${data.execution}/${data.device}/${data._id}`]);
            }else{
                this.toastr.warning('Relatório não encontrado!', '', {closeButton: true});
            }
        });
    }

}