import {Component, Inject} from '@angular/core';
import {MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import {Log} from './log';

@Component({
  templateUrl: 'logs-dialog.component.html',
  styleUrls: ['logs-dialog.component.scss']
})
export class LogsDialogComponent {
  public title = 'Device';
  public dataSource: MatTableDataSource<Log> = new MatTableDataSource();
  public displayedColumns: string[] = ['type', 'time', 'pid', 'tid', 'text'];

  private deviceLogFilter: Log = {};

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      isAppium: boolean;
      deviceOS: string;
      deviceLogs: Log[];
    }
  ) {
    this.dataSource = new MatTableDataSource(this.data.deviceLogs);
    this.dataSource.filterPredicate = (deviceLog: Log, filtersAsJSON: string): boolean => {
      const filters: Log = JSON.parse(filtersAsJSON);
      const validList: boolean[] = [];

      for (const key of Object.keys(filters)) {
        if (typeof deviceLog[key] === 'undefined' || deviceLog[key] === null) {
          deviceLog[key] = '';
        }

        if (key !== 'pid' && key !== 'tid') {
          validList.push((deviceLog[key] as string).includes(filters[key]));

          continue;
        }

        validList.push((deviceLog[key] as string).startsWith(filters[key]));
      }

      // Validates each boolean value in conjunction.
      return validList.every(Boolean);
    };

    this.displayedColumns = [...Object.keys(this.data.deviceLogs[0])];

    if (this.data.isAppium) {
      this.title = 'Appium';
    }
  }

  public filter(text: string, field: 'time' | 'pid' | 'tid' | 'type' | 'text'): void {
    this.deviceLogFilter[field] = text.trim();
    this.dataSource.filter = JSON.stringify(this.deviceLogFilter);
  }

  public getField(field: string): string {
    switch (field) {
      case 'time':
        return 'data/hora';

      case 'pid':
        return 'PID';

      case 'tid':
        return 'TID';

      case 'type':
        return 'tipo';

      case 'text':
        return 'texto';

      case 'process':
        return 'processo';

      case 'name':
        return 'nome';
    }

    return '';
  }

  public trackByFn(index: number, row: any): number {
    return index;
  }
}
