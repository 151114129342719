import { Input, Component, OnInit, Inject, OnDestroy, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AccountsService } from './../accounts.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SelectListDialogComponent } from '../../pending-devices/edit-device-dialog/select-list-dialog/select-list.component';

@Component({
  selector: 'action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent implements OnInit, OnDestroy, AfterViewChecked {

	@Input() elementDialog: any;
	actionForm: FormGroup;
    actionList: any[];
    action:any;

    constructor(
		private toastr: ToastrService,
		private dialog: MatDialog, 
        private changeDetector: ChangeDetectorRef,
        private accService: AccountsService, 
        public dialogRef: MatDialogRef<ActionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any    
	) {
	}
    ngAfterViewChecked(): void {
        
    }
    ngOnDestroy(): void {
        this.changeDetector.detach();
    }

	ngOnInit() {
        this.action = {
            command: '',
            device: {alias: '', deviceHub: {name: ''}, model: {name:''}, OS:{platform:'',version:0},serial:''}
        };
        
        this.actionList = [{name: 'Reboot'},{name:'Reinstall'}];

		this.actionForm = new FormGroup({
		 	cmd: new FormControl({value: this.action.command}, Validators.required),
            alias: new FormControl({value: this.action.device.alias}, Validators.required),
        });
    }

    listDevices(){
        let dRef = this.dialog.open(SelectListDialogComponent, {
            width: '400px',
            height: '400px',
            data: {
                type: 'devices',
                list: this.data.devices,
                device: this.action.device
            }
        })

        dRef.afterClosed().subscribe((result) => {
            this.action.device = result;
        });
    }

    listActions(){
        let dRef = this.dialog.open(SelectListDialogComponent, {
            width: '400px',
            height: '400px',
            data: {
                type: 'action',
                list: this.actionList,
                action: this.action.command
            }
        })

        dRef.afterClosed().subscribe((result) => {
            this.action.command = result.name;
        });
    }

    saveAction(){
        this.accService.createAction(this.action).subscribe(async resp => {
            let data = await resp.json();
            console.log(data);

            //toast here
            this.toastr.success('Comando adicionado a fila!');

            return this.dialogRef.close(this.action);
        });
    }
}
