import {  ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy, Inject, ViewChild, asNativeElements, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AccountsService } from './../accounts.service';
import { FormControl, Validators, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { UploadComponent } from '../../../util/upload/upload.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'edit-team-dialog',
  templateUrl: './edit-team-dialog.component.html',
  styleUrls: ['./edit-team-dialog.component.scss']
})
export class EditTeamDialogComponent implements OnInit, OnDestroy {

	@Input() elementDialog: any;
	@Input() deviceList = [] as any[];
	teamForm: FormGroup;
	respError = {message: '', type: ''};
	teamSub:any;
	accFilter:any;
	devFilter:any;
	isLoading: boolean = false;

    constructor(
		private router: Router,
		private changeDetector: ChangeDetectorRef,
		private zone: NgZone,
		private toastr: ToastrService,
		private dialog: MatDialog, 
		private accService: AccountsService,
		public dialogRef: MatDialogRef<EditTeamDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any        
	) {
	}

	ngOnInit() {
		console.log(this.data)
		if(!this.data.team){
			this.data.team = {
				name: '',
				allowedUsers: [],
				allowedDevices: []
			}
		}

		this.teamForm = new FormGroup({
		 	name: new FormControl({value: this.data.team.name}, Validators.required),
		 	accFilter: new FormControl({value: ''}),
		 	devFilter: new FormControl({value: ''}),
        });
    }

    saveTeam(){
		if(this.teamForm.valid){
			this.isLoading = true;

			var editableTeam = {
				_id:'',
				apiKey:'',
				name:'',
				company:'',
				widgets:[],
				creationDate: '',
				allowedDevices:[],
				allowedUsers:[]
			};

			editableTeam._id = this.data.team._id;
			editableTeam.apiKey = this.data.team.apiKey;
			editableTeam.name = this.data.team.name;
			editableTeam.company = this.data.team.company;
			editableTeam.widgets = this.data.team.widgets;
			editableTeam.creationDate = this.data.team.creationDate;
			editableTeam.allowedDevices = this.data.team.allowedDevices.map(d => d._id);
			editableTeam.allowedUsers = this.data.team.allowedUsers.map(u => u._id);


			this.teamSub = this.accService.saveTeam(editableTeam).subscribe(resp => {
				this.toastr.success('Dados Atualizados!', '', {closeButton: true});

				this.data.team = editableTeam;
				this.isLoading = false;

				this.dialogRef.close()
			}, error => {
				this.toastr.error('Erro ao criar Time, Nome em uso.')
			});
		}
	}
	
	addDev(device){
		this.data.team.allowedDevices.push(device);
	}
	
	removeDev(i){
		this.data.team.allowedDevices.splice(i,1);
	}

	addAcc(acc){
		this.data.team.allowedUsers.push(acc);
	}
	
	removeAcc(i){
		this.data.team.allowedUsers.splice(i,1);
	}

	showDevice(device){
		return this.data.team.allowedDevices.map(d => d._id).includes(device._id) ? false : true;
	}

	showAcc(acc){
		return this.data.team.allowedUsers.map(d => d._id).includes(acc._id) ? false : true;
	}

	ngOnDestroy() {
		if(this.teamSub) this.teamSub.unsubscribe();
		this.changeDetector.detach();
	}
}
