import { Injectable } from '@angular/core';

@Injectable()
export class LogoutService {
  public doLogout(): void {
    localStorage.removeItem('Auth-Token');
    sessionStorage.removeItem('Auth-Token');
    location.reload();
  }
}
