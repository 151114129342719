import {
	Component,
	ViewChildren,
	ViewChild,
	Inject,
	ChangeDetectorRef,
	ElementRef,
	QueryList,
	OnInit,
	AfterViewChecked,
} from '@angular/core';
import { DevicesService } from '../devices/devices.service';
import { ConnectService } from './connect.service';
import { windowToken, wsocketToken } from '../providers';
import { IStageInterface } from './stage/stage.interface';
import { Router, ActivatedRoute, Params, NavigationExtras, ParamMap } from '@angular/router';
import { TestComponent } from './test/test.component';
import { MatDialog } from '@angular/material';
import { SuiteDialogComponent } from './suite-dialog/suite-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';
import { FullScreenDialogComponent } from './fullscreen-dialog/fullscreen-dialog.component';
import { LoginService } from '../login/login.service';

declare var io: any;

@Component({
	selector: 'app-connect',
	templateUrl: './connect.component.html',
	styleUrls: ['./connect.component.scss']
})
export class ConnectComponent implements OnInit, AfterViewChecked {
	@ViewChild('canvas') canvas: ElementRef;
	@ViewChildren('stage') stage: QueryList<IStageInterface>;
	@ViewChildren('stageContainer') stageContainer: QueryList<ElementRef>;
	@ViewChildren('connectModal') connectModal: ElementRef;
	@ViewChild('test') test: TestComponent;

	tc = [];
	player: HTMLAudioElement;
	isPlaying: boolean;
	logcatActiv: boolean;
	audioArrBuffer: any = [];
	isMuted: boolean = true;
	audioSocket: any;
	audioLastTime = 0;
	showButtonsFlag = false;

	constructor(
		private deviceService: DevicesService,
		public connectService: ConnectService,
		private changeDetector: ChangeDetectorRef,
		private appService: AppService,
		private loginService: LoginService,
		private route: ActivatedRoute,
		private host: ElementRef,
		private toastr: ToastrService,
		@Inject(windowToken) private window: any,
		@Inject(wsocketToken) private wsocket: any,
		private matDialog: MatDialog,
	) {}


	newTestSuite() {
		this.test.newTestSuite();
	}

	closeIcon(deviceObject: any) {
		const stage: IStageInterface = this.stageFor(deviceObject);
		if (!stage) {
			return 'close';
		}

		if (stage.showInspector || stage.showApps || stage.showLogs) {
			return 'keyboard_backspace';
		} else {
			return 'close';
		}
	}

	toogleCollapse(){
		this.showButtonsFlag = !this.showButtonsFlag;
	}

	updateLastTime() {
		this.audioLastTime = this.player.duration;
	}

	updatePlayerTime(){
		this.player.currentTime = this.audioLastTime;
	} 

	loadSound(){
		let blob = new Blob([...this.audioArrBuffer], { type: 'audio/wav' });
		let url = URL.createObjectURL(blob);
		this.player.src = url;
	}

	getSound(mute, deviceObject) {
		const actionRequestId = this.appService.uuid();
		this.isMuted = mute;
		const ref = this;

		if(this.isPlaying && !mute){
			ref.toastr.info('Já tem um device com audio aberto.', 'Opa!', {closeButton: true});
			return
		}
		
		if(!ref.audioSocket){
			ref.audioSocket = io(`/${deviceObject.device._id}`);
			
			ref.audioSocket.on('audio', (buffer: any) => {				
				// validar quando receber audio se o active device que esta recebendo ainda esta ativo
				if (this.connectService.activeDevice && deviceObject.device._id !== this.connectService.activeDevice.device._id) {
					return;
				} else {
					ref.audioArrBuffer.push(buffer);
	
					// start caso seja o começo do buffer
					if(!ref.isPlaying){
						ref.isPlaying = true;
	
						ref.loadSound();
					}
				}
			});
		}

		if(mute){
			ref.toastr.info('Fechando audio...', '', {closeButton: true});
			ref.audioArrBuffer = [];
			this.isPlaying = false;
			ref.audioLastTime = 0;
		} else {
			ref.toastr.info('Abrindo audio...', '', {closeButton: true});
		}
			
		ref.connectService.sendAction(
			deviceObject.device._id,
			actionRequestId, 'live', {
				action: 'sound',
				userId: this.loginService.user._id,
				reference: !mute, // o player vai ler como status, por isso !mute
		}).then((result: any) => {
			// console.log(result.data);
			if (result.data.status === 'ERROR') {
				ref.isMuted = true;
				ref.toastr.error('Erro ao iniciar audio.', 'Error!', {closeButton: true});
			}else{
				if(mute){
					ref.toastr.success('Audio fechado.', 'OK!', {closeButton: true});
					ref.audioArrBuffer = [];
					ref.audioLastTime = 0;
					ref.player.pause();
					ref.isPlaying = false;
				} else {
					ref.toastr.success('Audio aberto.', 'Boa!', {closeButton: true});
				}
			}
		});
	}

	getDeviceLog(deviceObject){
		const actionRequestId = this.appService.uuid();
		const ref = this;

		ref.connectService.sendAction(
			deviceObject.device._id,
			actionRequestId, 'live', {
				action: 'logcat',
				apiKey: this.loginService.user.team.apiKey
		}).then((result: any) => {
			// console.log(result.data);
			if (result.data.status === 'ERROR') {
				ref.toastr.error('Log já esta sendo capturado!.', 'Error!', {closeButton: true});
			}else{
				ref.toastr.success('Gravando logs do device.', 'Boa!', {closeButton: true});
				ref.logcatActiv = true;
			}
		});
	}

	shakeDevice(deviceObject) {
		const actionRequestId = this.appService.uuid();
		const ref = this;
				

			// ref.toastr.info('Abrindo audio...', '', {closeButton: true});
					
		ref.connectService.sendAction(
			deviceObject.device._id,
			actionRequestId, 'live', { action: 'shake' })
			.then((result: any) => {
			// console.log(result.data);
			if (result.data.status === 'ERROR') {
				ref.toastr.error('Erro ao executar comando shake.', 'Error!', {closeButton: true});
			}
		});
	}

	disconnect(deviceObject: any, evt = null) {
		const stage: IStageInterface = this.stageFor(deviceObject);
		if (stage.showInspector || stage.showApps || stage.showLogs) {

			if (stage.showInspector) {
				stage.showInspector = false;
				this.connectService.requestScreen(deviceObject.device._id);
			}

			stage.showApps = false;
			stage.showLogs = false;

		} else {
			this.player.pause();
			this.audioArrBuffer = [];
			this.connectService.releaseConnection(deviceObject.device);
		}
	}

	stageFor(deviceObject: any) {
		if (!this.stage) {
			return undefined;
		}
		return this.stage.find((s: IStageInterface) => s.deviceObject === deviceObject);
	}

	toggleInspector(deviceObject: any) {

	}

	openDialog(element: any) {
		element.style.display = 'block';
	}

	openMatDialog() {
		this.matDialog.open(SuiteDialogComponent);
	}

	openFull(deviceObject,element){
		document.documentElement.requestFullscreen();
		this.connectService.fullScreenDevice = deviceObject;
		element.style.display = 'block';
	}

	clearCache(deviceObject){
		let result = prompt(`Qual o 'Package' do app que deseja limpar o cache?`);
		console.log(deviceObject)
		if(result){
			const actionRequestId = this.appService.uuid();
			
			this.connectService.sendAction(
				deviceObject.device._id,
				actionRequestId, 'live', {
					action: 'clearcache',
					reference: result,
			})
			.then((result: any) => {
				// console.log(result.data);
				if (result.data.status === 'ERROR') {
					this.toastr.error('Erro ao limpar aplicativo.', 'Error!', {closeButton: true});
				}else{
					this.toastr.success('App resetado.', 'Boa!', {closeButton: true});
				}
			});
		}
	}

	closeDialog(element: any) {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		}
		element.style.display = 'none';
	}

	getW(side) {
		switch (side) {
			case 'left':
				if (this.test.sideComponent) {
					return '350px';
				} else {
					return '0px';
				}
				break;

			case 'right':
				if (this.test.sideComponent) {
					return 'calc(100% - 350px)';
				} else {
					return '100%';
				}
				break;
		}
	}

	ngAfterViewInit() {
		this.wsocket.on('script-output', (data: any) => {
			console.log(data);
		});
	}

	ngOnInit() {
		this.changeDetector.detectChanges();
		this.player = new Audio();

		let ref = this;

		this.player.addEventListener('ended', () => {
			ref.updateLastTime();
			URL.revokeObjectURL(ref.player.src);
			ref.loadSound();
		})
		
		this.player.addEventListener('loadeddata', () => {
			ref.updatePlayerTime();
			ref.player.play();
		})

		this.player.addEventListener('error', (error) => {
			console.log('error', error)
		})

		this.route
			.queryParamMap
			.subscribe((map: ParamMap) => {
				const deviceId = map.get('device');
				if (deviceId) {
					const device = this.deviceService.devices.find((d) => d._id === deviceId);
					this.connectService.requestConnection(device);
				}
			},
				(error => console.log(error)));

		this.connectService.screenReceiveEvent.subscribe((deviceObject: any) => {
			if (this.connectService.activeDevice && deviceObject.device !== this.connectService.activeDevice.device) {
				return;
			}

			const g = this.canvas.nativeElement.getContext('2d');

			let blob = new Blob([deviceObject.screen], { type: 'image/jpeg' });
			const URL = window.URL || window['webkitURL'];
			let img = new Image();
			let u = URL.createObjectURL(blob);
			const ref = this;

			img.onload = function () {

				ref.canvas.nativeElement.setAttribute('width', img.width);
				ref.canvas.nativeElement.setAttribute('height', img.height);

				g.drawImage(img, 0, 0);
				img.onload = null;
				img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
				img = null;
				URL.revokeObjectURL(u);
				u = null;
				blob = null;
			};

			img.src = u;
		});

		//this.stageContainer.changes.subscribe(() => this.justifyContent());
	}

	screenRotation(deviceObject: any) {
		if(deviceObject.device.OS.platform.toLowerCase() == 'ios'){
			deviceObject.iOSorientation = deviceObject.iOSorientation == 'portrait' ? 'landscape' : 'portrait' ;
		}
		this.connectService.rotateScreen(deviceObject.device);
	}

	recordExploration(deviceObject: any) {
		if (deviceObject.isRecording) {
			this.deviceService.finishLiveReport(deviceObject.livereportId)
				.subscribe(async resp => {
					deviceObject.livereportId = '';
					deviceObject.isRecording = false;
				})

		} else {
			let result = prompt(`Qual será o nome do relatório?`);
			let dt = new Date().toLocaleString('pt-br')
			if (result) {
				this.deviceService.createLiveReport(result, deviceObject.device._id)
					.subscribe(async resp => {
						let data = await resp.json();
						this.toastr.success('Iniciando gravação!');

						deviceObject.livereportId = data.reportId;
						deviceObject.isRecording = true;
					});
			}
		}
	}

	downloadImg() {
		setTimeout(() => {
			let deviceObject = this.connectService.activeDevice;
			let blob = new Blob([deviceObject.screen], { type: 'image/jpeg' });
			const URL = window.URL || window['webkitURL'];
			let u = URL.createObjectURL(blob);

			var element = document.createElement('a');
			element.setAttribute('href', u);
			element.setAttribute('download', `screenshot_${new Date().getTime()}.jpg`);

			element.style.display = 'none';
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
		}, 800);
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	justifyContent() {
		const host = this.host.nativeElement as HTMLElement;
		let justifyContent = 'center';

		if (this.stageContainer.length > 2) {
			justifyContent = 'space-between';
		}

		host.style.justifyContent = justifyContent;
	}
}
