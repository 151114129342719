import { Component,
	OnInit,
	AfterViewInit,
	ViewChild,
	Inject, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, OnDestroy  } from '@angular/core';
import { ConnectService } from '../../../connect.service';
import { AppService } from '../../../../app.service';
import 'brace';
import 'brace/ext/language_tools';
import { wsocketToken } from '../../../../providers';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { TestsService, TestCase, TestSuite, TestStep, TestFolder } from '../../../../tests/tests.service';


@Component({
  selector: 'app-script',
  templateUrl: './script.component.html',
  styleUrls: ['./script.component.scss']
})
export class ScriptComponent implements OnInit, AfterViewInit, OnDestroy {
	
	ngOnDestroy(): void {
		this.connectService.activeEnhancedKeyboard = true;
	}

	@ViewChild('editor') editor;
	@ViewChild('canvas') canvas: ElementRef;

  constructor(
		public connectService: ConnectService,
	 	private appService: AppService,
		@Inject(wsocketToken) private wsocket: any,
		public dialogRef: MatDialogRef<ScriptComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private cd: ChangeDetectorRef
 ) { }

	options: any = {
    enableBasicAutocompletion: true,
    enableSnippets: true,
    enableLiveAutocompletion: true,
  };

	code: String = '';
	output: String[] = [];

	testSuite: TestSuite;

  ngOnInit() {
		this.connectService.activeEnhancedKeyboard = false;		
		this.cd.detectChanges();

		this.connectService.screenReceiveEvent.subscribe((deviceObject: any) => {
			if (this.connectService.activeDevice && deviceObject.device !== this.connectService.activeDevice.device) {
				return;
			}

			const g = this.canvas.nativeElement.getContext('2d');

	    let blob = new Blob([deviceObject.screen], {type: 'image/jpeg'});
	    const URL = window.URL || window['webkitURL'];
	    let img = new Image();
	    let u = URL.createObjectURL(blob);
			const ref = this;

	    img.onload = function() {

	      ref.canvas.nativeElement.setAttribute('width', img.width);
	      ref.canvas.nativeElement.setAttribute('height', img.height);

	      g.drawImage(img, 0, 0);
	      img.onload = null;
	      img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
	      img = null;
	      u = null;
	      blob = null;
	    };

	    img.src = u;
	 });

		if (this.data.step !== undefined) {
			this.code = this.data.step.defaultValue;
		}

		this.testSuite = this.data.testSuite;
  }

	run() {
		return;
		const actionRequestId = this.appService.uuid();

		this.connectService.runCode(
			// first device
			this.connectService.activeDevice.device._id,
			actionRequestId,
			this.code.toString(),
			this.testSuite,
		).then((result: any) => {
			console.log(result.data);
		});
	}

	save() {
		this.connectService.activeEnhancedKeyboard = true;
		this.dialogRef.close(this.code);
	}

	ngAfterViewInit() {

		const staticWordCompleter = {
			getCompletions: function(editor, session, pos, prefix, callback) {
			    const wordList = ['foo', 'bar', 'baz'];
			    callback(null, wordList.map(function(word) {
			        return {
			            caption: word,
			            value: word,
			            meta: 'testcase'
			        };
			    }));
			}
		};
		this.editor.getEditor().completers = [this.editor.getEditor().completers[0], this.editor.getEditor().completers[1], staticWordCompleter];
		console.log(this.editor.getEditor());

		this.wsocket.on('script-output', (data: any) => {
			this.output.push(data.value);
			console.log(this.output);
		});

		this.cd.detectChanges();
	}

}
