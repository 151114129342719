import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVER } from '../../providers';

@Injectable()
export class PendingDevicesService {

	constructor (
		private http: Http,
	) {
		
	}

	getPendingDevices() {
		return this.http.get(`${URL_SERVER}/services/device/pendingDevicesList`);
	}

	getDevicesFarms(){
		return this.http.get(`${URL_SERVER}/services/device/farmList`);		
	}

	getDeviceHubs(){
		return this.http.get(`${URL_SERVER}/services/device/hubList`);
	}

	getDeviceOSVersions(){
		return this.http.get(`${URL_SERVER}/services/device/osVersionList`);		
	}

	acceptDevice(device){
		return this.http.post(`${URL_SERVER}/services/device/acceptDevice`, {device});
	}
}
