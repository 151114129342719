import { Injectable, Inject, EventEmitter, NgZone } from '@angular/core';
import { Http } from '@angular/http';
import { wsocketToken } from '../../providers';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class ShowcaseService {
    
    companyId: String;
    showcases: any;

    constructor (
        private http: Http,
        private loginService: LoginService,
        private zone: NgZone,
        private toastr: ToastrService,
		@Inject(wsocketToken) private wsocket: any
	) {
        this.showcases = [];
    }
    
    setupSocket(){
        if(this.loginService.user && this.loginService.isAdmin()){
            this.companyId = this.loginService.user.team.company._id;
            this.wsocket.on(`${this.companyId}-showcase`, (args: any) => {
                this.zone.run(() => {
                    if(args.alert || /TRUE/.test(args.alert)){
                        this.toastr.warning(`${args.name}`,`Sensor de fumaça na Vitrine!`,{closeButton: true, disableTimeOut: true});
                    }
                    this.setShowcase(args);
                })
            });
        }
    }

    setShowcase(infos){
        let idx = this.showcases.findIndex(sc => sc.name === infos.name);
        
        if(idx > -1){
            this.showcases[idx] = infos;
        }else{
            this.showcases.push(infos);
        }
    }
}