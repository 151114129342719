import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../login/login.service';
import { ReportsService } from '../../../report-tree/reports/reports.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-last-report',
  templateUrl: './last-report.component.html',
  styleUrls: ['./last-report.component.scss']
})
export class LastReportComponent implements OnInit {

    constructor(
        private router: Router,
        public loginService: LoginService,
        public reportService: ReportsService
    ) { }

    lastReport: any;
    ngOnInit() {
        this.reportService.getLastReport().subscribe(async result => {
            let resp = await result.json();
            this.lastReport = resp[0];
        });
    }

    getThumbUrl(device) {
		return device.model.urlThumb ? device.model.urlThumb : (device.model.thumb ? 'assets/images/thumbs/'+device.model.thumb : 'assets/images/iphone.svg');
	}

    goToReport(report){
        console.log(report.execution._id, report.device._id, report._id)
        this.router.navigate(['/report-suites', report.execution._id, report.device._id, report._id]);
    }

    calcDuration(seconds){
        let getPlural = (number, word)  => {
            return number === 1 && word.one || word.other;
        }
    
        let words = {
                minute: { one: 'minuto', other: 'minutos' },
                second: { one: 'segundo', other: 'segundos' }
            },
            units = ['minute', 'second'],
            values = [60, 1],
            result = [];
    
        units.reduce(function (s, u, i) {
            var temp = Math.floor(s / values[i]);
            if (temp) {
                result.push(temp + ' ' + getPlural(temp, words[u]));
            }
            return s % values[i];
        }, seconds);
        return result.join(' ');
    }
}
