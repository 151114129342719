import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVER } from './../../providers';

@Injectable()
export class TicketListDialogService {
  constructor(private http: Http) {}

  listTickets(){ 
    return this.http.post(`${URL_SERVER}/services/execution/ticket/list`, {});
  }

  dealloc(ticket){
    return this.http.post(`${URL_SERVER}/services/execution/ticket/forcedealloc/`, {ticket});
  }
}
