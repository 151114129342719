import { Input, Component, OnInit, Inject, OnDestroy, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from '../../admin/accounts/accounts.service';
import { LoginService } from '../../login/login.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'edit-widget-dialog',
  templateUrl: './edit-widget-dialog.component.html',
  styleUrls: ['./edit-widget-dialog.component.scss']
})
export class EditWidgetDialogComponent implements OnInit, OnDestroy, AfterViewChecked {

	@Input() elementDialog: any;

    constructor(
		private toastr: ToastrService,
		private dialog: MatDialog, 
        private changeDetector: ChangeDetectorRef,
        private dashboardService: DashboardService, 
        private loginService: LoginService,
        public dialogRef: MatDialogRef<EditWidgetDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any    
	) {
	}
    ngAfterViewChecked(): void {
        
    }
    ngOnDestroy(): void {
        this.changeDetector.detach();
    }

	ngOnInit() {
        console.log(this.loginService.user.team)
    }

    checkWidget(widgetName){
        return this.loginService.user.team.widgets.map(wgt => wgt.title).includes(widgetName);
    }

    changeToggle(widgetName){
        let idx;
        if(this.loginService.user.team.widgets.map(wgt => wgt.title).includes(widgetName)){
            //se tiver remove
            idx = this.loginService.user.team.widgets.findIndex(wgt => wgt.title == widgetName);
            this.loginService.user.team.widgets.splice(idx, 1);
        } else {
            //adicionar
            this.loginService.user.team.widgets.push({
                title: widgetName,
                cols: 2, rows: (widgetName == 'lastReport' || widgetName == 'devices') ? 2 : 1
            })
        }

        this.dashboardService.updateWidgetsList(this.loginService.user.team.widgets)
        .subscribe(async resp => console.log(await resp.json()));
    }
}
