import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-testcase-dialog',
  templateUrl: './testcase-dialog.component.html',
  styleUrls: ['./testcase-dialog.component.scss']
})
export class TestcaseDialogComponent implements OnInit {

	testCase: any;
	activeStep: any;

  constructor(
		public dialogRef: MatDialogRef<TestcaseDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
	) { }

  ngOnInit() {
		this.testCase = this.data.testCase;

		if (this.testCase.steps.length > 0) {
			this.activeStep = this.testCase.steps[0];
		}
  }

	stepTitle() {

		switch (this.activeStep.type) {
			case 'touchAction':
			return 'Touch';
			break;
			case 'delay':
			return 'Delay';
			break;
			case 'validate':
			return 'Validate';
			break;
			case 'script':
			return 'Script';
			break;
			case 'keyboard':
			return 'Type';
			break;
			case 'folder':
			return 'Conditional folder';
			break;
			case 'pressKey':
				switch (this.activeStep.defaultValue) {
					case 4:
					return 'Back';
					break;
					case 3:
					return 'Home';
					break;
					case 187:
					return 'Options';
					break;
					case 'home':
					return 'Home';
					break;
				}
			break;
		}

	}

}
