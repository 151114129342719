import {  ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy, Inject, ViewChild, asNativeElements, ElementRef, AfterViewChecked } from '@angular/core';
import { FormControl, Validators, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../login/login.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { SuperAdminService } from '../superadmin.service';
import { SelectListDialogComponent } from '../../admin/pending-devices/edit-device-dialog/select-list-dialog/select-list.component';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { UploadComponent } from '../../util/upload/upload.component';


@Component({
    selector: 'edit-company-dialog',
    templateUrl: './edit-company-dialog.component.html',
    styleUrls: ['./edit-company-dialog.component.scss']
})

export class EditCompanyDialogComponent implements OnInit, AfterViewChecked {

    @Input() elementDialog: any;
	@ViewChild('uplogoComp') uploadComp: UploadComponent;
    companyForm: FormGroup;
    respError = {message: '', type: ''};
    isLoading: Boolean = false;
    methods = [{method:'ON_PREMISE', name: 'Privado'}, {method:'POSTPAID', name: 'Pós pago'}, {method:'PREPAID', name:'Pré pago'}, {method:'MINUTE', name:'Minutagem'}, {method:'ON_PREMISE_LITE', name:'Privado Custom'}];
    style: any;

    constructor(
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private zone: NgZone,
        private loginService: LoginService,
        private superAdminService: SuperAdminService,
        private toastr: ToastrService,
        private dialog: MatDialog, 
        public dialogRef: MatDialogRef<EditCompanyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }
    ngAfterViewChecked(): void {
        this.changeDetector.detectChanges();
    }
    
    ngOnInit(): void {
        this.style = {logo:'', background:''};
        console.log(this.data)
        if(!this.data.company){
            this.data.company = {
                name: '',
                isActive: false,
                plan: {method: 'ON_PREMISE', monthlyTime: 0, additionalTime: 0, limitOfDevices: 0, valuePerMinute: 0},
                servicePackages: [],
                contact: [{email: '', phone: ''}],
                address: [{state: '', city: '', addressLine1: '', zip: ''}]
            }
        }else{
            if(this.data.company.plan.monthlyTime || this.data.company.plan.additionalTime){
                this.data.company.plan.monthlyTime = (this.data.company.plan.monthlyTime/60);
                this.data.company.plan.additionalTime = (this.data.company.plan.additionalTime/60);        
            }
        }

        if(!this.data.company.contact.length){ this.data.company.contact.push({email: '', phone: ''}) }
        if(!this.data.company.address.length){ this.data.company.address.push({state: '', city: '', addressLine1: '', zip: ''}) }

        this.companyForm = new FormGroup({
            name: new FormControl({value: this.data.company.name}, Validators.required),
            isActive: new FormControl({value: this.data.company.isActive}),
            email: new FormControl({value: this.data.company.contact[0].email}),
            phone: new FormControl({value: this.data.company.contact[0].phone}),
            addressLine1: new FormControl({value: this.data.company.address[0].addressLine1 || ''}),
            state: new FormControl({value: this.data.company.address[0].state || ''}),
            city: new FormControl({value: this.data.company.address[0].city || ''}),
            zip: new FormControl({value: this.data.company.address[0].zip || ''}),
            method: new FormControl({value: this.data.company.plan.method || 'ON_PREMISE'}),
            limitOfDevices: new FormControl({value: this.data.company.plan.limitOfDevices || 0}),
            monthlyTime: new FormControl({value: this.data.company.plan.monthlyTime || 0}),
            price: new FormControl({value: this.data.company.plan.valuePerMinute || 0}),
            additionalTime: new FormControl({value: this.data.company.plan.additionalTime || 0}),
       });
    }
 
    saveCompany(){
        this.isLoading = true;
        if(this.style.background.length || this.style.logo.length){
            this.data.company.style = this.style
        }
        console.log(this.data.company)
        this.data.company.plan.monthlyTime = (this.data.company.plan.monthlyTime*60);
        this.data.company.plan.additionalTime = (this.data.company.plan.additionalTime*60);

        this.superAdminService.saveCompany(this.data.company).subscribe(async resp => {
            let data = await resp.json();
            this.isLoading = false;
            this.toastr.success('Empresa Salva!');
            this.dialogRef.close('x');
        });
    }

    toggleIsActive(){
        this.data.company.isActive = !this.data.company.isActive
    }

    showPackage(sPackage){
        return this.data.company.servicePackages.map(d => d._id).includes(sPackage._id) ? false : true;
    }

    addPackage(sPackage){
        this.data.company.servicePackages.push(sPackage);
    }

    removePackage(idx){
        this.data.company.servicePackages.splice(idx,1);
    }
   
    createPackage(){
        this.dialog.open(CustomDialogComponent, {
			width: '600px',
            data: {
                type: 'package',
                deviceFarms: this.data.deviceFarms,
                servicePackages: this.data.servicePackages
            }
        })
    }

    list(list: any, type: any){
        this.dialog.open(SelectListDialogComponent, {
			width: '400px',
			height: '400px',
            data: {
				list,
				type,
				company: this.data.company
            }
        })
    }
}