import {  Component, OnInit } from '@angular/core';
import { VERSION} from '../../providers';

@Component({
  selector: 'release-notes-dialog',
  templateUrl: './release-notes-dialog.component.html',
  styleUrls: ['./release-notes-dialog.component.scss']
})
export class ReleaseNotesDialogComponent implements OnInit {

  version = VERSION;	
	constructor() {
	}

	ngOnInit() {
	}
}
