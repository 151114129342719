import {  ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy, Inject, ViewChild, asNativeElements, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { PendingDevicesService } from './../pending-devices.service';
import { FormControl, Validators, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { SelectListDialogComponent } from './select-list-dialog/select-list.component';
import { UploadComponent } from '../../../util/upload/upload.component';

@Component({
  selector: 'edit-device-dialog',
  templateUrl: './edit-device-dialog.component.html',
  styleUrls: ['./edit-device-dialog.component.scss']
})
export class EditDeviceDialogComponent implements OnInit, OnDestroy {

	@ViewChild('upload') uploadComp: UploadComponent;
	@ViewChild('thumbDevice') thumbDev: ElementRef;
	
	@Input() elementDialog: any;
	@Input() deviceList = [] as any[];
	pendingForm: FormGroup;
	farmSub:any;
	uploadSubscr:any;
	farmList = [];
	hubSub:any;
	deviceHubList = [];
	osSub:any;


    constructor(
		private router: Router,
		private changeDetector: ChangeDetectorRef,
		private zone: NgZone,
		private dialog: MatDialog, 
		private pendingService: PendingDevicesService,
		public dialogRef: MatDialogRef<EditDeviceDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	ngOnInit() {
		console.log(this.data.device);

		this.farmSub = this.pendingService.getDevicesFarms().subscribe(async resp => {
            this.farmList = await resp.json();
		});
		
		this.uploadSubscr = this.uploadComp.uploadEvent.subscribe((val: any) => {
			let thumb = this.thumbDev.nativeElement as HTMLElement;

			thumb.style.backgroundImage = `url(${val.url})`;
			thumb.style.display = `inline-flex`;
			this.data.device.model.urlThumb = val.url;
		});

		this.pendingForm = new FormGroup({
			alias: new FormControl({value: this.data.device.alias}, Validators.required),
			serial: new FormControl({value: this.data.device.serial, disabled: true}, Validators.required),
			OS: new FormControl({value: this.data.device.OS.platform, disabled: true}, Validators.required),
			version: new FormControl({value: this.data.device.OS.version}, Validators.required),
			farm: new FormControl({value: this.data.device.deviceFarms[0].name}, Validators.required),
			hub: new FormControl({value: this.data.device.deviceHub.name, disabled: true}, Validators.required),
			model: new FormControl({value: this.data.device.model.name, disabled: true}, Validators.required),
			width: new FormControl({value: this.data.device.model.resolution.width}, Validators.required),
			height: new FormControl({value: this.data.device.model.resolution.height}, Validators.required),
		});
	}

	list(list: any, type: any){
        this.dialog.open(SelectListDialogComponent, {
			width: '300px',
			height: '400px',
            data: {
				list,
				type,
				device: this.data.device
            }
        })
    }

	acceptDevice(){
		this.pendingService.acceptDevice(this.data.device).subscribe(resp => {
			console.log(resp.json());
			this.data.device.authorized = true;
			this.dialogRef.close()
		});
	}

	uploadParams() {
		return {type: 'img', name: this.data.device.model.name};
	}

	ngOnDestroy() {
		this.changeDetector.detach();

		//this.osSub.unsubscribe();
		this.farmSub.unsubscribe();
		//this.hubSub.unsubscribe();
	}
}
