import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';

@Component({
  selector: 'app-storage-consuption',
  templateUrl: './storage-consuption.component.html',
  styleUrls: ['./storage-consuption.component.scss']
})
export class StorageConsuptionComponent implements OnInit {

  constructor(private http: Http) { }

	private dataArray = [];
	consumption: Number;
	used: Number;

	

  ngOnInit() {
		
		this.readStorageStatistics()
		.subscribe(
		(result: any) => {
				const statistics = result.json();
				let sum = 0;

				for (const key in statistics) {
					if (key) {
						sum = sum + statistics[key] ;
					}
				}

				const total = 4064;

				this.dataArray.push({title: 'Total', value: total});
				this.dataArray.push({title: 'Used', value: sum});

				this.used = parseFloat(sum.toFixed(2));
				this.consumption = (sum / total) * 100;
				this.consumption = parseFloat(this.consumption.toFixed(2));

		},
		(error: any) => {
				console.log(error.json());
		});
  }

	readStorageStatistics() {
		 return this.http.get('services/resource/count');
	}

}
