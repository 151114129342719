import { Injectable, Inject } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVER } from './../providers'

@Injectable()
export class ConfigurationService {

  constructor(
    private http: Http
  ) {}

  getCompanyIntegration(){
    return this.http.get(`${URL_SERVER}/services/user/getCompanyIntegration`);
  }

  updateIntegration(integration){
    return this.http.post(`${URL_SERVER}/services/user/updateIntegrationInfo`, integration);
  }
}
