import { Component, ChangeDetectorRef, Input, NgZone, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DevicesService, DeviceFilter } from './devices.service';
import { ConnectService } from '../connect/connect.service';
import { MatSnackBar } from '@angular/material';
import { $Token } from '../providers';
import { URL_SERVER } from '../providers';
import { LoginService } from '../login/login.service';
import { wsocketToken } from '../providers';
import { EditDeviceInfoDialogComponent } from './edit-device-dialog/edit-device-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})

export class DevicesComponent implements OnInit, OnDestroy {

	@Input() deviceList = [] as any[];
	deviceUpdateSubscription: Subject<any>;
	deviceFilter: DeviceFilter = DeviceFilter.getInstance();
	newwindow: any;
	deviceRatio: any;
	devFilter:any;
	disconnectingMap = new Map<string, boolean>();
	allowedDevices:any[];

	constructor(
		public snackBar: MatSnackBar,
		private router: Router,
		public devicesService: DevicesService,
		private connect: ConnectService,
		private changeDetector: ChangeDetectorRef,
		private zone: NgZone,
		private dialog: MatDialog,
		private toastr: ToastrService,
		private loginService: LoginService,
		@Inject($Token) private $: any,
		@Inject(wsocketToken) private wsocket: any
	) {

		this.wsocket.on('disconnect-warning', (args: any) => {
			if (this.loginService.user._id === args.user) {
				this.snackBar.open(args.message, args.type, {duration: 2000});
			}
		});
	}

	ngOnInit() {
		if(this.devicesService.devices.length == 0){
			this.devicesService.getDevices().subscribe(async resp => {
				this.allowedDevices = this.devicesService.devices = await resp.json();
				this.filterDevices();
			})
		} else {
			this.allowedDevices = this.devicesService.devices;
			this.filterDevices();
		}

		this.deviceUpdateSubscription = this.devicesService.deviceUpdateEvent.subscribe((device: any) => {
			this.zone.run(() => {
				this.deviceList.forEach((fullDevice: any) => {
					if (fullDevice._id === device._id) {
						if (device.status === 'BUSY') {
							this.isConnected(device);
						}else{
							device.owner = null;
							this.connect.cleanDeviceStatus(device);
						}
						fullDevice.status = device.status;
					}
				});
			});
		});
	}

	getShadowByTemp(temp){
		if(!temp) return '#CCC';

		let nTemp = parseInt(temp);

		if(nTemp > 50){ return 'red'};
		if(nTemp > 32){
			return 'orange';
		} else{
			return 'green';
		}
	}
	
	getThumbUrl(device) {
		return device.model.urlThumb ? device.model.urlThumb : (device.model.thumb ? 'assets/images/thumbs/'+device.model.thumb : 'assets/images/iphone.svg');
	}

	ngOnDestroy() {
		this.changeDetector.detach();
		this.deviceUpdateSubscription.unsubscribe();
	}

	getlength(status){
		if (!this.allowedDevices) { return 0; }

		if(status.toLowerCase() === 'ios' || status.toLowerCase() === 'android'){
			let aoi = status.toLowerCase() == 'ios' ? 'ios' : 'android' ;
			return this.allowedDevices.filter(d=> d.OS.platform.toLowerCase() == aoi).length;
		}
		return this.allowedDevices.filter(d=>d.status.toLowerCase() == status.toLowerCase()).length;
	}

	showAll(statusOrOS){
		this.deviceList = this.allowedDevices.filter(device => {
			if(statusOrOS.toLowerCase() == 'ios' || statusOrOS.toLowerCase() == 'android'){
				return device.OS.platform.toLowerCase() == statusOrOS
			} else {
				return device.status.toLowerCase() == statusOrOS
			}
		})
	}

	filterDevices() {
		this.deviceList = this.allowedDevices.filter((device) => {
			let valid = true;
			for (const presset of Object.keys(this.deviceFilter)) {
				if (this.deviceFilter[presset].filter.length > 0) {
					switch (presset) {
						case 'types': {
							let hasType = false;
							for (const type of device.model.type) {
								if (this.deviceFilter[presset].filter.indexOf(type._id) !== -1) {
									hasType = true;
								}
							}
							valid = hasType;
						}
						break;
						case 'models': {
							if (this.deviceFilter[presset].filter.indexOf(device.model._id) === -1) {
								valid = false;
							}
						}
						break;
						case 'manufacturers': {
							if (this.deviceFilter[presset].filter.indexOf(device.model.manufacturer._id) === -1) {
								valid = false;
							}
						}
						break;
						case 'oss': {
							if (this.deviceFilter[presset].filter.indexOf(device.OS.platform.toLowerCase()) === -1) {
								valid = false;
							}
						}
						break;
						case 'osVersions': {
							if (this.deviceFilter[presset].filter.indexOf(device.OS.version) === -1) {
								valid = false;
							}
						}
						break;
						case 'resolutions': {
							if (this.deviceFilter[presset].filter.indexOf(`${device.model.resolution.width}x${device.model.resolution.height}`) === -1) {
								valid = false;
							}
						}
						break;
						case 'status': {
							if (this.deviceFilter[presset].filter.indexOf(device.status) === -1) {
								valid = false;
							}
						}
						break;
					}
				}
			}
			return valid;
		});
		this.sortDevices();
	}

	onClickSeeMore(type: string) {
		this.deviceFilter[type].seeAll = true;
	}

  hideInfo(device: any){
    let info = this.$(`#device-${device._id} div.info`);
    if(!info.hasClass('hide')){
      info.removeClass('show');
      info.addClass('hide');
    }
  }

  onDeviceMouseLeave(device: any) {
    let info = this.$(`#device-${device._id} div.info`);
    if(!info.hasClass('hide')){
      setTimeout(this.hideInfo,1000,device);
    }
  }

  onClickShowInfo(device: any){
    let info = this.$(`#device-${device._id} div.info`);
    if(!info.hasClass('show')){
        info.addClass('show');
        info.removeClass('hide');
    } else {
        this.hideInfo(device);
    }
  }

	onClickConnect(device: any) {
		//BATER NA API PRA CHECAR OS MINUTOS
		this.devicesService.checkCompanyMinutes(this.loginService.user.team.company._id, device._id).subscribe(async data => {
			let resp = await data.json();
			if(resp.status){
				// if (device.status === 'ONLINE') {
					this.connect.requestConnection(device);
					this.router.navigate(['/connect']);
				// }
			}else{
				this.toastr.warning(resp.message);
			}
		});
	}

	onClickWatcher(device: any){
		this.deviceRatio = (device.model.resolution.width / 300);
		this.newwindow = window.open(`${URL_SERVER}/#/watcher?device=${device._id}`,'',
									`directories=0, titlebar=0, location=0, status=0, menubar=0,history=0,resizable=0,scrollbars=0,toolbar=0, height=${((device.model.resolution.height/this.deviceRatio)+100)},width=320`);
		this.newwindow.resizeTo(320, ((device.model.resolution.height/this.deviceRatio)+100));
	}

	onClickReconnect(device: any) {
		this.connect.requestReconnect(device);
		this.router.navigate(['/connect']);
	}

	onClickDisconnect(device: any) {
    this.disconnectingMap.set(device._id, true);
		this.connect.releaseConnection(device,null,true);
	}

	isAutomated(device: any) {
		if(device.owner && device.owner.type){
			return device.owner.type == 'automated' ? true : false;
		}
		return false;
	}

	canDisconect(device: any) {
		const index = this.connect.activeDevices.filter(dev => dev.device._id === device._id);

		if(this.loginService.user && this.loginService.user.roles && (this.loginService.user.roles.includes('ADMIN') || this.loginService.user.roles.includes('ROOT'))){
			return true;
		}
		if(index.length > 0){
			return true;
		}
		return false;
	}

	isConnected(device: any): boolean {
    if (this.checkConnected(device)) {
      return true;
    }
    this.disconnectingMap.set(device._id, false);
    return false;
	}

  checkConnected(device: any): boolean {
		if(device.owner){
			if(device.status != 'ONLINE' && this.loginService.user && (this.loginService.user._id == device.owner._id)){
				return true;
			}else{
				const index = this.connect.activeDevices.findIndex(itDevice => itDevice.device._id === device._id);
				return index !== -1;
			}
		}else{
			device.owner = {username: ''};
			return false;
		}
  }

	onChangeFilter(event: any, type: string, value: any) {
		console.log('onChangefilter')
		let ref;
		if (value && typeof(value) === 'string') {
			ref = value;
		} else {
			ref = value._id;
		}

		if (event.checked) {
			if (this.deviceFilter[type].filter.indexOf(ref) === -1) {
				this.deviceFilter[type].filter.push(ref);
			}
		} else {
			if (this.deviceFilter[type].filter.indexOf(ref) !== -1) {
				this.deviceFilter[type].filter.splice(this.deviceFilter[type].filter.indexOf(ref), 1);
			}
		}
		this.filterDevices();
	}

	getList(type: string) {
	 if (this.deviceFilter[type].seeAll) {
		 return this.devicesService.pressets[type];
	 } else {
		 return this.devicesService.pressets[type].slice(0, 5);
	 }
	}

	sortDevices(){
		this.deviceList.sort((a:any, b:any) => {
			if(parseInt(a.ordnance) === parseInt(b.ordnance)){
				return a.alias < b.alias ? -1 : (a.alias > b.alias ? 1 : 0);
			}
			return parseInt(a.ordnance) < parseInt(b.ordnance) ? -1 : (parseInt(a.ordnance) > parseInt(b.ordnance) ? 1 : 0);
		});
	}

	isChecked(type, value){
		let ref;
		if(value){
			typeof(value) === 'string' ? ref=value : ref=value._id;
		}
		return (this.deviceFilter[type].filter.indexOf(ref)) > -1;
	}

	isAdmin(){
		return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ADMIN') > -1 || this.loginService.user.roles.indexOf('ROOT') > -1);
	}
	
	editDevice(device: any){
		let list = this.deviceList;
		this.dialog.open(EditDeviceInfoDialogComponent, {
				width: '500px',
				data: {
					device,
					list
				}
		})
		
		// this.devicesService.editAlias(device).subscribe((result) => {
		// 	this.sortDevices();
		// 	console.log(result.json())
		// }, (error) => {
		// 	console.error(error);
		// });
	}
}
