import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras, ParamMap } from '@angular/router';
import { DevicesService, DeviceFilter } from '../../devices/devices.service';
import { TestsService } from '../../tests/tests.service';
import { MatDialog } from '@angular/material';
import { PreviewComponent } from './preview/preview.component';
import { D3Service, D3, Selection } from 'd3-ng2-service';
import { $Token } from '../../providers';

@Component({
  selector: 'app-execution',
  templateUrl: './execution.component.html',
  styleUrls: ['./execution.component.scss']
})
export class ExecutionComponent implements OnInit {

	private d3: D3;
	executionId: any = this.route.snapshot.params.executionId;
	execution: any;
	rawDeviceReport: any;
	deviceFilter: DeviceFilter = DeviceFilter.getInstance();
	metricsSVG: any;
	activeReportHistory: any;
	metricData: any[] = [];
	metricData2: any[] = [];
	@ViewChild('tooltipMetrics') tooltipMetrics: ElementRef;
	@ViewChild('reportMetrics') reportMetricsContainer: ElementRef;

	constructor(
		public devicesService: DevicesService,
		private router: Router,
		private route: ActivatedRoute,
		private testsService: TestsService,
		private previewDialog: MatDialog,
		private d3Service: D3Service,
	) {
		this.d3 = d3Service.getD3();
	}

	showAllReports(deviceId) {
		this.router.navigate(['/report-suites', this.executionId, deviceId]);
	}

  ngOnInit() {
		this.testsService.getExecution(this.executionId as string).subscribe(async (response) => {
			const data = await response.json();

			if (!data) {
				return;
			}
			console.log(data)
			this.execution = data;
			this.rawDeviceReport = data.deviceReport;
			// this.execution.deviceReport = this.rawDeviceReport = this.execution.deviceReport.map((e) => {
			// 	e.device = this.devicesService.devices.find((device) => device._id === e.device);
			// 	return e;
			// });


			const clone = Object.assign({}, this.execution);

			this.metricData = clone.deviceReport.map((dr) => {
				return dr.history.map((history) => {
					history.device = dr.device;
					history.creationDate = new Date(Date.parse(history.creationDate));
					return history;
				});
			});

			this.metricData = this.metricData.reduce((stack, metric) => {
				stack.push(... metric);
				return stack;
			}).sort(function(a, b) {
				return a.creationDate.getTime() - b.creationDate.getTime();
			});
		});
  }

	filterDevices() {
		this.execution.deviceReport = this.rawDeviceReport.filter((deviceReport) => {
			let valid = true;
			for (const presset of Object.keys(this.deviceFilter)) {
				if (this.deviceFilter[presset].filter.length > 0) {
					switch (presset) {
						case 'types': {
							let hasType = false;
							for (const type of deviceReport.device.model.type) {
								if (this.deviceFilter[presset].filter.indexOf(type._id) !== -1) {
									hasType = true;
								}
							}
							valid = hasType;
						}
						break;
						case 'models': {
							if (this.deviceFilter[presset].filter.indexOf(deviceReport.device.model._id) === -1) {
								valid = false;
							}
						}
						break;
						case 'manufacturers': {
							if (this.deviceFilter[presset].filter.indexOf(deviceReport.device.model.manufacturer._id) === -1) {
								valid = false;
							}
						}
						break;
						case 'oss': {
							if (this.deviceFilter[presset].filter.indexOf(deviceReport.device.OS.platform) === -1) {
								valid = false;
							}
						}
						break;
						case 'resolutions': {
							if (this.deviceFilter[presset].filter
								.indexOf(`${deviceReport.device.model.resolution.width}x${deviceReport.device.model.resolution.height}`) === -1) {
								valid = false;
							}
						}
						break;
						case 'status': {
							if (this.deviceFilter[presset].filter.indexOf(deviceReport.device.status) === -1) {
								valid = false;
							}
						}
						break;
					}
				}
			}
			return valid;
		});
	}

	onChangeFilter(event: any, type: string, value: any) {

		let ref;
		if (value && typeof(value) === 'string') {
			ref = value;
		} else {
			ref = value._id;
		}

		if (event.checked) {
			if (this.deviceFilter[type].filter.indexOf(ref) === -1) {
				this.deviceFilter[type].filter.push(ref);
			}
		} else {
			if (this.deviceFilter[type].filter.indexOf(ref) !== -1) {
				this.deviceFilter[type].filter.splice(this.deviceFilter[type].filter.indexOf(ref), 1);
			}
		}
		this.filterDevices();
	}

	onClickSeeMore(type: string) {
		this.deviceFilter[type].seeAll = true;
	}

	getList(type: string) {
	 if (this.deviceFilter[type].seeAll) {
		 return this.devicesService.pressets[type];
	 } else {
		 return this.devicesService.pressets[type].slice(0, 5);
	 }
	}

	seeFullReport(deviceId, reportId) {
		this.router.navigate(['/report-suites', this.executionId, deviceId, reportId]);
	}

	preview(report) {
		this.previewDialog.open(PreviewComponent, {
      		width: '350px',
		
			panelClass: report.succeed ? 'preview-success' : 'preview-fail',
      		data: {
				report,
				reportName: this.execution.name,
				executionId: this.executionId
      		}
    	});
	}

	getThumbUrl(device) {
		return device.model.urlThumb ? device.model.urlThumb : (device.model.thumb ? 'assets/images/thumbs/'+device.model.thumb : 'assets/images/iphone.svg');
	}

	isChecked(type, value){
		let ref;
		if(value){
			typeof(value) === 'string' ? ref=value : ref=value._id;
		}
		return (this.deviceFilter[type].filter.indexOf(ref)) > -1;
	}

	back() {
		this.router.navigate(['/reports']);
	}

}
