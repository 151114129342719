import { Component, OnInit, ViewChild, ElementRef, Inject, HostListener, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ReportsService } from '../reports.service';

import { Router, ActivatedRoute, Params, NavigationExtras, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material';
import { D3Service, D3, Selection } from 'd3-ng2-service';
import { ReportUtilService } from './report-util.service';
import { DevicesService } from '../../../devices/devices.service';
import { $Token } from '../../../providers';
import {LogsDialogComponent} from './logs-dialog/logs-dialog.component';
import {Log} from './logs-dialog/log';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss']
})
export class ReportDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  	static readonly DOWNLOAD_LABEL = 'Download em PDF';
  	static readonly DOWNLOADING_LABEL = 'Gerando relatório...';
	private d3: D3;
	steps: Number;
	self: any;
	report: any;
	reportEntries: any;
  	stepList: any[] = [];
	errorMessage: string;
  	currentIndex: number;
  	reportList: any[];
  	executionList: any;
	execution: any;
	executionGroup: any;
	executionId: any = this.route.snapshot.params.executionId;
	reportId: any = this.route.snapshot.params.reportId;
	deviceId: any = this.route.snapshot.params.deviceId;
	width: number;
	reportSVG: any;
	metricsSVG: any;
	showMetrics = true;

	showAppiumLogModal = false;
	showDeviceLogModal = false;
	showCapabilitiesLogModal = false;

	appiumLog: string;
  	appiumLogs: Log[];
	deviceLog: string;
  	deviceLogs: Log[];
	deviceDataSourceLoaded = false;
	deviceDataSource: any;
  	displayedColumns = ['type', 'time', 'pid', 'tid', 'text'];
	capabilities: string;

	metric: any = {
	};

	firstBuild: Boolean = true;
  	downloading = false;
  	downloadLabel = ReportDetailComponent.DOWNLOAD_LABEL;

	@ViewChild('resultModal') resultModal: ElementRef;
	@ViewChild('logModal') logModal: ElementRef;
	@ViewChild('appiumModal') appiumModal: ElementRef;
	@ViewChild('capabilitiesModal') capabilitiesModal: ElementRef;
	@ViewChild('reportIndex') reportIndexContainer: ElementRef;
	@ViewChild('reportMetrics') reportMetricsContainer: ElementRef;
	@ViewChild('tooltipMetrics') tooltipMetrics: ElementRef;
  	@ViewChild('pdfLink') pdfLink: ElementRef;
	activeStep: any;

	constructor(
    	private changeRef: ChangeDetectorRef,
		private route: ActivatedRoute,
		private router: Router,
		private reportsService: ReportsService,
		private devicesService: DevicesService,
		private d3Service: D3Service,
		private deviceDialog: MatDialog,
		public reportUtilservice: ReportUtilService,
		@Inject($Token) private $: any
	) {
		this.d3 = d3Service.getD3();
	}

  	public ngAfterViewInit(): void {
    	sessionStorage.setItem(this.reportId, 'ok');
  	}

  	public ngOnDestroy(): void {
    	sessionStorage.removeItem(this.reportId);
  	}

  	@HostListener('document:keydown', ['$event'])
  	public handleKeyboardEvent(event: KeyboardEvent): void {

     	if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
       		event.stopPropagation();
       		event.preventDefault();

       		if (!this.activeStep) {
         		this.setActiveStep(this.stepList[0]);
          		return;
        	}

       		switch (event.key) {
         		case 'ArrowLeft':
         			if (this.currentIndex - 1 >= 0 ) {
           				this.setActiveStep(this.stepList[this.currentIndex - 1]);
         			}
         		break;
         		case 'ArrowRight':
					if (this.currentIndex + 1 < this.stepList.length ) {
						this.setActiveStep(this.stepList[this.currentIndex + 1]);
					}
         		break;
       		}
     	}
  	}

	findDeph(list, depth, mostDepth) {
		depth++;
		if (depth > mostDepth) {
			mostDepth = depth;
		}
		list.forEach((el) => {
			if (el.children) {
				mostDepth = this.findDeph(el.children, depth, mostDepth);
				return mostDepth;
			} else {
        this.stepList.push(el);
      }
		});
		return mostDepth;
	}

	filterSession(reports) {
		const ref = this;
		reports = reports.filter((r) => {
			if (r.children) {
				r.children = ref.filterSession(r.children);
			}

			if (r.value) {
				return this.reportUtilservice.labelFor(r) !== 'Session';
			} else {
				return true;
			}
		});

		return reports;
	}

	loadReport() {
		const self = this;
		return new Promise((resolve, reject) => {
			self.reportsService.view(self.reportId).subscribe((result: any) => {
				self.report = result.json();
				//self.report.device = self.devicesService.devices.find((device: any) => device._id === self.report.device);
				//this.setLogs(this.report);
				self.reportsService.detail(self.report).subscribe((detailsResult: any) => {
					self.reportEntries = detailsResult.json();
					self.reportEntries = self.filterSession(self.reportEntries);
					self.width = self.reportIndexContainer.nativeElement.offsetWidth;
					self.buildChart();
					self.reportIndexContainer.nativeElement.addEventListener('mousewheel', (e: any) => {
						e.preventDefault();
						if (self.reportSVG && self.width + (e.wheelDelta / 10) >= self.reportIndexContainer.nativeElement.offsetWidth) {
							self.reportSVG.remove();
							self.reportSVG = undefined;
	
							if ( self.metricsSVG ) {
								self.metricsSVG.remove();
							}
							self.metricsSVG = undefined;
	
							self.width += (e.wheelDelta / 10);
							self.buildChart();
						}
					}, false);
				});

				resolve(0);
			}, (error: any) => {
				// TODO
				// this.errorMessage = error.json().message;
			});
		})

	}

	editReason(){
		this.deviceDialog.open(ErrorDialogComponent, {
			width: '40%',
			height: '400px',
      data: {
				report: this.report
			}
    });
	}

	setLogs(report){
		if(report.logResource){
			this.reportUtilservice.getLog(this.report)
						  .subscribe(

							 	res => {
									let log  =  res.json();

									if (log.appium){
										this.showAppiumLogModal = true;
										this.appiumLog = log.appium.join('');
                    this.appiumLogs = log.appium.filter(log => log).map(log => {
                      const parsed = log.split(/\s(.+)/);
                      return {
                        type: parsed[0],
                        text: parsed[1]
                      } as Log;
                    });
									}

									if (log.device){
                    this.showDeviceLogModal = true;

                    if ((report.device.OS.platform as string).toLowerCase() === 'android') {
                      if (parseFloat(report.device.OS.version) >= 5) {
                        const deviceLogs = log.device.filter(log => log);
                        this.deviceLogs = deviceLogs.map((log, index) => {
                          const result: Log = {
                            time: '',
                            pid: '',
                            tid: '',
                            type: '',
                            text: ''
                          };
                          const parsed = log.replace(/\s{2,}/g, ' ')
                            .split(' ');

                          if (parsed.length && parsed[1] && parsed[1].match(/\d/g)) {
                            result.time = parsed.slice(0, 2).join(' ');
                            result.pid = parsed[2];
                            result.tid = parsed[3];
                            result.type = parsed[4];
                            result.text = parsed.slice(5).join(' ');
                            const split = result.text.split('\n');
                            const merged = split.slice(1).filter(log => log);
                            result.text = split[0];

                            for (let i = 0; i < merged.length; ++i) {
                              deviceLogs.splice(index + i + 1, 0, merged[i]);
                            }
                          } else {
                            result.text = parsed.join(' ');
                          }

                          return result;
                        });
                      } else {
                        this.deviceLogs = log.device.filter(log => log).map(log => {
                          let parsed = log.split(/\/(.+)/);
                          const result: Log = {
                            type: '',
                            process: '',
                            text: ''
                          };

                          if (parsed.length > 1) {
                            result.type = `${parsed[0]}/`;
                            parsed = parsed[1].split(' ');
                            result.process = parsed.slice(0, 2).join(' ');
                            result.text = parsed.slice(2).join(' ');
                          } else {
                            result.text = parsed.join(' ');
                          }

                          return result;
                        });
                      }
                    } else {
                      const deviceLogs = log.device.filter(log => log);
                      this.deviceLogs = deviceLogs.map((log, index) => {
                        const result: Log = {
                          time: '',
                          name: '',
                          process: '',
                          type: '',
                          text: ''
                        };
                        const parsed = log.replace(/\s{2,}/g, ' ')
                          .split(' ');

                        if (parsed.length > 1) {
                          result.time = parsed.slice(0, 3).join(' ');
                          result.name = parsed[3];
                          result.process = parsed[4];
                          result.type = parsed[5];
                          result.text = parsed.slice(6).join(' ');
                          const split = result.text.split('\n');
                          const merged = split.slice(1).filter(log => log);
                          result.text = split[0];

                          for (let i = 0; i < merged.length; ++i) {
                            deviceLogs.splice(index + i + 1, 0, merged[i]);
                          }
                        } else {
                          result.text = parsed[0];
                        }

                        return result;
                      });
                    }

                    this.deviceDataSourceLoaded = true;
									}

									if(log.capabilities){
										this.showCapabilitiesLogModal = true;
										this.capabilities = log.capabilities.join(' ');
									}
								},
								err => console.log(err)
						   )
		}
	}

  renderExecutions() {
		const executions = {};
		this.reportList.forEach((r) => {
			const executionNames = Object.keys(executions);
			if (r.executionName && executionNames.indexOf(r.executionName) === -1 ) {
				executions[r.executionName] = [r];
			} else if (executionNames.indexOf(r.executionName) > -1) {
				executions[r.executionName].push(r);
			} else {
        if (!executions['Uncategorized']) {
          executions['Uncategorized'] = [];
        }
				executions['Uncategorized'].push(r);
			}
		});

		this.executionList = executions;

		this.executionGroup = this.route
		 .queryParamMap
		 .subscribe((map: ParamMap) => {
			 this.executionGroup = map.get('group');

			 if (this.executionList && this.executionGroup) {
				 this.execution = {
					 key: this.executionGroup,
					 value: this.executionList[this.executionGroup]
				 };
			 }
		 });
	}

  back() {
    this.router.navigate([`/report-suites`, this.executionId, this.deviceId]);
  }

	buildMetrics() {

		const width = this.width;
		const ref = this;
		const height = 20;
		const min = 1;
		const max = 100;


		const data = this.stepList.map((step) => {

			if ( (this.showMetrics && !step.stats) || !this.showMetrics) {
				this.showMetrics = false;
				return null;
			} else {

				return {
					cpu: parseFloat(step.stats.cpu),
					ram: parseFloat(step.stats.ram),
					signal: parseFloat(step.stats.signal),
				};
			}
		});

		if (!this.showMetrics) {
			return;
		}

		data.push(data[data.length - 1]);

		this.metricsSVG = this.d3.select(this.reportMetricsContainer.nativeElement)
		.append('svg')
		.attr('width', width)
		.attr('height', height * 3 + (10 * 3))
		// .on('mouseover', function(d) {
		// 	ref.d3.select(ref.tooltipMetrics.nativeElement)
		// 	.transition()
		// 	.duration(200)
		// 	.style('opacity', 1);
		// })
		// .on('mouseout', function(d) {
		// 	ref.d3.select(ref.tooltipMetrics.nativeElement)
		// 	.transition()
		// 	.duration(200)
		// 	.style('opacity', 0);
		// })
		// .on('mousemove', function(d) {
		// 	const x = ref.d3.mouse(this as SVGGElement)[0];
		// 	const els = width / ref.stepList.length;
		// 	let i = 0;
		// 	let sizeRef = 0;
		// 	for (; i < ref.stepList.length; i++, sizeRef += els) {
		// 		if (x < sizeRef) {
		// 			break;
		// 		}
		// 	}
		// 	ref.tooltipMetrics.nativeElement.style.left = `${x - 150}px`;
		// 	ref.metric = data[i];
		// });

		const gCPU = this.metricsSVG.append('g')
		.attr('height', '20');

		gCPU.append('line')
		.attr('x1', 0)
		.attr('x2', width)
		.attr('y1', 1)
		.attr('y2', 1)
		.attr('shape-rendering', 'optimizeSpeed')
		.attr('stroke-width', 1)
		.attr('stroke', 'red');

		/*gCPU.append('rect')
		 .attr('class', 'overlay')
		 .attr('width', width)
		 .attr('height', 20)
		 .on('mouseover', function(d) { console.log(this);  })
		 .on('mouseout', function(d) { console.log(d);  })
		 .on('mousemove', function(d) { console.log(d); });*/

		const gMEM = this.metricsSVG.append('g')
		.attr('height', '20')
		.attr('transform', 'translate(0, 35)');

		gMEM.append('line')
		.attr('x1', 0)
		.attr('x2', width)
		.attr('y1', 0)
		.attr('y2', 0)
		.attr('stroke-width', 1)
		.attr('shape-rendering', 'optimizeSpeed')
		.attr('stroke', '#44a461');

		const gSIG = this.metricsSVG.append('g')
		.attr('height', '20')
		.attr('transform', 'translate(0, 70)');

		gSIG.append('line')
		.attr('x1', 0)
		.attr('x2', width)
		.attr('y1', 0)
		.attr('y2', 0)
		.attr('shape-rendering', 'optimizeSpeed')
		.attr('stroke-width', 1)
		.attr('stroke', '#3ab3db');

	 	// cpu
		const area_cpu = this.d3.area()
		.x(function( d: any, i: any ) {
			return i * (width / ref.stepList.length);
		})
		.y0(function( d: any ) {
			return height - Math.floor((d.cpu / 100) * height);
		})
		.y1(function( d: any ) {
			return height;
		});

		const line_cpu = this.d3.line()
		.x(function( d: any, i: any ) {
			return i * (width / ref.stepList.length);
		})
		.y(function( d: any ) {
			return height - Math.floor((d.cpu / 100) * height);
		});

		gCPU.append('path')
		.datum(data)
		.attr('fill', 'none')
		.attr('stroke', 'red')
		.attr('stroke-linejoin', 'round')
		.attr('stroke-linecap', 'round')
		.attr('stroke-width', 1)
		.attr('d', line_cpu);

		gCPU.append('path')
		.datum(data)
		.attr('fill', '#ee2c43')
		.attr('opacity', .5)
		.attr('d', area_cpu);

		// ram
		const area_ram = this.d3.area()
		.x(function( d: any, i: any ) {
			return i * (width / ref.stepList.length);
		})
		.y0(function( d: any ) {
			return height - Math.floor((d.ram / 100) * height);
		})
		.y1(function( d: any ) {
			return height;
		});

		const line_ram = this.d3.line()
		.x(function( d: any, i: any ) {
			return i * (width / ref.stepList.length);
		})
		.y(function( d: any ) {
			return height - Math.floor((d.ram / 100) * height);
		});

		gMEM.append('path')
		.datum(data)
		.attr('fill', 'none')
		.attr('stroke', '#44a461')
		.attr('stroke-linejoin', 'round')
		.attr('stroke-linecap', 'round')
		.attr('stroke-width', 1)
		.attr('d', line_ram);

		gMEM.append('path')
		.datum(data)
		.attr('fill', '#44a461')
		.attr('opacity', .5)
		.attr('d', area_ram);

		//signal
		const area_sig = this.d3.area()
		.x(function( d: any, i: any ) {
			return i * (width / ref.stepList.length);
		})
		.y0(function( d: any ) {
			return height - Math.floor((d.signal / 100) * height) - 1;
			//return Math.floor((d.signal / 100) * height);
		})
		.y1(function( d: any ) {
			return height;
		});

		const line_sig = this.d3.line()
		.x(function( d: any, i: any ) {
			return i * (width / ref.stepList.length);
		})
		.y(function( d: any ) {
			return Math.floor((d.signal / 100) * height);
		});

		gSIG.append('path')
		.datum(data)
		.attr('fill', 'none')
		.attr('stroke', '#3ab3db')
		.attr('stroke-linejoin', 'round')
		.attr('stroke-linecap', 'round')
		.attr('stroke-width', 1)
		.attr('d', line_sig);

		gSIG.append('path')
		.datum(data)
		.attr('fill', '#3ab3db')
		.attr('opacity', .5)
		.attr('d', area_sig);

	}

	buildChart() {
    this.stepList = [];
		let width = this.width;
		const height = (this.findDeph(this.reportEntries, 0, 0) * 25) + 20;

		if (this.firstBuild) {
			this.firstBuild = false;
			if (100 * this.stepList.length < this.reportIndexContainer.nativeElement.offsetWidth) {
				this.width = this.reportIndexContainer.nativeElement.offsetWidth;
			} else {
				this.width = width = 100 * this.stepList.length;
			}
		}

		//
		this.buildMetrics();
		//

		const x = this.d3.scaleLinear().range([0, width]);
		const x2 = this.d3.scaleLinear().range([0, width]);
		const y = this.d3.scaleLinear().range([0, height]);

		const partition = this.d3.partition().size([width, height]).padding(0).round(true);

		//const indexToolTip = this.d3.select(this.reportIndexContainer.nativeElement.childNodes[1])
    //.style('opacity', 0);

		this.reportSVG = this.d3.select(this.reportIndexContainer.nativeElement).append('svg')
 		.attr('viewBox', '0 0 ' + width + ' ' + height)
		.attr('width', width);

		const context = this.reportSVG.append('g')
    .attr('class', 'context');

	  const root = this.d3.hierarchy({
			type: 'plan',
			description: 'description',
			children: this.reportEntries
		}, (d: any) => d.children)
		.sum(d => d.children ? 0 : 1);

		partition(root);

		let rect = context.selectAll('rect');
		let text = context.selectAll('foreignObject');

		rect = rect
      .data(root.descendants())
    	.enter().append('rect')
      .attr('x', (d: any) =>  d.x0)
      .attr('y', (d: any) =>  d.y0 - 24)
      .attr('width', (d: any) =>  d.x1 - d.x0)
      .attr('height', (d: any) =>  d.y1 - d.y0)
			.style('cursor', (d: any) => {
 			 if (d.data.type === 'step') {
 				 return 'pointer';
 			 } else {
 				 return 'normal';
 			 }
 		 })
      .attr('fill', (d: any) => {
				switch (d.data.type) {
					case 'plan':
						return '#EEE';
					case 'suite':
						return '#DDD';
					case 'spec':
						return '#ee2c43';
					case 'step':
						if (this.activeStep && d.data.id === this.activeStep.id) {
							return '#EEE';
						} else {
							return '#373431';
						}
					default:
						return 'blue';
				}
			})
      .attr('stroke', 'white')
			.style('display', (d: any) => d.y0 === 0 ? 'none' : 'normal');
			// .on('click', clicked);


			text = text
		 .data(root.descendants())
		 .enter().append('foreignObject')
		 .attr('x', (d: any) => d.x0)
		 .attr('y', (d: any) => d.y0 - 24)
		 .attr('width', (d: any) => d.x1 - d.x0)
		 .attr('height', (d: any) => d.y1 - d.y0)
		 .style('cursor', (d: any) => {
			 if (d.data.type === 'step') {
				 return 'pointer';
			 } else {
				 return 'normal';
			 }
		 })
		 .style('color', (d: any) => {
			 switch (d.data.type) {
				 case 'plan':
					 return '#333';
				 case 'suite':
					 return '#333';
				 case 'spec':
					 return '#FFF';
				 case 'step':
				 if (this.activeStep && d.data.id === this.activeStep.id) {
					 return '#ee2c43';
				 } else {
					 return '#FFF';
				 }
				 default:
					 return 'blue';
			 }
		 })
		 .style('font-size', '11')
		 .style('font-weight', (d) => {
			 if (this.activeStep && d.data.id === this.activeStep.id) {
				 return 'bold';
			 } else {
				 return 'normal';
			 }
		 })
		 .style('text-align', 'center')
		 .style('padding', '5px')
		 .style('overflow', 'hidden')
		 /*.style('opacity', (d: any) => {
			 return (d.data.description || d.data.action).length * 7 > (d.x1 - d.x0) ? 0 : 1;
		 })*/
		 .html((d: any) => {
			 if (d.data.action === 'APPIUM') {

				let desc;

				if (d.data.action === 'APPIUM') {
					desc = this.reportUtilservice.labelFor(d.data);
				} else {
					desc = (d.data.description || d.data.action).toLowerCase();
				}

				 return `<span>${desc}</span>`;
			 }

			 return `<span>${(d.data.description || d.data.action).toLowerCase()}</span>`;
		 })
		 .on('mouseover', (d) => {

			if (this.d3.event.target.offsetWidth < this.d3.event.target.scrollWidth) {
				/*indexToolTip.transition()
				.duration(200)
				.style('opacity', .9);*/

				let desc;

				if (d.data.action === 'APPIUM') {
					desc = this.reportUtilservice.labelFor(d.data);
				} else {
					desc = (d.data.description || d.data.action).toLowerCase();
				}

				/*indexToolTip.html(desc)
				.style('left', `${this.d3.event.pageX}px`)
				.style('top', `${this.d3.event.pageY - 28}px`);*/
			}

		 })
		 .on('mouseout', (d) => {
			 /*indexToolTip.transition()
			 .duration(500)
			 .style('opacity', 0);*/
			})
		 .on('click', (d) => {
			 this.setActiveStep(d.data);
		 });
	}

	notSameResult(step: any) {
		return step.value === step.result;
	}

	setActiveStep(step: any) {
		this.activeStep = step;

    this.currentIndex = this.stepList.findIndex(el => el.id === this.activeStep.id);

		if (!document.getElementById(`pic_${step.id}`)) {
			return;
		}

		const r = document.getElementById(`pic_${step.id}`).getBoundingClientRect();
		const gallery = document.getElementsByClassName('horizontal-report')[0];
    this.$(gallery).stop();
		this.$(gallery).animate({scrollLeft: `+=${r.left - 60}`}, 800);
		this.$('.step.active').removeClass('active');
		this.$(`#step_${step.id}`).addClass('active');

		this.reportSVG.remove();
		if ( this.metricsSVG ) {
			this.metricsSVG.remove();
		}

		this.buildChart();
	}

	showStepResult(step: any) {
		this.setActiveStep(step);
		// this.$('.mat-sidenav-content').css('overflow', 'hidden');
		// this.resultModal.nativeElement.style.top = this.$('.mat-sidenav-content').scrollTop() + 'px';
		this.resultModal.nativeElement.style.display = 'block';
	}

	closeStepResult() {
		this.resultModal.nativeElement.style.display = 'none';
		// this.$('.mat-sidenav-content').css('overflow', 'auto');
	}

	stepTitle(step: any) {
		if (!step) {
			return;
		}

		let desc;
		if (step.action === 'APPIUM') {
			desc = this.reportUtilservice.labelFor(step);
		} else {
			desc = (step.description || step.action);
		}
		return desc;
	}

	stepResult(step: any) {
		const obj = {};
		const title = this.stepTitle(step);
    const value = this.stepValue(step);
		if (value !== '.') {
			obj[title] = value;
		}

		const json = JSON.parse(step.result.body);
		Object.keys(json).forEach((key: any) => {
      if (title === 'source' && key === 'value') {

      } else {
        obj[key] = json[key];
      }
		});
		return obj;
	}

	stepValue(step: any) {
		if (!step) {
			return;
		}

		if (step.action === 'APPIUM' && step.value) {
			if (step.value.content && step.value.content.value) {
				return `${step.value.content.value}`;
			} else {
				return '.';
			}
		} else {
			return '.';
		}
	}

  stepTree(step: any) {
   return [
    {
      id: 1,
      name: 'Rota 1',
      children: [
        { id: 2, name: 'Rota 1.1' },
        { id: 3, name: 'Rota 1.2' }
      ]
    },
    {
      id: 4,
      name: 'Rota 2',
      children: [
        { id: 5, name: 'Rota 2.1' },
        {
          id: 6,
          name: 'Rota 2.2',
          children: [
            { id: 7, name: 'Sub-rota' }
          ]
        }
      ]
    }
  ];
  }

  openModal(modal: any) {
	modal.style.display = 'block';

  }

  openDeviceDialog(arr: Log[], isAppium = false) {
    this.deviceDialog.open(LogsDialogComponent, {
      width: '800px',
      data: {
        isAppium,
        deviceOS: (this.report.device.OS.platform as string).toLowerCase(),
        deviceLogs: arr,
      }
    });
  }

  closeModal(modal: any) {
    modal.style.display = 'none';
  }

	async ngOnInit() {
		await this.loadReport();

		this.self = this;
	}

	getThumbUrl(device){		
		return device.model.urlThumb ? device.model.urlThumb : (device.model.thumb ? 'assets/images/thumbs/'+device.model.thumb : 'assets/images/iphone.svg');
	}

  	// downloadPdf() {
	// 	this.downloading = true;
	// 	this.downloadLabel = ReportDetailComponent.DOWNLOADING_LABEL;

	// 	this.reportUtilservice
	// 	.download(this.reportId)
	// 	.subscribe(async data => {
	// 		let result = data.blob();
	// 		const url = window.URL.createObjectURL(result);
	// 		const a = this.pdfLink.nativeElement;

	// 		a.download = `${this.reportId}.pdf`;
	// 		a.href = url;
	// 		a.click();

	// 		this.downloadLabel = ReportDetailComponent.DOWNLOAD_LABEL;
	// 		this.downloading = false;
	// 	});
	// }

	downloadPdf() {
		this.downloading = true;
		this.downloadLabel = ReportDetailComponent.DOWNLOADING_LABEL;
	
		this.reportUtilservice
		.download(this.reportId)
		.subscribe(result => {
			const url = window.URL.createObjectURL(result);
			const a = this.pdfLink.nativeElement;

			a.download = `${this.reportId}.pdf`;
			a.href = url;
			a.click();

			this.downloadLabel = ReportDetailComponent.DOWNLOAD_LABEL;
			this.downloading = false;
		});
	}
}
