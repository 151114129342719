import { Http } from '@angular/http';
import { Injectable, Inject } from '@angular/core';
import {URL_SERVER} from './../../providers'

@Injectable()
export class WidgetsService {

  constructor (private http: Http,) {}

  listResourcesStatistics() {
 	  return this.http.get(`${URL_SERVER}/services/resource/count`);
  }
}
