import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logs',
  pure: false
})
export class LogsPipe implements PipeTransform {
  transform(list, filterText): any {
    if (!list || !filterText) {
      return list;
    }

    return list.filter(v => v.includes(filterText));
  }
}