import { OnInit, OnDestroy, Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LoginService } from "../login/login.service";
import { SuperAdminService } from "./superadmin.service";
import { EditCompanyDialogComponent } from "./edit-company-dialog/edit-company-dialog.component";
import { CustomDialogComponent } from "./custom-dialog/custom-dialog.component";
import { JsonpModule } from "@angular/http";

@Component({
    selector: 'app-superadmin',
    templateUrl: './superadmin.component.html',
    styleUrls: ['./superadmin.component.scss']
})

export class SuperAdminComponent implements OnInit, OnDestroy {

    companiesSub: any;
    packagesSub: any;
    farmSub: any;
    companyList: any;
    servicePackageList: any;
    farmList: any;
    deviceSub: any;
    deviceList: any;
    deviceHubSub: any;
    deviceHubList: any;
    deviceModelSub: any;
    deviceModelList: any;
    manufacturerSub: any;
    manufacturerList: any;
    accSub: any;
    accountList: any;
    teamSub: any;
    teamList: any;
    companyFilter: string = '';
    deviceFilter: string = '';
    modelFilter: string = '';
    accountFilter: string = '';

    constructor(
		private router: Router,
        private toastr: ToastrService,
        private loginService: LoginService,
        private superAdminService: SuperAdminService,
		private dialog: MatDialog,
	) {
    }
    ngOnInit(): void {
        if(!this.isRoot()){
            this.toastr.error('Você não é um SuperAdmin!');
            this.router.navigate(['/']);
        }else{
            this.findManufacturers()
            this.findDeviceModels()
            this.findDeviceHubs()
            this.findDevices()
            this.findFarms()
            this.findPackages()
            this.findCompanies()
            this.findAccs()            
            this.findTeams()            
        }
    }
    
    findTeams(){
        this.teamSub = this.superAdminService.listTeams().subscribe(async resp => {
            this.teamList = await resp.json();
            console.log('List Teams OK');
        });
    }

    findAccs(){
        this.accSub = this.superAdminService.listAccounts().subscribe(async resp => {
            this.accountList = await resp.json();
            console.log('List Accounts OK');
        });
    }

    findManufacturers(){
        this.manufacturerSub = this.superAdminService.listManufacturers().subscribe(async resp => {
            this.manufacturerList = await resp.json();
            console.log('List Manufacturer OK');
        });
    }

    findDeviceModels(){
        this.deviceModelSub = this.superAdminService.listDeviceModels().subscribe(async resp => {
            this.deviceModelList = await resp.json();
            console.log('List Models OK');
        });
    }

    findDeviceHubs(){
        this.deviceHubSub = this.superAdminService.listDeviceHubs().subscribe(async resp => {
            this.deviceHubList = await resp.json();
            console.log('List Hubs OK');
        });
    }

    findDevices(){
        this.deviceSub = this.superAdminService.listDevices().subscribe(async resp => {
            this.deviceList = await resp.json();
            console.log('List Devices OK');
        });
    }

    findFarms(){
        this.farmSub = this.superAdminService.listDeviceFarms().subscribe(async resp => {
            this.farmList = await resp.json();
            console.log('List Farms OK');
        });
    }

    findPackages(){
        this.packagesSub = this.superAdminService.listServicePackages().subscribe(async resp => {
            this.servicePackageList = await resp.json();
            console.log('List Packages OK');
        });
    }

    findCompanies(){
        this.companiesSub = this.superAdminService.listCompanies().subscribe(async resp => {
            this.companyList = await resp.json();
            console.log('List Companies OK');
        });
    }

    createCompany(){
        this.dialog.open(EditCompanyDialogComponent, {
            width: '800px',
            data: {
                deviceFarms: this.farmList,
                servicePackages: this.servicePackageList,
                companyList: this.companyList
            }
        }).afterClosed().subscribe(() => this.findCompanies());
    }
    
    createDevice(){
        this.dialog.open(CustomDialogComponent, {
			width: '600px',
            data: {
                type: 'device',
                deviceFarms: this.farmList,
                deviceHubs: this.deviceHubList,
                models: this.deviceModelList,
                deviceList: this.deviceList,
                isNew: true,
                device: {alias: '', deviceHub: {name: ''}, model: {name:''}, deviceFarms:[], OS:{platform:'',version:0},serial:''}
            }
        }).afterClosed().subscribe(() => this.findDevices());
    }

    createAccount(){
        this.dialog.open(CustomDialogComponent, {
            width: '800px',
            data: {
                type: 'account',
                acc: {
                    firstName: '', lastName: '',
                    team: {name: ''},
                    teams: [],
                    username:'', password:'', confirmPassword:'',
                    email: '',
                    isAdmin: false
                },
                isNew: true,
                accountList: this.accountList,
                teams: this.teamList
            }
        }).afterClosed().subscribe(() => {
            this.findAccs();
            this.findTeams();
        });
    }

    createModel(){
        this.dialog.open(CustomDialogComponent, {
            width: '600px',
            data: {
                type: 'model',
                manufacturerList: this.manufacturerList,
                model: {name: '', logicalResolution: {width: 0, height: 0},resolution: {width: 0, height: 0}, manufacturer: {name: ''}},
                modelList: this.deviceModelList,
                isNew: true
            }
        }).afterClosed().subscribe(() => this.findDeviceModels());
    }

    edit(comp){
        this.dialog.open(EditCompanyDialogComponent, {
            width: '800px',
            data: {
                company: JSON.parse(JSON.stringify(comp)),
                deviceFarms: this.farmList,
                servicePackages: this.servicePackageList,
                deviceList: this.deviceList
            }
        }).afterClosed().subscribe(() => this.findCompanies());
    }

    editAccount(acc){
        this.dialog.open(CustomDialogComponent, {
            width: '800px',
            data: {
                type: 'account',
                acc,
                accountList: this.accountList,
                teams: this.teamList
            }
        }).afterClosed().subscribe(() => {
            this.findAccs();
            this.findTeams();
        });
    }

    editModel(model){
        this.dialog.open(CustomDialogComponent, {
            width: '600px',
            data: {
                type: 'model',
                model,
                manufacturerList: this.manufacturerList,
            }
        }).afterClosed().subscribe(() => this.findDeviceModels());
    }
    
    editDevice(device){
        this.dialog.open(CustomDialogComponent, {
			width: '600px',
            data: {
                type: 'device',
                deviceFarms: this.farmList,
                deviceHubs: this.deviceHubList,
                models: this.deviceModelList,
                device
            }
        }).afterClosed().subscribe(() => this.findDevices());
    }

    resetCache(hub){

        this.superAdminService.updateHubCache(hub._id).subscribe(resp => {
            let data = resp.json();

            this.toastr.info(data.message);
        },error => console.log(error))
    }

    resetMinute(comp){
        let result = confirm(`Deseja resetar os minutos da empresa ${comp.name}`);

        if(result){
            this.superAdminService.resetMinutes(comp._id).subscribe(async resp => {
                let data = await resp.json();
                if(data.success){
                    this.toastr.success('Minutagem resetada!');
                }else{
                    this.toastr.warning('Não há tempo para ser resetado!');
                }
            });

        }
    }

    deactive(comp){
        let result = confirm(`Deseja desativar a empresa ${comp.name}`);

        if(result){
            this.superAdminService.deactiveCompany(comp._id).subscribe(async resp => {
                let data = await resp.json();
                console.log('company deactived!');
            });

        }
    }

    removeAccount(acc){}

    uploadThumb(mod){
        this.dialog.open(CustomDialogComponent, {
			width: '600px',
            data: {
                type: 'upload',
                deviceModel: mod,
            }
        })
    }

    isRoot(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ROOT') > -1);
    }

    ngOnDestroy(): void {
        if(this.companiesSub) this.companiesSub.unsubscribe();
        if(this.packagesSub) this.packagesSub.unsubscribe();
        if(this.deviceSub) this.deviceSub.unsubscribe();
        if(this.deviceHubSub) this.deviceHubSub.unsubscribe();
        if(this.deviceModelSub) this.deviceModelSub.unsubscribe();
        if(this.accSub) this.accSub.unsubscribe();
        if(this.teamSub) this.teamSub.unsubscribe();
    }
}