import { Component, ChangeDetectorRef, Input, NgZone, OnInit, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { DeviceStatusLogService } from './device-status-log.service';
import { DevicesService } from '../devices/devices.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResumeCompanyService } from '../admin/company/resume.service';
import { LoginService } from '../login/login.service';

@Component({
    selector: 'device-status-log',
    templateUrl: './device-status-log.component.html',
    styleUrls: ['./device-status-log.component.scss']
})

export class DeviceStatusLogComponent implements OnInit, OnDestroy {

    logFilter: String;
    statusLogs: any[] = [];
    range: any = 30;
    skip: any = 0;
    errorMessage: any;
    minDate: Date = new Date(2000, 0, 1);
    maxDate: Date = new Date(2120, 0, 1);
    filterFrom: Date;
	filterTo: Date;    

    constructor(
        public logService: DeviceStatusLogService,
        private route: Router,
        public deviceService: DevicesService,
        private changeDetector: ChangeDetectorRef,
        private loginService: LoginService,
		private toastr: ToastrService
    ){
        const currentDate: Date = new Date();
		this.minDate = new Date(currentDate.getTime() - (180 * 24 * 60 * 60 * 1000));
		this.maxDate = new Date(currentDate.setHours(23,59,59));
		
        this.filterTo = new Date(currentDate);
        this.filterTo.toLocaleDateString();
        this.filterFrom = new Date(currentDate.getTime() - (60 * 24 * 60 * 60 * 1000));
        this.filterFrom.toLocaleDateString();
    }

    ngOnInit(){
        this.loadLogs(this.skip, this.range);
    }

    loadLogs(skip, limit, resetList = false){
        var params = {
            from: this.filterFrom,
            to: this.filterTo
        }
        this.logService.getStatusLogs(skip, limit, params).subscribe(async (resp) => {
            let data = await resp.json();
            
            if(resetList){
                this.statusLogs = [];
            }

            this.statusLogs = this.statusLogs.concat(...data);
        }, (error) => {
            console.error(error);
        });
    }

    loadMore(){
        this.skip = this.skip + this.range;
        this.loadLogs(this.skip, this.range);
    }

    findFiltered(){
        console.log(`Search with this params: ${this.filterFrom} - ${this.filterTo}`);
        this.skip = 0;
        this.loadLogs(this.skip,this.range, true)
    }

    ngAfterViewChecked(): void {
        this.changeDetector.detectChanges();
    }

    ngOnDestroy(){}
}