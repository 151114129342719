import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../login/login.service';
import { Router } from "@angular/router";
import { DevicesService } from '../../../devices/devices.service';

@Component({
	selector: 'app-device-widget',
	templateUrl: './devicesWidget.component.html',
	styleUrls: ['./devicesWidget.component.scss']
})
export class DevicesWidgetComponent implements OnInit {

    allowedDevices: any[] = [];

    constructor(
        private router: Router,
        public loginService: LoginService,
        public devicesService: DevicesService
    ) { }

    lastReport: any;
    ngOnInit() {
		if(this.devicesService.devices.length == 0){
			this.devicesService.getDevices().subscribe(async resp => {
				this.allowedDevices = this.devicesService.devices = await resp.json();
			})
		} else {
			this.allowedDevices = this.devicesService.devices;
		}
    }

    getlength(status){
		if (!this.allowedDevices) { return 0; }

		if(status.toLowerCase() === 'ios' || status.toLowerCase() === 'android'){
			let aoi = status.toLowerCase() == 'ios' ? 'ios' : 'android' ;
			return this.allowedDevices.filter(d=> d.OS.platform.toLowerCase() == aoi).length;
		}
		return this.allowedDevices.filter(d=>d.status.toLowerCase() == status.toLowerCase()).length;
	}
}
