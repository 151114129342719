import { Injectable } from "@angular/core";


@Injectable()
export class AndroidKeyCode {
    
    keyCodeMap: any;

    constructor (
	) {
        this.keyCodeMap = [
            //Numbers
            {key: '0', code: 7},
            {key: '1', code: 8},
            {key: '2', code: 9},
            {key: '3', code: 10},
            {key: '4', code: 11},
            {key: '5', code: 12},
            {key: '6', code: 13},
            {key: '7', code: 14},
            {key: '8', code: 15},
            {key: '9', code: 16},
    
            //Letters
            {key: 'a', code: 29},
            {key: 'b', code: 30},
            {key: 'c', code: 31},
            {key: 'd', code: 32},
            {key: 'e', code: 33},
            {key: 'f', code: 34},
            {key: 'g', code: 35},
            {key: 'h', code: 36},
            {key: 'i', code: 37},
            {key: 'j', code: 38},
            {key: 'k', code: 39},
            {key: 'l', code: 40},
            {key: 'm', code: 41},
            {key: 'n', code: 42},
            {key: 'o', code: 43},
            {key: 'p', code: 44},
            {key: 'q', code: 45},
            {key: 'r', code: 46},
            {key: 's', code: 47},
            {key: 't', code: 48},
            {key: 'u', code: 49},
            {key: 'v', code: 50},
            {key: 'w', code: 51},
            {key: 'x', code: 52},
            {key: 'y', code: 53},
            {key: 'z', code: 54},
    
            //Commands
            {key: 'enter', code: 66},
            //{key: 'shift', code: 59},
            {key: ' ', code: 62},
            {key: 'backspace', code: 67},
            {key:'escape', code: 111},
    
            //Symbols
            {key:'@', code: 77},
            {key:'+', code: 81},
            {key:'.', code: 56},
            {key:',', code: 55},
            {key:';', code: 74},
            {key:'/', code: 76},
            {key:'[', code: 71},
            {key:']', code: 72},
            {key:'=', code: 70},
            {key:'-', code: 69},
            {key:'*', code: 155},
            {key:'(', code: 162},
            {key:')', code: 163},
            {key:'\'', code: 75},
            {key:'\\', code: 73}
    
        ];
    }
    

    //passa o event.key
    getKeyCode(key){
        let data = this.keyCodeMap.find(k => k.key === key.toLowerCase());

        return data.code || null;
    }
}