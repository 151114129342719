import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../login/login.service';
import { ReportsService } from '../../../report-tree/reports/reports.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-no-report',
  templateUrl: './no-report.component.html',
  styleUrls: ['./no-report.component.scss']
})
export class NoReportComponent implements OnInit {

    constructor(
        private router: Router,
        public loginService: LoginService
    ) { }

    saveReport = true;
    ngOnInit() {
        if(this.loginService.user.team.noReport == true){
            this.saveReport = false;
        }else{
            this.saveReport = true;
        }
        console.log(this.loginService.user.team);     
    }


    changeOpt(){
        const confirmation = confirm(`Deseja alterar a configuração de relatórios? (Isso pode impactar o desempenho dos testes automatizados.)`);
        
        if(confirmation){
            this.loginService.changeFlagReport(this.loginService.user.team._id, this.saveReport).subscribe(async (result) => {
                let resp = await result.json();
                this.saveReport = !resp.noReport;
                this.loginService.user.team.noReport = resp.noReport;
                console.log(resp)
            }, (error) => {
                console.log(error)
            });
        }
    }


	isAdmin(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ADMIN') > -1 || this.loginService.user.roles.indexOf('ROOT') > -1)
    }

    isRoot(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ROOT') > -1);
    }
}
